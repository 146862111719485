import React from 'react'
import { Typography } from 'antd';

import IconTwitter from '../../../statics/images/icon-twitter.svg';

import styles from './index.module.scss';

function Footer() {
  return (
    <div className={styles.footer}>
      {/* <a className={styles.row} href="https://twitter.com/heimdallrwa">
        <img src={IconTwitter} width={23} height={23} alt="twitter" />
        <Typography.Text strong>Connect on Twitter</Typography.Text>
      </a> */}
      <Typography.Text type="secondary"> Copyright © 2023 by Heimdall RWA</Typography.Text>
      <div></div>
    </div>
  )
}

Footer.propTypes = {}

export default Footer
