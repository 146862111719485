import styles from './ClaimTable.module.css';
import { TbCopy } from 'react-icons/tb';
import { MdKeyboardArrowDown, MdArrowOutward } from 'react-icons/md';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import ClaimInfoTable from './ClaimInfo';
import { useCallback, useEffect, useState } from 'react';
import { Tag, Typography } from 'antd';
import { survey_obj, truncDigits } from '../../common/utils.ts';
import CustomTag from '../../components/CustomTags';
import { Link } from 'react-router-dom';
import { get_status } from '../PublicDataPage/Table/TableRow';
import clsx from 'clsx';
import { BcTokenFactory } from '../../typechain/BcTokenFactory';
import { useProvider } from 'wagmi';

function ClaimTableRow({ onViewDetails, claim, prices, survey }) {
  console.log(claim);
  console.log(survey);
  const [showInfo, setShowInfo] = useState(false);
  const [showAmount, setShowAmount] = useState(false);

  // const get_status = (status, submit_status) => {
  //   console.log("myacc", status);
  //   console.log(submit_status);
  //   if (status === "Rejected") {
  //     return (
  //       <Tag color="#F04438">
  //         <span style={{ color: "#fff" }}>Rejected</span>
  //       </Tag>
  //     );
  //   }
  //   if (submit_status === "Rejected" || submit_status === "" || !submit_status) {
  //     return (
  //       <CustomTag bgColor="#EAE9FF" color="#6558FF">
  //         <span style={{ color: "" }}>Under Review</span>
  //       </CustomTag>
  //     );
  //   } else if (submit_status === "Approved") {
  //     return (
  //       <CustomTag bgColor="#E3FAE5" color="#31B43E">
  //         <span style={{ color: "" }}>Transfer in Progress</span>
  //       </CustomTag>
  //     );
  //   }
  // };
  const provider = useProvider();
  const [isClaimIdExist, setIsClaimIdExist] = useState(false);

  const checkClaimExist = useCallback(async () => {
    if (!claim || !claim.claim_id || claim.claim_id === '') {
      return;
    }
    const result = await BcTokenFactory.connect(
      process.env.REACT_APP_COMMON_ADDRESS,
      provider
    ).get_id_by_claim_id(claim.claim_id);

    console.log(
      claim.claim_id,
      result,
      result.toNumber(),
      claim.admin_status,
      claim.submit_status
    );
    // console.log(claim.claim_id, result, result.toNumber());
    if (result.toNumber() === 0 || !result) {
      setIsClaimIdExist(false);
      return;
    }
    setIsClaimIdExist(true);
  }, [claim, provider]);

  useEffect(() => {
    setIsClaimIdExist(false);
    checkClaimExist();
  }, [checkClaimExist]);

  function preference(survey) {
    return (
      <>
        {parseFloat(survey.preferenceRisk) <= 0 ? (
          <>
            <span>Not Flagged</span>
          </>
        ) : parseFloat(survey.preferenceRisk) > 0 ? (
          <>
            <div className={styles.subTableCell}>
              <div className={styles.info}>
                <span style={{ alignSelf: 'center' }}>
                  Estimated: {truncDigits(parseFloat(survey.preferenceRisk), 2)}{' '}
                  USD
                </span>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      <tr
        className={clsx(styles.tr2, 'first-child:mb-0 mb-4', {
          [styles.section_selected]: showAmount || showInfo,
          'bg-white': !showAmount && !showInfo,
        })}
      >
        <td
          className={clsx(styles.tr_td, styles.td2, '!w-[150px] !px-5', {
            '!rounded-bl-none': showAmount || showInfo,
          })}
          onClick={onViewDetails}
        >
          <span className={styles.case}>
            Celsius Ch.11...of NY1
          </span>
        </td>
        <td className={clsx(styles.tr_td, 'min-w-[140px]')}>
          <div className={styles.parent_div}>
            <span className={styles.claim}>{claim.claim_id}</span>
            <Typography.Text
              copyable={{
                text: claim.claim_id,
                icon: (
                  <TbCopy
                    className={clsx('text-[#13A2A2] text-lg self-center', {
                      '!text-white': showAmount || showInfo,
                    })}
                  />
                ),
              }}
            />
          </div>
        </td>
        <td className={clsx(styles.tr_td, '!w-[150px]')}>
          <div className={styles.parent_div}>
            <span className={styles.claim}>
              {claim.survey
                ? '$' + truncDigits(survey_obj(claim.survey).totalClaim, 2)
                : 'N/A'}
            </span>

            <MdKeyboardArrowDown
              onClick={() => setShowAmount(!showAmount)}
              className={clsx('text-xl text-[#03ABAB] cursor-pointer', {
                'text-white': showAmount || showInfo,
              })}
              style={{
                transform: `rotate(${showAmount ? '180' : '0'}deg)`,
              }}
            />
          </div>
        </td>
        <td className="text-sm p-2.5 !w-[180px]">
          {preference(survey_obj(claim.survey))}
        </td>
        <td className="text-sm p-2.5 !w-[150px]">
          {' '}
          {survey_obj(claim?.survey)?.claim_link || 'N/A'}
        </td>
        <td className="text-sm p-2.5 !min-w-[130px]">
          {' '}
          {survey_obj(claim?.survey)?.poc_file_number || 'N/A'}
        </td>
        <td className={clsx(styles.tr_td, '!w-[150px]')}>
          {get_status(claim, claim.status, claim.submit_status, claim?.token_minted)}
        </td>
        {/* <td className={styles.tr_td}>
          N/A
          <div className={styles.parent_div}>
            <span className={styles.claim}>0x8460...c5c51a</span>
            <MdArrowOutward className={styles.copy} />
          </div>
        </td> */}
        <td className={clsx(styles.tr_td, '!min-w-[130px]')}>
          {claim.admin_status === 'Approved' &&
          claim.submit_status === 'Not Submitted' &&
          !!isClaimIdExist ? (
            <div>
              <Link
                to={'/transfer-agreement/' + claim.claim_id}
                state={{ id: claim.claim_id }}
              >
                <span
                  className={styles.action_text}
                  style={{
                    color: !showAmount && !showInfo ? '#037c7c' : '#fff',
                  }}
                >
                  Initiate Transfer {'>'}
                </span>
              </Link>
            </div>
          ) : null}
        </td>
        <td
          className={clsx(styles.tr_td, '', {
            '!rounded-br-none': showAmount || showInfo,
          })}
          style={{
            color: !showAmount && !showInfo ? '#037c7c' : '#fff',
          }}
        >
          <IoIosArrowDropdownCircle
            onClick={() => setShowInfo(!showInfo)}
            className={styles.arrow}
            style={{
              transform: `rotate(${showInfo ? '180' : '0'}deg)`,
            }}
          />
        </td>
      </tr>
      {showAmount || showInfo ? (
        <ClaimInfoTable
          claim={claim}
          showAmount={showAmount}
          showInfo={showInfo}
          prices={prices}
          setShowAmount={setShowAmount}
          setShowInfo={setShowInfo}
        />
      ) : null}
    </>
  );
}

ClaimTableRow.propTypes = {};

export default ClaimTableRow;
