import { Button, Space, Steps, Typography } from "antd";
import clsx from "clsx";
import { useContext } from "react";
import ClaimContextProvider, { ClaimContext } from "./ClaimContextProvider";
import styles from "./index.module.scss";
import StepsPanel from "./StepsPanel";
import Clap from "../../statics/images/clap.svg";
import { classNames } from "../../common/utils";

const ClaimPageLayout = () => {
  const {
    submitted,
    currentStep,
    progress,
    messageContextHolder,
    claimId,
    resetForm,
  } = useContext(ClaimContext);

  if (submitted && !!claimId) {
    return (
      <div className={classNames("container", styles.main)}>
        <div className={styles.content}>
          <div className={styles.success}>
            <div className={styles.result}>
              <img src={Clap} alt="Clap" />
              <div className={styles.resultText}>
                Thank you for your submission.
                <br /> Please note down your Claim ID:{" "}
                <strong>{claimId}</strong>
              </div>
              <button
                onClick={() => {
                  resetForm();
                }}
                className={"sd-btn"}
              >
                Create Another Claim
              </button>
              
                <a target="_blank" className={"sd-btn"} rel="noreferrer" href={process.env.REACT_APP_OPNX_DASHBOARD_URL || "https://opnx.com/claims/dashboard"}>Go to OPNX Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.content}>
      {messageContextHolder}
      <Space
        direction="vertical"
        align="center"
        size={12}
        className={styles.pageHeader}
      >
        <img
          src={"/logo_celsius.svg"}
          height={35}
          alt="celsius"
          className="self-center"
        />

        <Typography.Title
          level={2}
          className={styles.title}
          style={{ marginTop: "5px" }}
        >
          <div className="flex gap-2">
            <span className={styles.title_text}>
              Celsius Claim Questionnaire
            </span>
          </div>
        </Typography.Title>
        <Typography.Text type="secondary" className={styles.description}>
          Please complete the questionnaire by supplying the correct information{" "}
          <br />
          about your Celsius Bankruptcy Claim.
        </Typography.Text>
      </Space>

      <div className={clsx(styles.steps)}>
        <Steps
          current={currentStep}
          labelPlacement="vertical"
          items={progress}
          size={"small"}
          responsive={false}
        />
      </div>
      <div className={styles.card}>
        <StepsPanel />
      </div>
    </div>
  );
};

const ClaimPage = () => {
  return (
    <ClaimContextProvider>
      <ClaimPageLayout />
    </ClaimContextProvider>
  );
};

export default ClaimPage;
