import { Divider } from "antd";
import { CheckingTxt } from "../CheckingTxt";

export default function ClaimInfo({ surveyData }: any) {
  return (
    <>
      <div className="flex flex-col gap-[25px] bg-white">
        <div className="flex flex-col sm:flex-row gap-[25px]">
          <div className="flex flex-col w-[50%] gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              Claim is part of the latest Schedule of Assets
            </div>
            <div className="text-black text-[14px] font-bold">
              {surveyData?.is_soa_available}
            </div>
          </div>

          <Divider type="horizontal" className="my-0 block sm:hidden " />
          <div className="flex flex-col gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              {" "}
              Schedule F Line Number
            </div>
            <div className="text-black text-[14px] font-bold">
              {surveyData?.claim_link || "N/A"}
            </div>
          </div>
        </div>

        <Divider type="horizontal" className="my-0 block sm:hidden " />
        <div className="flex flex-col sm:flex-row gap-[25px]">
          <div className="flex flex-col w-[50%] gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              Is the claim contingent unliquidated, disputed?
            </div>
            <CheckingTxt text={surveyData?.claim_schedule_status} />
          </div>
          <Divider type="horizontal" className="my-0 block sm:hidden " />
          <div className="flex flex-col gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              {" "}
              Do you agree with the Claim Details?
            </div>
            <div className="text-black text-[14px] font-bold">
              {surveyData?.is_soa_amount_agreed || "No"}
            </div>
          </div>
        </div>

        <Divider type="horizontal" className="my-0 block sm:hidden " />
        <div className="flex flex-col sm:flex-row gap-[25px]">
          <div className="flex flex-col w-[50%] gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              Is the claim subject to offset?
            </div>
            <CheckingTxt text={surveyData?.claim_offset} />
          </div>
        </div>

        <Divider type="horizontal" className="my-0 block sm:hidden " />
        <div className="flex flex-col sm:flex-row gap-[25px]">
          <div className="flex flex-col w-[50%] gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              Total Claim Amount
            </div>
            <div className="text-black text-[14px] font-bold">
              $
              {Number(surveyData?.total_celsius_earn_balances)?.toFixed(3) ||
                "N/A"}
            </div>
          </div>
          <Divider type="horizontal" className="my-0 block sm:hidden " />
          <div className="flex flex-col gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              Total Preference Risk
            </div>
            <div className="text-black text-[14px] font-bold">
              $
              {(
                Number(surveyData?.earn_preference_risk) +
                Number(surveyData?.custody_preference_risk)
              )?.toFixed(3) || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
