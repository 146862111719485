import { useState } from "react";
import { Button, Modal } from "antd";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom"
import { CLAIM_TYPE } from "../../../common/constants";
function Disclaimer({ isModalOpen,cancelDisclaimer, type = '' }: { isModalOpen: any,cancelDisclaimer:any, type?: any }) {
    const navigate = useNavigate();
    const handleShowAgreement=()=>{
        cancelDisclaimer()
        const claimType = type || localStorage.getItem('claimType');
        if (claimType === CLAIM_TYPE) {
          navigate("/celsius");
        } else {
          navigate("/ftx");
        }
      }

  return (
    <Modal open={isModalOpen} footer={null} width={960} 
    onCancel={cancelDisclaimer}>
      <div className={styles.wrapper}>
        <div className={styles.header}>Website Disclaimer</div>
        <div className={styles.content_wrapper}>
          <div className={styles.content_text}>
            The information provided by Heimdall Operations Limited ('we', 'us',
            or 'our') on http://www.heimdallrwa.com (the 'Site') and any mobile
            application we offer is for general informational purposes only. All
            information on the Site and any mobile application is provided in
            good faith, however we make no representation or warranty of any
            kind express or implied, regarding the accuracy, adequacy, validity,
            reliability, availability, or completeness of any information on the
            Site or any mobile application.
            <b>
              UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
              LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
              SITE OR ANY MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION
              PROVIDED ON THE SITE OR ANY MOBILE APPLICATION. YOUR USE OF THE
              SITE AND ANY MOBILE APPLICATION AND YOUR RELIANCE ON ANY
              INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT
              YOUR OWN RISK.
            </b>
            <br />
            <br />
            <div>
              By accessing this Site, you represent that you are (i) over the
              age of 18, (ii) not a citizen, resident (tax or otherwise) of, or
              a person located or domiciled in any prohibited jurisdiction or
              any entity, including without limitation, any corporation,
              partnership, trust or unincorporated entity, created or organized
              in or under the laws of any prohibited jurisdiction; and (iii) not
              a person person who is the subject of sanctions or is organized or
              resident in a country or territory that is the subject of
              country-wide or territory-wide sanctions. In the event that we
              determine you have misrepresented your age, location, residency or
              any other material fact, we reserve the right to immediately and
              without further notice block your access.
            </div>
            <br />
            <h4>Prohibited Jurisdictions</h4>
            <div>
              Individuals of these nationalities and/or located or residing in
              the areas listed are restricted from participating on our platform
              or entering into contracts with Heimdall and its affiliates. The
              list is under continuous review, and countries or citizenships may
              be added or removed from time to time. If it is determined that
              any individual seeking to participate on our platform has given
              false representations as to their age, location, place of
              residence or citizenship, Heimdall reserves the right to
              immediately and without further notice block your access.
              Companies incorporated in these countries are not allowed to use
              the platform.
              <br /> <br />
              For the avoidance of doubt, we may refuse to grant an account to
              any person without stating a reason.
            </div>
          </div>
        </div>
        <div className={styles.agreeBtnWarn}>
        If you agree, click on the button below to proceed
        </div>
        <button
        onClick={()=>handleShowAgreement()}
        className={styles.button}>Accept</button>
      </div>
    </Modal>
  );
}

Disclaimer.propTypes = {};

export default Disclaimer;
