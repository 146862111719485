import {
  Button,
  Collapse,
  Form,
  Input,
  Select,
  Tabs,
  TabsProps,
  Typography,
  Modal,
} from "antd";
import clsx from "clsx";
import styles from "./index.module.scss";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { useState, useCallback, useContext, useMemo } from "react";
import { ClaimContext, ScheduleFLineAccount } from "../ClaimContextProvider";
import CryptoIcon from "../../../components/CryptoIcon";
import debounce from "lodash.debounce";
import * as yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { step_2 } from "./schema";
import { baseURL, uploadFileAPI } from "../../../api/client";
import { BiChevronRight } from "react-icons/bi";
import { sleep, uploadImage } from "../../../common/utils";

const Step2 = () => {
  const [modal, setModal] = useState(null);

  const {
    celciusScheduleData,
    formData,
    prices,
    getCelciusScheduleData,
    claimDataLoading,
    handleNext,
    handleBack,
    useYupValidationResolver,
    handleUpdateForm,
    handleSubmit: submitClaim
  } = useContext(ClaimContext);

  type FormData = yup.InferType<typeof step_2>;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: useYupValidationResolver(step_2),
    defaultValues: {
      claim_link: formData.claim_link,
      is_soa_amount_agreed: formData.is_soa_amount_agreed,
      celsius_statement: formData.celsius_statement,
    },
  });

  const values = useWatch({ control });
  const currentFileList = useMemo(
    () =>
      values.celsius_statement
        ? [(values.celsius_statement as Record<string, any>).file] || []
        : [],
    [values]
  );
  const handleNextStep =  async (values: Record<string, any>) => {
      handleUpdateForm(values);
      if(values.is_soa_amount_agreed === "No") {
        submitClaim(values)
        return 
      }
      handleNext();
    }

  const handleBackStep = useCallback(() => {
    handleUpdateForm(values);
    handleBack();
  }, [handleBack, handleUpdateForm, values]);

  const renderTabContent = useCallback(
    (data: ScheduleFLineAccount) => {
      return (
        <div className="divide-y divide-grey-100 sm:divide-y-0">
          {data?.length ? (
            data?.map((item, index) => {
              const icon =
                prices
                  .find((price) => price.coin === item.token)
                  ?.coin?.toLowerCase() ?? "btc";

              return (
                <div key={index} className="py-4 sm:py-0">
                  <div className="flex items-center">
                    <div className="mr-2">
                      <CryptoIcon name={icon} />
                    </div>
                    <div className="text-sm font-semibold">
                      {item.token}&nbsp;&nbsp;
                    </div>
                  </div>
                  <div className="mt-2.5 sm:mt-2 text-xs">
                    {(+item.value).toFixed(12)}
                  </div>
                </div>
              );
            })
          ) : (
            <p>N/A</p>
          )}
        </div>
      );
    },
    [prices]
  );

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderTabContent(celciusScheduleData.earn_account),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderTabContent(celciusScheduleData.custody_account),
      },
      {
        key: "3",
        label: `Withheld Account`,
        children: renderTabContent(celciusScheduleData.withheld_account),
      },
      {
        key: "4",
        label: `Borrow Account`,
        children: renderTabContent(
          celciusScheduleData.collateral_on_loan_receivable
        ),
      },
    ],
    [
      celciusScheduleData.collateral_on_loan_receivable,
      celciusScheduleData.custody_account,
      celciusScheduleData.earn_account,
      celciusScheduleData.withheld_account,
      renderTabContent,
    ]
  );

  const debouncedClaimLinkData = useMemo(() => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      getCelciusScheduleData(e.target.value);
    };
    return debounce(handleChange, 500);
  }, [getCelciusScheduleData]);

  const [defaultFileList, setDefaultFileList] = useState([]);

  const handleOnChange = ({ file, fileList, event }: any) => {
    console.log(file, fileList, 'file, fileList');
    
    setValue(
      'celsius_statement',
      {
        file: file,
        fileList: file.status === "removed" ? [] : [file],
      }
    );
    setDefaultFileList(fileList);
  };
  return (
    <Form
      onFinish={handleSubmit(handleNextStep)}
      layout="vertical"
      className="flex flex-col gap-2"
    >
      <div className={clsx(styles.panel_container)}>
        <Typography.Text className="font-semibold text-black text-2xl !px-4 sm:!px-10">
          Stretto/Celsius Details
        </Typography.Text>

        <Form.Item
          required
          label={
            <span>
              Enter the SCHEDULE F LINE number for your claim <br /> (you can
              find your SCHEDULE F LINE on
              <a
                target="_blank"
                rel="noreferrer"
                className="text-[#2673E8]"
                href="https://cases.stretto.com/public/x191/11749/PLEADINGS/1174910062280000000017.pdf"
              >
                {" "}
                Admin Portal{" "}
              </a>
              on page 92) *
            </span>
          }
          className="!px-4 sm:!px-10"
        >
          <Controller
            name="claim_link"
            control={control}
            render={({ field }) => (
              <Input
                status={errors.claim_link && "error"}
                {...field}
                onChange={(e) => {
                  setValue("claim_link", e.target.value, {
                    shouldValidate: true,
                  });
                  debouncedClaimLinkData(e);
                }}
              />
            )}
          />

          <Typography.Text type="danger">
            {errors.claim_link?.message?.toString()}
          </Typography.Text>
        </Form.Item>
      </div>
      {!claimDataLoading && celciusScheduleData._id && (
        <Collapse
          className={styles.panel_collapse}
          expandIconPosition={"end"}
          defaultActiveKey="claim_data"
        >
          <Collapse.Panel header="Claim Data" key="claim_data">
            <div className={styles.schedule_basic_info}>
              <table>
                <thead className="hidden sm:table-header-group">
                  <tr>
                    <th>Creditor</th>
                    <th>Address</th>
                    <th className="w-[175px]">
                      Is the claim contingent, unliquidated or disputed?
                    </th>
                    <th className="w-[126px]">
                      Is the claim subject to offset?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="space-y-3 sm:space-y-0">
                    <td className="block sm:table-cell border-b border-grey-100">
                      <div className="block sm:hidden mb-2 font-normal text-xs text-grey-400">
                        Creditors Name
                      </div>
                      <div className="">
                        {celciusScheduleData.creditors_name}
                      </div>
                    </td>
                    <td className="block sm:table-cell border-b border-grey-100">
                      <div className="block sm:hidden mb-2 font-normal text-xs text-grey-400">
                        Address
                      </div>
                      <div className="">{celciusScheduleData.address}</div>
                    </td>
                    <td className="block sm:table-cell border-b border-grey-100">
                      <div className="block sm:hidden mb-2 font-normal text-xs text-grey-400">
                        Is the claim contigent unliquidated, disputed
                      </div>
                      <div className="">
                        {celciusScheduleData.indicate ? (
                          <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#31B43E] flex justify-center items-center font-semibold">
                            • YES
                          </div>
                        ) : (
                          <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#CF352A] flex justify-center items-center font-semibold">
                            • NO
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="block sm:table-cell">
                      <div className="block sm:hidden mb-2 font-normal text-xs text-grey-400">
                        Is the claim subject to offset?
                      </div>
                      <div className="">
                        {celciusScheduleData.is_claim_to_offset === true ? (
                          <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#31B43E] flex justify-center items-center font-semibold">
                            • YES
                          </div>
                        ) : (
                          <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#CF352A] flex justify-center items-center font-semibold">
                            • NO
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Tabs
                className="my-3 mx-5 hidden sm:block"
                defaultActiveKey="1"
                items={items}
              />

              <div className="block sm:hidden divide-y divide-grey-100">
                {items.map((el) => (
                  <div
                    key={el.key}
                    className="flex items-center justify-between mx-3 py-4 cursor-pointer"
                    //  @ts-ignore
                    onClick={() => setModal(el)}
                  >
                    <div className="text-sm font-semibold">{el.label}</div>

                    <BiChevronRight className="text-primary-400 text-xl" />
                  </div>
                ))}
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      )}

      <Modal
        // @ts-ignore
        title={<span className="text-xl">{modal?.label || "NA"}</span>}
        open={Boolean(modal)}
        onCancel={() => setModal(null)}
        footer={null}
      >
        {/* @ts-ignore */}
        {modal?.children || null}
      </Modal>
      {celciusScheduleData._id && (
        <div className={clsx(styles.panel_container)}>
          <Form.Item
            label="Do you agree with the claim information as published above?"
            required
            className="!px-4 sm:!px-10"
          >
            <Controller
              name="is_soa_amount_agreed"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  status={errors.is_soa_amount_agreed && "error"}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  placeholder="Select"
                />
              )}
            />
            <Typography.Text type="danger">
              {errors.is_soa_amount_agreed?.message?.toString()}
            </Typography.Text>
          </Form.Item>

          {values?.is_soa_amount_agreed === "Yes" && (
            <Form.Item
              label="Upload latest Celsius Account Statement from your Celsius account"
              required
              className="!px-4 sm:!px-10"
            >
              <Dragger
                listType="picture"
                accept="image/*,.pdf"
                customRequest={(options) =>
                  uploadImage(options, setValue, "celsius_statement")
                }
                name="file"
                onChange={handleOnChange}
                defaultFileList={
                  defaultFileList?.length
                    ? defaultFileList
                    : formData.celsius_statement?.fileList
                }
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Drop your file here, or browse
                </p>
                <p className="ant-upload-hint">Support PDF and images</p>
              </Dragger>
              <Typography.Text type="danger">
                {errors.celsius_statement?.message?.toString()}
              </Typography.Text>
            </Form.Item>
          )}
        </div>
      )}

      <div className={styles.navigation}>
        <Button onClick={handleBackStep}>Back</Button>
        <Button htmlType="submit">{values.is_soa_amount_agreed === "No" ? "Submit" : "Next"}</Button>
      </div>
    </Form>
  );
};
export default Step2;
