import React, { useState, useRef } from "react";
import styles from "./index.module.scss";
import { BsDot } from "react-icons/bs";
import SignatureCanvas from "react-signature-canvas";
import AgreementModal from "./Modal";
import { useNavigate } from "react-router-dom";
import LogoCelsius from "../../../statics/images/logo_celsius_icon.svg";
import { notification } from "antd";
function MasterAgreement() {
  const [showAggrement, setShowAggrement] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const cancelDisclaimer = () => {
    setShowAggrement(false);
  };

  const Context = React.createContext({
    name: "Default",
  });

  const openNotification = (placement: any) => {
    api.info({
      message: `Notification ${placement}`,
      description: (
        <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>
      ),
      placement,
    });
  };
  const signCanvas = useRef() as React.MutableRefObject<any>;
  const navigate = useNavigate();
  //handle case
  
  const handleReadyAggrement = () => {
     
    if (signCanvas?.current?.getTrimmedCanvas()?.toDataURL()) {
      cancelDisclaimer();
      navigate("/celsius");
    } else
      api.error({
        message: `Error`,
        description: "Signature is required",
      });
  };


  return (
    <div className={styles.main}>
      {contextHolder}
      <AgreementModal
        isModalOpen={showAggrement}
        cancelDisclaimer={cancelDisclaimer}
      />{" "}
      <div className={styles.titleOne}>
        <img src={LogoCelsius} width={50} height={36} alt="celsius" /> Celsius
        Transfer
      </div>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.header}>Master Agreement</div>
          <div className={styles.subText}>
            Please review the complete Terms and Conditions of the Master
            Agreement for your Celsius Claim and sign the agreement to proceed.
          </div>

          <div className={styles.participate}>
            <BsDot className={styles.dot} />
            <div className={styles.participateHeader}>Participant:</div>
            <div className={styles.participateName}>Simon</div>
          </div>

          <div className={styles.agreementFormat}>
            <div className={styles.agreementFormatHeader}>MASTER AGREEMENT</div>
            <div className={styles.agreementFormatSection1}>
              <div className={styles.section1_part1}>
                <div className={styles.title}>Draft No:</div>
                <div className={styles.date}>[]</div>
              </div>
              <div className={styles.section1_part2}>
                <div className={styles.title}>Draft Date:</div>
                <div className={styles.date}>[Date]</div>
              </div>
              <div className={styles.section1_part3}>
                <div className={styles.title}>Dated:</div>
                <div className={styles.date}>[date]</div>
              </div>

              <div className={styles.breaker} />
            </div>

            <div className={styles.agreementFormatSection2}>
              <div className={styles.section2_part1}>[party 1]</div>
              <div className={styles.section2_part1}>Celsius0 SPV</div>

              <div className={styles.section2_part1}>
                Heimdall Operations Limited
              </div>
              <div className={styles.section2_part1}>OPNX</div>
            </div>
            <div className={styles.breaker} />
          </div>

          <button
            className={styles.button}
            onClick={() => setShowAggrement(true)}
          >
            Read Master Agreement
          </button>
          <div className={styles.signaturePage}>
            <div className={styles.signatureHeading}>
              Please sign to agree with the agreement
              <span style={{ color: "#F04438" }}>*</span>
            </div>
            <SignatureCanvas
              penColor="green"
              canvasProps={{ className: styles.formSignature }}
              ref={signCanvas}
            />
          </div>

          <div className={styles.dateSection}>
            <div className={styles.dateHeader}>Date and Time</div>
            <div className={styles.dateText}>
              Tue Mar 14 2023 08:04:17 GMT+0700 (Indochina Time)
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => handleReadyAggrement()} className={styles.submit}>
        Submit
      </button>
    </div>
  );
}

MasterAgreement.propTypes = {};

export default MasterAgreement;
