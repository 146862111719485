import { Button, Col, Modal, Row, Tabs, TabsProps, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useCallback, useMemo, useState } from "react";
import CryptoIcon from "../../../../components/CryptoIcon";
import styles from "./index.module.scss";
import { AiFillEye } from "react-icons/ai";
import { baseURL } from "../../../../api/client";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const mobileBalanceItems = [
  {
    label: `Earn Account`,
    value: "earn",
  },
  {
    label: `Custody Account`,
    value: "custody",
  },
  {
    label: `Withheld Account`,
    value: "witheld",
  },
  {
    label: `Borrow Account`,
    value: "borrow",
  },
];

const txHistoryItems = [
  {
    label: `Earn Account`,
    value: "earn",
  },
  {
    label: `Custody Account`,
    value: "custody",
  },
];
export default function AccountBalances({ surveyData, setPreview }: any) {
  const [balanceView, setBalanceView] = useState<boolean | null>(null);
  const [selectedBalance, setSelectedBalance] = useState<any | null>(null);

  const [txHistoryView, setTxHistoryView] = useState<boolean | null>(null);
  const [selectedTx, setSelectedTx] = useState<any | null>(null);
  const [fileSuffix, setFileSuffix] = useState<any | null>(null);

  const renderTxTab = useCallback(
    (data: { tx: any[]; pdf: any[]; excl: any[]; name: string }) => {
      const columns: ColumnsType<Record<string, any>> = [
        {
          title: "Date",
          dataIndex: "TxsDate",
          key: "TxsDate",
          render: (_, { TxsDate }, index) => (
            <Row key={index} className={""}>
              <Row align="middle" gutter={8}>
                <Col>
                  <Typography.Text>{TxsDate}</Typography.Text>
                </Col>
              </Row>
            </Row>
          ),
        },
        {
          title: "Type",
          dataIndex: "Type",
          key: "Type",
        },
        {
          title: "Token",
          dataIndex: "token",
          key: "token",
          render: (_, { token }) => (
            <Row gutter={4}>
              <Col>
                <CryptoIcon name={token?.toLowerCase()} />{" "}
              </Col>
              <Col>
                <Typography.Text>{token?.toUpperCase()}</Typography.Text>
              </Col>
            </Row>
          ),
        },
        {
          title: "Value",
          key: "Value",
          dataIndex: "Value",
        },
      ];

      return (
        <div className={styles["tx-table"]}>
          <Table pagination={false} columns={columns} dataSource={data.tx} />

          {data?.excl?.[0]?.content && (
            <Row className={styles["table-row"]}>
              <Col span={12}>
                <Typography.Text strong>
                  {data.name} Tx Excel File
                </Typography.Text>
              </Col>
              <Row>
                <Col span={12}>
                  <div className={""}>
                    <Button
                      className={"file-action text-[#03ABAB]"}
                      type="link"
                      onClick={() => setPreview(data?.excl?.[0]?.content)}
                    >
                      View
                    </Button>
                  </div>
                </Col>
              </Row>
            </Row>
          )}

          {data?.pdf?.[0]?.content && (
            <Row className={styles["table-row"]}>
              <Col span={12}>
                <Typography.Text strong>
                  {data.name} Tx PDF File
                </Typography.Text>
              </Col>
              <Row>
                <Col span={12}>
                  <div className={""}>
                    <Button
                      className={"file-action text-[#03ABAB]"}
                      type="link"
                      onClick={() => setPreview(data?.pdf?.[0]?.content)}
                    >
                      View
                    </Button>
                  </div>
                </Col>
              </Row>
            </Row>
          )}
        </div>
      );
    },
    [setPreview]
  );

  const renderBalanceTab = useCallback((data: any[]) => {
    const columns: ColumnsType<Record<string, any>> = [
      {
        title: "Token",
        dataIndex: "token",
        key: "token",
        render: (_, { price, token }) => (
          <Row gutter={4}>
            <Col>
              <CryptoIcon name={token?.toLowerCase()} />{" "}
            </Col>
            <Col>
              <Typography.Text>{token?.toUpperCase()}</Typography.Text>
            </Col>
          </Row>
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (_, { price }) => <Typography.Text>${price}</Typography.Text>,
      },
      {
        title: "Quantity",
        dataIndex: "value",
        key: "value",
        render: (_, { Quantity }) => (
          <Typography.Text>{Quantity}</Typography.Text>
        ),
      },
      {
        title: "Value",
        key: "item_value",
        dataIndex: "item_value",
        render: (_, item) => (
          <Typography.Text>
            ${Number(item?.["Claim Value"])?.toFixed(3)}
          </Typography.Text>
        ),
      },
    ];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  }, []);

  const balanceItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderBalanceTab(surveyData?.celsius_earn_balances),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderBalanceTab(surveyData?.celsius_custody_balances),
      },
      {
        key: "3",
        label: `Withheld Account`,
        children: renderBalanceTab(surveyData?.celsius_witheld_balances),
      },
      {
        key: "4",
        label: `Borrow Account`,
        children: renderBalanceTab(surveyData?.celsius_borrow_balances),
      },
    ],
    [
      renderBalanceTab,
      surveyData?.celsius_borrow_balances,
      surveyData?.celsius_custody_balances,
      surveyData?.celsius_earn_balances,
      surveyData?.celsius_witheld_balances,
    ]
  );

  const txItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderTxTab({
          tx: surveyData?.celsius_tx_earn,
          pdf: surveyData?.celsius_pdf_earn,
          excl: surveyData?.celsius_excl_earn,
          name: "Earn",
        }),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderTxTab({
          tx: surveyData?.celsius_tx_custody,
          pdf: surveyData?.celsius_pdf_custody,
          excl: surveyData?.celsius_excl_custody,
          name: "Custody",
        }),
      },
    ],
    [
      renderTxTab,
      surveyData?.celsius_excl_custody,
      surveyData?.celsius_excl_earn,
      surveyData?.celsius_pdf_custody,
      surveyData?.celsius_pdf_earn,
      surveyData?.celsius_tx_custody,
      surveyData?.celsius_tx_earn,
    ]
  );

  const handleBalanceTabForMobile = (data: any) => {
    setBalanceView(true);
    setSelectedBalance(surveyData?.[`celsius_${data}_balances`]);
  };

  const handleTxHistoryForMobile = (data: any) => {
    setSelectedTx(surveyData?.[`celsius_tx_${data}`]);
    setFileSuffix(data);
    setTxHistoryView(true);
  };

  return (
    <div className="">
      <div className="hidden sm:block">
        <div className={styles["account-balances"]}>
          <div className={styles.block}>
            <Typography.Text className={styles.title}>Balances</Typography.Text>
            <Tabs defaultActiveKey="1" items={balanceItems} />
          </div>

          <div className={styles.block+' ' + styles.tx_block}>
            <Typography.Text className={styles.title}>
              Tx History
            </Typography.Text>
            <Tabs defaultActiveKey="1" items={txItems} />
          </div>
        </div>

        {surveyData?.["celsius_statement"]?.[0]?.content && (
          <div className={styles.block}>
            <Typography.Text className={styles.title}>
              Account Statement
            </Typography.Text>
            <div className="flex items-center align-middle justify-between p-[20px] border border-[#EDEDED] rounded-lg">
              <Typography.Text className="font-bold text-sm">
                Account Statement PDF
              </Typography.Text>
              <Button
                className={"file-action text-[#03ABAB]"}
                type="link"
                onClick={() =>
                  setPreview(baseURL +"/files?name=" +
                  encodeURIComponent(surveyData?.["celsius_statement"]?.[0]?.name)+ "&token=" + localStorage.getItem('token'))
                }
              >
                View
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col sm:!hidden">
        <div className="text-[#111111] text-lg font-semibold">Balances</div>

        {mobileBalanceItems.map((el) => (
          <div className="flex justify-between py-2 border-b border-[#EDEDED]">
            <div className="text-[#111111] text-sm font-semibold">
              {el.label}
            </div>
            <MdOutlineKeyboardArrowRight
              className="text-[#03ABAB] text-xl cursor-pointer"
              onClick={() => handleBalanceTabForMobile(el.value)}
            />
          </div>
        ))}

        <Modal
          footer={null}
          title="Balances"
          open={!!balanceView}
          onCancel={() => setBalanceView(null)}
        >
          <div className="flex flex-col gap-5">
            {selectedBalance &&
              selectedBalance.map((item: any) => {
                return (
                  <div className="flex border border-[#EDEDED] rounded-md flex-col gap-2 py-2 px-3">
                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Token
                      </span>
                      <div className="flex gap-2">
                        <CryptoIcon name={item?.token?.toLowerCase()} />{" "}
                        <span className="font-normal text-[#666666] text-sm">
                          {item?.token?.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Price as of July 13, 2022
                      </span>
                      <span className="font-normal text-[#666666] text-sm">
                        ${item?.price}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Quantity
                      </span>
                      <span className="font-normal text-[#666666] text-sm">
                        {item?.Quantity}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Value
                      </span>
                      <span className="font-normal text-[#666666] text-sm">
                        <Typography.Text>
                          ${Number(item?.["Claim Value"])?.toFixed(3)}
                        </Typography.Text>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal>
      </div>

      <div className="flex flex-col mt-5 mb-10 sm:!hidden">
        <div className="text-[#111111] text-lg font-semibold">Tx History</div>
        {txHistoryItems.map((el) => (
          <div className="flex justify-between py-2 border-b border-[#EDEDED]">
            <div className="text-[#111111] text-sm font-semibold">
              {el.label}
            </div>
            <MdOutlineKeyboardArrowRight
              className="text-[#03ABAB] text-xl cursor-pointer"
              onClick={() => handleTxHistoryForMobile(el.value)}
            />
          </div>
        ))}
        <Modal
          footer={null}
          title="Tx History"
          open={!!txHistoryView}
          onCancel={() => setTxHistoryView(null)}
        >
          <div className="flex flex-col gap-5">
            {selectedTx &&
              selectedTx.map((item: any) => {
                return (
                  <div className="flex border border-[#EDEDED] rounded-md flex-col gap-2 py-2 px-3">
                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Date
                      </span>

                      <span className="font-normal text-[#666666] text-sm">
                        {item?.TxsDate}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Type
                      </span>
                      <span className="font-normal text-[#666666] text-sm">
                        {item?.Type}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Token
                      </span>
                      <div className="flex gap-2">
                        <CryptoIcon name={item?.token?.toLowerCase()} />{" "}
                        <span className="font-normal text-[#666666] text-sm">
                          {item?.token?.toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-semibold text-[#111111] text-sm">
                        Value
                      </span>
                      <span className="font-normal text-[#666666] text-sm">
                        <Typography.Text>{item.Value}</Typography.Text>
                      </span>
                    </div>
                  </div>
                );
              })}

            {surveyData?.[`celsius_excl_${fileSuffix}`]?.[0]?.content && (
              <div className="flex flex-col">
                <Typography.Text strong>
                  {`${
                    fileSuffix.charAt(0).toUpperCase() + fileSuffix.slice(1)
                  }`}{" "}
                  Tx Excel File
                </Typography.Text>

                <div className={""}>
                  {surveyData?.[`celsius_excl_${fileSuffix}`]?.[0]?.content ? (
                    <button
                      className={
                        "border border-#E2E2E2 rounded-md text-[#03ABAB] py-2 w-full text-center"
                      }
                      onClick={() =>
                        setPreview(
                          surveyData?.[`celsius_excl_${fileSuffix}`]?.[0]
                            ?.content
                        )
                      }
                    >
                      <div className="flex gap-1 text-[#03ABAB]">
                        <div className="text-[14px] font-semibold">View</div>
                        <AiFillEye className="text-xl" />
                      </div>
                    </button>
                  ) : (
                    // </a>
                    <Typography.Text>N/A</Typography.Text>
                  )}
                </div>
              </div>
            )}

            {surveyData?.[`celsius_pdf_${fileSuffix}`]?.[0]?.content && (
              <div className="flex flex-col">
                <Typography.Text strong>
                  {`${
                    fileSuffix.charAt(0).toUpperCase() + fileSuffix.slice(1)
                  }`}{" "}
                  Tx PDF File
                </Typography.Text>

                <div className={""}>
                  {surveyData?.[`celsius_pdf_${fileSuffix}`]?.[0]?.content ? (
                    <button
                      className={
                        "border border-#E2E2E2 rounded-md text-[#03ABAB] py-2 w-full text-center"
                      }
                      onClick={() =>
                        setPreview(
                          surveyData?.[`celsius_pdf_${fileSuffix}`]?.[0]
                            ?.content
                        )
                      }
                    >
                      <div className="flex gap-1 text-[#03ABAB]">
                        <div className="text-[14px] font-semibold">View</div>
                        <AiFillEye className="text-xl" />
                      </div>
                    </button>
                  ) : (
                    // </a>
                    <Typography.Text>N/A</Typography.Text>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}
