const theme = {
  token: {
    colorPrimary: "#037C7C",
    colorError: "#B11C18",
    colorTextBase: "#111111",
    colorTextSecondary: "#666666",
  },
  components: {
    Select: {},
  },
};

export default theme;
