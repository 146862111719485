import { Typography } from "antd";
import moment from "moment";
import { useContext, useMemo } from "react";
import { ClaimContext } from "../../ClaimContextProvider";

const ClaimInformation = () => {
  const {
    formData,
    celciusAccountTx,
    celciusScheduleData,
    celciusAccountBalance,
    prices,
  } = useContext(ClaimContext);
  const total = useMemo(() => {
    const totalCelsiusEarnBalances = (
      formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
        ? celciusAccountBalance?.earn_account ?? []
        : celciusScheduleData?.earn_account ?? []
    ).reduce((previousValue, currentValue: any) => {
      return (
        previousValue +
        Number(currentValue.value) *
        Number(
          prices.find((price) => price.coin === currentValue.token)?.price ??
          null
        )
      );
    }, 0);

    const totalCelsiusCustodyBalances = (
      formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
        ? celciusAccountBalance?.custody_account ?? []
        : celciusScheduleData?.custody_account ?? []
    ).reduce((previousValue, currentValue: any) => {
      return (
        previousValue +
        Number(currentValue.value) *
        Number(
          prices.find((price) => price.coin === currentValue.token)?.price ??
          null
        )
      );
    }, 0);

    const totalCelsiusWitheldBalances = (
      formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
        ? celciusAccountBalance?.withheld_account ?? []
        : celciusScheduleData?.withheld_account ?? []
    ).reduce((previousValue, currentValue: any) => {
      return (
        previousValue +
        Number(currentValue.value) *
        Number(
          prices.find((price) => price.coin === currentValue.token)?.price ??
          null
        )
      );
    }, 0);

    const totalCelsiusBorrowBalances = (
      formData?.is_soa_available === "No" ||
        formData?.is_soa_amount_agreed === "No"
        ? celciusAccountBalance?.collateral_on_loan_receivable ?? []
        : celciusScheduleData?.collateral_on_loan_receivable ?? []
    ).reduce((previousValue, currentValue: any) => {
      return (
        previousValue +
        Number(currentValue.value) *
        Number(
          prices.find((price) => price.coin === currentValue.token)?.price ??
          null
        )
      );
    }, 0);

    return (
      totalCelsiusEarnBalances 
      // totalCelsiusBorrowBalances +
      // totalCelsiusCustodyBalances +
      // totalCelsiusWitheldBalances
    );
  }, [prices, celciusAccountBalance, celciusScheduleData, formData]);
  return (
    <div className="flex flex-col gap-8">
      <div className="flex max-[450px]:flex-col max-[450px]:gap-2">
        <div className="grow gap-2 w-1/2 flex flex-col justify-start">
          <Typography.Text className="text-[#666666] text-sm font-medium">
            Does your claim form part of the latest schedule of assets available
            on the administrator's portal?
          </Typography.Text>
          <Typography.Text className="text-[#111111] font-semibold text-sm">
            {formData["is_soa_available"]}
          </Typography.Text>
        </div>

        {formData["is_soa_available"] === "Yes" && (
          <div className="grow gap-2 w-1/2 flex flex-col justify-start">
            <Typography.Text className="text-[#666666] text-sm font-medium">
              SCHEDULE F LINE number for your claim listed in the schedule
            </Typography.Text>
            <Typography.Text className="text-[#111111] font-semibold text-sm">
              {formData["claim_link"] || "N/A"}
            </Typography.Text>
          </div>
        )}
      </div>

      {formData["is_soa_available"] === "Yes" && (
        <div className="flex max-[450px]:flex-col max-[450px]:gap-2">
          <div className="grow gap-2 w-1/2 flex flex-col justify-start">
            <Typography.Text className="text-[#666666] text-sm font-medium">
              Is the claim contigent, unliquidated or disputed?
            </Typography.Text>

            {celciusScheduleData["indicate"] ? (
              <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#31B43E] flex justify-center items-center font-semibold">
                • YES
              </div>
            ) : (
              <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#CF352A] flex justify-center items-center font-semibold">
                • NO
              </div>
            )}
          </div>

          <div className="grow gap-2 w-1/2 flex flex-col justify-start">
            <Typography.Text className="text-[#666666] text-sm font-medium">
              Do you agree with the claim information as published?
            </Typography.Text>
            <Typography.Text className="text-[#111111] font-semibold text-sm">
              {formData["is_soa_amount_agreed"] || "N/A"}
            </Typography.Text>
          </div>
        </div>
      )}

      {formData["is_soa_available"] === "Yes" && (
        <div className="grow w-100 flex flex-col justify-start gap-2">
          <Typography.Text className="text-[#666666] text-sm font-medium">
            Is the claim subject to offset?
          </Typography.Text>
          {celciusScheduleData["is_claim_to_offset"] ? (
            <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#31B43E] flex justify-center items-center font-semibold">
              • YES
            </div>
          ) : (
            <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#CF352A] flex justify-center items-center font-semibold">
              • NO
            </div>
          )}
        </div>
      )}

      <div className="flex max-[450px]:flex-col max-[450px]:gap-2">
        <div className="grow gap-2 w-1/2 flex flex-col justify-start">
          <Typography.Text className="text-[#666666] text-sm font-medium">
            {" "}
            Total Claim Amount{" "}
          </Typography.Text>
          <Typography.Text className="text-[#111111] font-semibold text-sm">
            ${total}
          </Typography.Text>
        </div>
        <div className="grow gap-2 w-1/2 flex flex-col justify-start">
          <Typography.Text className="text-[#666666] text-sm font-medium">
            {" "}
            Total Preference Risk{" "}
          </Typography.Text>
          <Typography.Text className="text-[#111111] font-semibold text-sm">
            $
            {Math.max(
              celciusAccountTx.earn_account
                .filter(({ date, token, type, value }) => {
                  return !!date && !!token && !!type && !!value;
                })
                .sort(
                  (prev, next) =>
                    moment(prev.date).unix() - moment(next.date).unix()
                )
                .reduce((previousValue, { type, value }) => {
                  return type === "Withdraw"
                    ? previousValue + Number(value)
                    : Math.max(previousValue - Number(value), 0);
                }, 0),
              0
            ) +
              Math.max(
                celciusAccountTx.custody_account
                  .filter(({ date, token, type, value }) => {
                    return !!date && !!token && !!type && !!value;
                  })
                  .sort(
                    (prev, next) =>
                      moment(prev.date).unix() - moment(next.date).unix()
                  )
                  .reduce((previousValue, { type, value }) => {
                    return type === "Withdraw"
                      ? previousValue + Number(value)
                      : Math.max(previousValue - Number(value), 0);
                  }, 0),
                0
              )}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
export default ClaimInformation;
