import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { Menu } from 'antd';
import Header from "../Header";
import Footer from "./Footer";
import styles from "./index.module.scss";
import clsx from "clsx";
function DefaultLayout() {
  return (
    <Layout className={styles.main}>
      <Header />
      <Layout.Content
        className={clsx(styles.container, "container container-content")}
      >
        <Outlet />
      </Layout.Content>
      <Footer />
    </Layout>
  );
}

DefaultLayout.propTypes = {};

export default DefaultLayout;
