import styles from "./index.module.scss";
import { TbCopy } from "react-icons/tb";
import { MdOutlineArrowOutward } from "react-icons/md";
import CustomTag from "../../../../components/CustomTags";
import ClaimInfoModal from "./ClaimInfo";
import { useState } from "react";
import ClaimAmountModal from "./ClaimAmount";
import { Typography } from "antd";
import { survey_obj, truncDigits } from "../../../../common/utils";
import { get_status } from "../TableRow";

interface IProps {
  claim: any;
  prices: any;
}

function TableClaimMobile({ claim, prices }: IProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showClaimAmount, setShowClaimAmount] = useState(false);

  // const get_status = (status: any, admin_status: any) => {
  //   if (admin_status === "Rejected" || admin_status === "" || !admin_status) {
  //     return (
  //       <CustomTag bgColor="#EAE9FF" color="#6558FF">
  //         Under Review
  //       </CustomTag>
  //     );
  //   } else if (admin_status === "Approved") {
  //     return (
  //       <CustomTag bgColor="#E3FAE5" color="#31B43E">
  //         Transfer in Progress
  //       </CustomTag>
  //     );
  //   }
  // };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleShowClaimAmount = () => {
    setShowClaimAmount(!showClaimAmount);
  };

  function preference(survey: any) {
    return (
      <>
        {parseFloat(survey.preferenceRisk) <= 0 ? (
          <>
            <span>Not Flagged</span>
          </>
        ) : parseFloat(survey.preferenceRisk) > 0 ? (
          <>
            <div className={styles.subTableCell}>
              <div className={styles.info}>
                <span style={{ alignSelf: "center" }}>
                  Estimated: {truncDigits(parseFloat(survey.preferenceRisk), 2)}{" "}
                  USD
                </span>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      <ClaimInfoModal
        claim={claim}
        prices={prices}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
      />
      <ClaimAmountModal
        handleCancel={handleShowClaimAmount}
        isModalOpen={showClaimAmount}
        claim={claim}
        prices={prices}
      />
      <div className={styles.main}>
        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-medium text-sm">
            Bankruptcy Case
          </span>
          <span className="font-semibold text-sm">
            {claim.claim_type === "celsius"
              ? "Celsius Ch.11 in Southern District of NY"
              : 'Celsius Ch.11 in Southern District of NY'}
          </span>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-medium text-sm">Claim ID</span>
          <div className="flex gap-1">
            <span className="font-normal text-sm">{claim.claim_id}</span>
            <Typography.Text
              copyable={{
                text: claim.claim_id,
                icon: <TbCopy className="self-center text-lg text-[#03ABAB]" />,
              }}
            ></Typography.Text>
          </div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <span className="text-[#666666] font-normal text-sm">
              Claim Amount
            </span>
            <span
              onClick={() => setShowClaimAmount(true)}
              className="text-[#03ABAB] font-normal text-sm border-b"
            >
              More Info
            </span>
          </div>

          <span className="font-medium text-[#111111] text-sm">
            {claim.survey
              ? "$" + truncDigits(survey_obj(claim.survey).totalClaim, 2)
              : "N/A"}
          </span>
        </div>
        <div className="border border-[#EDEDED] my-3" />
        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Preference Risk
          </span>
          <span className="">
          {preference(survey_obj(claim.survey))}
          </span>
        </div>
  

        <div className="border border-[#EDEDED] my-3" />
        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Schedule F Line
          </span>
          <div className="flex gap-1">
          {survey_obj(claim?.survey)?.claim_link || 'N/A'}
          </div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">Claim No</span>
          <span className="">
           {survey_obj(claim?.survey)?.poc_file_number || 'N/A'}
           
          </span>
        </div>

        <div
          onClick={() => setIsModalOpen(true)}
          className="border border-[#E2E2E2] text-center py-3 rounded-md cursor-pointer mt-3"
        >
          <span className="font-semibold text-[#111111] text-sm">
            Claim Information
          </span>
        </div>
      </div>
    </>
  );
}
TableClaimMobile.propTypes = {};

export default TableClaimMobile;
