import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import clsx from "clsx";
import Footer from "./Footer";
import styles from "./index.module.scss";
import Header from "../Header";

function HomeLayoyt() {
  return (
    <Layout className={styles.main}>
      <Header />
      <Layout.Content
        className={clsx(styles.container, "container container-content")}
      >
        <Outlet />
      </Layout.Content>
      <Footer />
    </Layout>
  );
}

HomeLayoyt.propTypes = {};

export default HomeLayoyt;
