import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import Header from "../Header";
import Footer from "./Footer";
import styles from "./index.module.scss";
import clsx from "clsx";

function DefaultLayout() {
  return (
    <Layout className={styles.main}>
      <Header />
      {/* <Header />
      <Trending />
      <BackgroundDiv />
      <PageTitle />
      <Layout.Content className="container container-content">
        <div className="site-layout-background">
          <Outlet />
        </div>
      </Layout.Content>
      <Footer /> */}
      <Layout.Content
        className={clsx(styles.container, "container container-content")}
      >
        <Outlet />
      </Layout.Content>
      <Footer />
    </Layout>
  );
}

DefaultLayout.propTypes = {};

export default DefaultLayout;
