import React from "react";

type CryptoIconType = {
  name: string;
  size?: number;
};

function CryptoIcon({ name, size = 20 }: CryptoIconType) {
  let icon;
  try {
    icon = require(`../../statics/images/token-assets/${name}.svg`);
  } catch (err) {}
  return icon ? (
    <img src={icon} style={{ width: size, height: size }} alt="icon" />
  ) : (
    <></>
  );
}

export default CryptoIcon;
