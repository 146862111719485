import styles from "./index.module.scss";
import { BsEye, BsDownload } from "react-icons/bs";
import dayjs from "dayjs";
import { CheckingTxt } from "../CheckingTxt";
import { Button, Divider } from "antd";
import moment from "moment";
import { baseURL } from "../../../../api/client";
import { EyeFilled } from "@ant-design/icons";

export default function ProofOfClaim({ surveyData, setPreview }: any) {
  return (
    <div className="flex flex-col gap-[25px] bg-white">
      <div className="flex flex-col sm:flex-row gap-[25px]">
        <div className="flex flex-col w-1/3 gap-2">
          <div className="text-[#666666] text-[14px] font-medium">
            Proof of Claim Filed
          </div>
          <div className="text-black text-[14px] font-bold">
            <CheckingTxt text={surveyData?.is_poc_filed} />
          </div>
        </div>

        <Divider type="horizontal" className="my-0 block sm:hidden " />
        <div className="flex flex-col w-1/3 gap-2">
          <div className="text-[#666666] text-[14px] font-medium">
            Date Filed
          </div>
          <div className="text-black text-[14px] font-bold">
            {surveyData?.is_poc_general_bar
              ? moment(new Date()).format("DD/MM-YYYY - HH:mm")
              : "N/A"}
          </div>
        </div>
        <Divider type="horizontal" className="my-0 block sm:hidden " />

        <div className="flex flex-col w-1/3 gap-2">
          <div className="text-[#666666] text-[14px] font-medium">
            Claim Number
          </div>
          <div className="text-black text-[14px] font-bold">
            {surveyData?.poc_file_number ?? "N/A"}
          </div>
        </div>
      </div>

      <Divider type="horizontal" className="my-0 block sm:hidden " />

      <div className="flex flex-col sm:flex-row gap-[25px]">
        <div className="flex flex-col w-1/3 gap-2">
          <div className="text-[#666666] text-[14px] font-medium">
            Objections Against the Claim
          </div>
          <div className="text-black text-[14px] font-bold">
            <CheckingTxt text={surveyData?.is_poc_registered} />
          </div>
        </div>

        <Divider type="horizontal" className="my-0 block sm:hidden " />
        <div className="flex flex-col w-1/3 gap-2">
          <div className="text-[#666666] text-[14px] font-medium">
            Name as per Proof of Claim
          </div>
          <div className="text-black text-[14px] font-bold">
            {surveyData?.name_appear_poc}
          </div>
        </div>
        <Divider type="horizontal" className="my-0 block sm:hidden " />

        <div className="flex flex-col w-1/3 gap-2">
          <div className="text-[#666666] text-[14px] font-medium">
          Proof of Claim PDF
          </div>
          <div className="text-black text-[14px] font-bold">
            <Button
              className={"file-action text-[#03ABAB] p-0 flex items-center"}
              type="link"
              onClick={() =>
                setPreview(
                  baseURL +
                    "/files?name=" +
                    encodeURIComponent(surveyData?.["poc_link"]?.[0]?.name)+ "&token=" + localStorage.getItem('token')
                )
              }
            >
              <EyeFilled />
              View
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
