import LogoFTX from "../../statics/images/ftx_logo.svg";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { clientAPI } from "../../api/client";
import { Collapse, Modal, Row } from "antd";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PersonalInfo from "./Components/PersonalInfo";
import ClaimInfo from "./Components/ClaimInfo";
import styles from "./index.module.scss";
import ProofOfClaim from "./Components/ProofOfClaim";
import AccountBalances from "./Components/AccountBalance";

export default function MyFTXClaimInfo() {
  const [surveyData, setSurveyData] = useState<any>();
  const [claimData, setClaimData] = useState<any>({});
  const [claimBalance, setClaimBalance] = useState<any>();
  const { id } = useParams();
  const [preview, setPreview] = useState<string | null>(null);

  const getClaimData = async () => {
    const result = await clientAPI("post", "/getFtxClaimById", {
      claim_id: id,
      token: localStorage.getItem("token"),
    });
    if (result.status === "OK") {
      setClaimData({
        ...result?.message,
        note: JSON.parse(result?.message?.note || []),
      });
      const survey = JSON.parse(result?.message.survey || "{}");
      setSurveyData(survey);
      console.log(survey, result?.message, "surveysurvey");
      let count = 0;
      const balance = survey.celsius_earn_balances?.map((val: any) => {
        return {
          key: ++count,
          token: val.token,
          price: val.price,
          quantity: val.Quantity,
          claimValue: `$${val["Claim Value"]}`,
        };
      });

      setClaimBalance(balance);
    }
  };

  useEffect(() => {
    getClaimData();
  }, []);

  const viewPdfFile = () => {
    window.open(surveyData?.celsius_statement?.[0]?.content, "_blank");
  };
  const panelList = [
    {
      key: "claimant-information",
      label: "Claimant Personal Information",
      content: <PersonalInfo surveyData={surveyData} setPreview={setPreview}/>,
    },
    {
      key: "claim-information",
      label: "Claim General Information",
      content: <ClaimInfo surveyData={surveyData} claimData={claimData} setPreview={setPreview}/>,
    },
    {
      key: "account-information",
      label: "Account Balances",
      content: <AccountBalances surveyData={surveyData} setPreview={setPreview}/>,
    },
    {
      key: "poc-information",
      label: "Proof of Claim",
      content: <ProofOfClaim surveyData={surveyData} setPreview={setPreview} />,
    },
  ];
  let panelListUpdate = panelList;
  if (surveyData?.is_poc_filed === 'No' || !surveyData?.is_poc_filed) {
    panelListUpdate = [panelList[0], panelList[1], panelList[2]];
  }
  const openPanel = panelList.map((panel) => panel.key);
  return (
    <div className="flex flex-col gap-5 justify-center items-center">
      <div className="flex flex-col items-center gap-3">
        <img src={LogoFTX} width={120} alt="celsius" />{" "}
        <div className="text-[30px] text-[#111111] font-semibold">
          Claim Information
        </div>
      </div>
      {/* <button className="bg-[#03ABAB] rounded-md px-7 py-3 my-[25px]">
        <span className="text-[14px] text-white font-normal">
          Read Master Agreement
        </span>
      </button> */}
      <div className="w-full">
        <Collapse
          className={styles.panel_collapse}
          defaultActiveKey={openPanel}
          expandIcon={() => null}
        >
          {panelListUpdate.map((panel) => (
            <Collapse.Panel header={panel.label} key={panel.key}>
              <div className="p-5">
                {panel.content}
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
      {/* <ClaimInfo claimData={claimData} surveyData={surveyData} /> */}
      {/* <AccountBalances */}
      {/*   claimData={claimData} */}
      {/*   surveyData={surveyData} */}
      {/*   setPreview={setPreview} */}
      {/* /> */}
      {/* <ProofOfClaim */}
      {/*   claimData={claimData} */}
      {/*   surveyData={surveyData} */}
      {/*   setPreview={setPreview} */}
      {/* /> */}
      <Modal
        open={!!preview}
        onCancel={() => setPreview(null)}
        centered
        footer={false}
        width={"80vw"}
        bodyStyle={{ height: "80vh" }}
      >
        <Row style={{ height: "100%" }}>
          {preview && (
            <DocViewer
              documents={[{ uri: preview }]}
              pluginRenderers={DocViewerRenderers}
            />
          )}
        </Row>
      </Modal>
    </div>
  );
}
