import styles from './index.module.css';
import { survey_obj, truncDigits } from '../../../common/utils';
import clsx from 'clsx';
import CryptoIcon from '../../../components/CryptoIcon';

interface IProps {
  claim?: any;
  prices?: any;
}

function ClaimAmount({ claim, prices }: IProps) {
  const getIcon = (value: any) => {
    const matchedCoin =
      prices &&
      (prices
        .find((item: any) => `${item.price}` === `${value}`)
        ?.coin?.toLowerCase() ||
        'btc');
    return matchedCoin;
  };

  return (
    <table className={clsx(styles.table, styles.subTable)}>
      <thead>
        <tr>
          <td className={styles.tr_td} style={{ width: '200px' }}>
            Coin
          </td>
          <td className={styles.tr_td} style={{ width: '150px' }}>
            Quantity
          </td>
          <td className={styles.tr_td} style={{ width: '250px' }}>
            USD Value as of July 13, 2022
          </td>

          <td className={styles.tr_td}>Claim Amount</td>
        </tr>
      </thead>

      <tbody>
        {survey_obj(claim.survey).celsius_balances.map(
          (claimAmount: any, index: any) => {
            const coinName = getIcon(claimAmount.price);
            return (
              <>
                <tr>
                  <td className={styles.td}>
                    <div className={styles.col1}>
                      <div className={styles.subcol1}>
                        <div className="mr-1">
                          <CryptoIcon name={coinName} size={20} />
                        </div>
                        <span> {coinName.toUpperCase()}</span>
                      </div>
                    </div>
                  </td>

                  <td className={styles.td}>
                    <div className={styles.col3}>{claimAmount.Quantity}</div>
                  </td>
                  <td className={styles.td}>
                    <div className={styles.col2}>
                      <span>
                        {' '}
                        ${claimAmount.Coin} per {coinName.toUpperCase()}
                      </span>
                    </div>
                  </td>

                  <td className={styles.td}>
                    <div className={styles.col4}>
                      <span>
                        {' '}
                        $
                        {truncDigits(
                          claimAmount.Coin * claimAmount.Quantity,
                          2
                        )}
                      </span>
                    </div>
                  </td>
                </tr>
              </>
            );
          }
        )}
      </tbody>
    </table>
  );
}

ClaimAmount.propTypes = {};

export default ClaimAmount;
