import CustomTag from "../../../../components/CustomTags";
import { Modal } from "antd";
import { survey_obj, truncDigits } from "../../../../common/utils";

interface IProps {
  handleCancel: any;
  isModalOpen: any;
  claim: any;
  prices: any;
}

function ClaimAmountModal({
  handleCancel,
  isModalOpen,
  claim,
  prices,
}: IProps) {
  const getIcon = (value: any) => {
    const matchedCoin =
      prices &&
      (prices
        .find((item: any) => `${item.price}` === `${value}`)
        ?.coin?.toLowerCase() ||
        'btc');
    return matchedCoin;
  };

  return (
    <Modal
      footer={null}
      title="Claim Amount"
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <div className="flex flex-col gap-5">
        {survey_obj(claim.survey).celsius_balances && survey_obj(claim.survey).celsius_balances.map(
          (claimAmount: any, index: any) => {
            const coinName = getIcon(claimAmount.price);
            return (
              <div className="flex border border-[#EDEDED] rounded-md flex-col gap-2 py-2 px-3">
                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                    Token
                  </span>
                  <div className="flex gap-2">
                    <span className="font-normal text-[#666666] text-sm">
                      {coinName.toUpperCase()}
                    </span>
                    <img
                      alt="coin"
                      style={{
                        height: 20,
                        width: 20,
                      }}
                      src={`https://cdn.jsdelivr.net/npm/cryptocurrency-icons@latest/svg/color/${coinName}.svg`}
                    />
                  </div>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                    Price as of July 13, 2022
                  </span>
                  <span className="font-normal text-[#666666] text-sm">
                    ${claimAmount.Coin} per {coinName.toUpperCase()}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                    Quantity
                  </span>
                  <span className="font-normal text-[#666666] text-sm">
                    {claimAmount.Quantity}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                    Claim Value
                  </span>
                  <span className="font-normal text-[#666666] text-sm">
                    ${truncDigits(claimAmount.Coin * claimAmount.Quantity, 2)}
                  </span>
                </div>
              </div>
            );
          }
        )}
      </div>
    </Modal>
  );
}
ClaimAmountModal.propTypes = {};

export default ClaimAmountModal;
