import { Button, Form, Input, Select, Typography } from "antd";
import { useCallback, useContext, useEffect } from "react";
import USER_ICON from "../../../../statics/images/user.svg";
import styles from "../index.module.scss";
import clsx from "clsx";
import { FTXClaimContext } from "../../ClaimContextProvider";

const Step1 = () => {
  const {
    userData,
    countries,
    handleNext,
    handleBack,
    useYupValidationResolver,
    formData,
    handleUpdateForm,
    form,
    handleSubmit,
    submitLoading,
    claimDataLoading,
  } = useContext(FTXClaimContext);

  const handleNextStep = async () => {
    try {
      const resValid = await form.validateFields(["ftx_claim_owner"]);
      console.log(resValid, "resValidresValid");

      if (resValid) {
        const value = form.getFieldValue("ftx_claim_owner");
        if (value === "Bought This Claim") {
          handleSubmit();
          return;
        }
        handleNext();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className={clsx(styles.panel_container)}>
        <Typography.Text className="font-semibold text-black text-2xl">
          Personal Details
        </Typography.Text>
        <div className={styles["detail-information"]}>
          {userData && (
            <div className={styles["personal_info"]}>
              <div className="flex items-center gap-x-1">
                <p className="m-0">
                  Hi <span>{userData.legalName}</span>, welcome.
                </p>
              </div>
            </div>
          )}
        </div>
        <Form.Item
          label="Are you the original claim holder or did you buy this claim from someone?"
          rules={[{ required: true, message: "Please input your data!" }]}
          name="ftx_claim_owner"
        >
          <Select
            options={[
              { label: "Original Claim Owner", value: "Original Claim Owner" },
              { label: "Bought This Claim", value: "Bought This Claim" },
            ]}
            placeholder="Select"
          />
        </Form.Item>
      </div>
      <div onClick={handleNextStep} className={styles.navigation}>
        <Button
          onClick={handleNextStep}
          loading={submitLoading || claimDataLoading}
        >
          Next
        </Button>
      </div>
    </>
  );
};
export default Step1;
