import PropTypes from "prop-types";
import { Button } from "antd";
import { useState } from "react";
import Disclaimer from "./Disclaimer";
import AgreementModal from "./MasterAggrement/Modal";
import MasterAgreement from "./MasterAggrement";
function Aggrement() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <MasterAgreement />
    </>
  );
}

Aggrement.propTypes = {};

export default Aggrement;
