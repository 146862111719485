import styles from "../../index.module.scss";
import Clap from "../../../../statics/images/clap.svg";
import { classNames } from "../../../../common/utils";
import { FTXClaimContext } from "../../ClaimContextProvider";
import { useContext } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { clientAPI } from "../../../../api/client";
import { CALENDY_LINK_URL } from "../../../../common/constants";

const Submited = () => {
  const {
    claimId,
    resetForm,
    userData,
    messageApi,
    form,
    FTXScheduleData,
    accountBalance,
  } = useContext(FTXClaimContext);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: async (e) => {
      // const res = await clientAPI("post", "/addScheduledInterview", {
      //   claimId: claimId,
      //   eventUri: e.data.payload.event.uri,
      //   inviteeUri: e.data.payload.invitee.uri,
      // });
    },
  });

  const values = form.getFieldsValue(true);

  return (
    <div className={classNames("container", styles.main)}>
      <div className={styles.content}>
        <div className={styles.success}>
          <div className={styles.result}>
            <img src={Clap} alt="Clap" />
            <div className={styles.resultText}>
              Thank you for your submission.
              <br /> Please note down your Claim ID: <strong>{claimId}</strong>
            </div>
            {/* <button
              onClick={() => {
                resetForm();
              }}
              className={"sd-btn"}
            >
              Create Another Claim
            </button>
             */}
            <a
              target="_blank"
              className={"sd-btn"}
              rel="noreferrer"
              href={
                process.env.REACT_APP_OPNX_DASHBOARD_URL ||
                "https://opnx.com/claims/dashboard"
              }
            >
              Go to OPNX Dashboard
            </a>
            {!["Unliquidated", "Disputed"].some((el) =>
                FTXScheduleData?.indicator?.includes(el)
              ) &&
              accountBalance >= 2000 &&
              !!FTXScheduleData &&
              values?.is_soa_details_agreed === "Yes" && (
                <InlineWidget
                  styles={{
                    minWidth: "1000px",
                    height: "700px",
                  }}
                  prefill={{
                    email: userData?.email,
                    name: userData?.legalName,
                    customAnswers: {
                      a1: values?.ftx_email,
                      a2: values?.claim_f_line,
                      a3: claimId,
                    },
                  }}
                  url={CALENDY_LINK_URL}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Submited;
