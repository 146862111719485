import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

// import DefaultLayout from './layouts/DefaultLayout';

import ClaimPage from "./containers/ClaimPage";
import TransferAgreementPage from "./containers/TransferAgreementPage";
import PublicDataPage from "./containers/PublicDataPage";

import NotFoundPage from "./containers/NotFoundPage";
import HomePage from "./containers/HomePage";
import LoginPage from "./containers/LoginPage";
import HomeLayout from "./layouts/HomeLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import MyAccountLayout from "./layouts/MyAccountLayout";
import MyAccount from "./containers/MyAccount";
import Redirect from "./containers/Redirect";
import PublicDataLayout from "./layouts/PublicDataLayout";
import { polygon, polygonMumbai } from "wagmi/chains";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { Web3Modal } from "@web3modal/react";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import Aggrement from "./containers/Aggrement";
import MyClaimInfo from "./containers/MyClaimInfo";
import SVPData from "./containers/SVPDataPage";
import ClaimDetails from "./containers/MyAccount/ClaimDetails";
import FtxQuestionnaire from "./containers/FtxQuestionnaire";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { Modal } from "antd";
import { useReadLocalStorage } from "usehooks-ts";
import { getWithExpiry } from "./common/utils";
import FTXClaimPage from "./containers/FTXClaimPage";
import MyFTXClaims from "./containers/MyFTXClaims";
import TransferAgreementFTXPage from "./containers/TransferAgreementFTXPage";
import MyFTXClaimInfo from "./containers/MyFTXClaimInfo";
import SVPFTXDataPage from "./containers/SVPFTXDataPage";
import PublicFTXDataPage from "./containers/PublicFTXDataPage";

const chains: any = process.env.REACT_APP_CHAIN_ID === '80001' ? [polygonMumbai] : [polygon];

const projectId = "8707a707ecde3582b89374254899260a";

const { provider } = configureChains(chains, [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY || 'vBZxJWpOETR8RRjbpdgMvDeTSxGGnwhv' })]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider,
});

const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  const invalidToken= getWithExpiry('invalidToken')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if(invalidToken) {
      setIsOpen(true)
    }
  }, [invalidToken])  

  const backBtn = () => {
    localStorage.removeItem("invalidToken")
    setIsOpen(false)
    window.open(process.env.REACT_APP_OPNX_DASHBOARD_URL || "https://opnx.com/claims/dashboard");
  }

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<HomePage />} />
          </Route>
          <Route path="/redirect" element={<HomeLayout />}>
            <Route index element={<Redirect />} />
          </Route>
          <Route path="/public-data" element={<PublicDataLayout />}>
            <Route index element={<PublicDataPage />} />
          </Route>
          <Route path="/login" element={<DefaultLayout />}>
            <Route index element={<LoginPage />} />
          </Route>
          <Route path="/celsius" element={<MyAccountLayout />}>
            <Route index element={<ClaimPage />} />
          </Route>
          <Route path="/transfer-agreement/:id" element={<DashboardLayout />}>
            <Route index element={<TransferAgreementPage />} />
          </Route>
          <Route path="/transfer-ftx-agreement/:id" element={<DashboardLayout />}>
            <Route index element={<TransferAgreementFTXPage />} />
          </Route>
          <Route path="/my-account" element={<MyAccountLayout />}>
            <Route index element={<MyAccount />} />
          </Route>
          <Route path="/my-ftx-claims" element={<MyAccountLayout />}>
            <Route index element={<MyFTXClaims />} />
          </Route>
          <Route path="/agreement" element={<DefaultLayout />}>
            <Route index element={<Aggrement />} />
          </Route>
          <Route path="/my-claim-detail/:id" element={<MyAccountLayout />}>
            <Route index element={<MyClaimInfo />} />
          </Route>
          <Route path="/my-ftx-claim-detail/:id" element={<MyAccountLayout />}>
            <Route index element={<MyFTXClaimInfo />} />
          </Route>
          <Route path="/spv-data" element={<DashboardLayout />}>
            <Route index element={<SVPData />} />
          </Route>
          {/* <Route path="/spv-ftx-data" element={<DashboardLayout />}>
            <Route index element={<SVPFTXDataPage />} />
          </Route>
          <Route path="/public-ftx-data" element={<DashboardLayout />}>
            <Route index element={<PublicFTXDataPage />} />
          </Route> */}
          <Route path="/ftx" element={<DashboardLayout />}>
            <Route index element={<FTXClaimPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode="light"
      />
      <Modal
        open={isOpen}
        onCancel={() => {
          localStorage.removeItem("invalidToken")
        setIsOpen(false)}}
        className={'modal-limit'}
        width={"350px"}
        title={<div className="title-txt">You are logged out!</div>}
        footer={null}
        centered
      >
        <div className="txt-desc">
        You have been logged out of your OPNX account.
        </div>
        <div className="txt-desc">
        Please log into your OPNX account  before trying again. 
        </div>

        <div className="flex justify-center">
          <button onClick={backBtn} className={'button'}>
            Go to OPNX Dashboard
          </button>
        </div>
      </Modal>
    </>
  );
}

export default App;



 