import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { classNames, encrypt } from "../../common/utils";
import Step1 from "./Step1";
import Step2 from "./Step2";
import StepResult from "./StepResult";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { clientAPI } from "../../api/client";
import LogoCelsius from "../../statics/images/logo_celsius_icon.svg";
import Step3 from "./Step3";
import { message } from "antd";
import {
  DEFAULT_SCHEDULE,
  ScheduleFLine,
} from "../ClaimPage/ClaimContextProvider";

function TransferAgreementPage() {
  const location = useLocation();
  const params = useParams();

  const queryClaimId = params;

  const [claim, setClaim] = useState<any>();
  const [survey, setSurvey] = useState();
  const [celciusScheduleData, setCelciusScheduleData] =
    useState<ScheduleFLine>(DEFAULT_SCHEDULE);

  const [step, setStep] = useState<number>(1);

  const [signature, setSignature] = useState();
  const [place, setPlace] = useState();
  const [authenDisabled, setAuthenDisabled] = useState();
  const [celsiusPassword, setCelsiusPassword] = useState();
  const [celsiusPinCode, setCelsiusPinCode] = useState('');
  const [messageApi, messageContextHolder]: any = message.useMessage();

  // const submitAggreement = () =>
  const submitAggreement = useCallback(async () => {
    let ret = await clientAPI("post", "/submitAgreement", {
      claim_id: queryClaimId?.id,
      survey: JSON.stringify({
        signed_date: new Date().toString(),
        celsius_email: survey && survey["celsius_email"],
        signature,
        place,
        authen_disabled: [`${authenDisabled}`],
        celsius_password: await encrypt(celsiusPassword || ""),
        celcius_code: celsiusPinCode
      }),
    });
    console.log(ret);
    if (ret.status === "FAILED" || !ret.status) {
      console.log(ret.message);
      message.error('Submit failed')
    } else {
      setStep(4);
    }
  }, [
    authenDisabled,
    celsiusPassword,
    place,
    queryClaimId?.id,
    signature,
    survey,
    celsiusPinCode
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const data = Object.fromEntries(
    Array.from(searchParams)
  );
  const verify = async () => {
    if (!data || !data.accountId || !data.host || !data.nonce || !data.path || !data.sign || !data.timestamp) {
      get_claims(queryClaimId?.id || "")
      return;
    }
    const result = await clientAPI("post", "/verify", {
      accountId: data.accountId,
      host: data.host,
      nonce: data.nonce,
      path: data.path,
      sign: data.sign,
      timestamp: data.timestamp,
      applicationId: data.applicationId,
      claimType: data.claimType,
    });
    if (result.status === "FAILED" || !result.status) {
      console.log("Something wrong with your request");
      messageApi.error("Login failed", 10)
    } else {
      localStorage.setItem("token", result?.message?.token);
      localStorage.setItem("accountId", result?.message?.accountId);
      localStorage.setItem("applicationId", data?.applicationId);
      localStorage.setItem("claimType", data?.claimType);
      get_claims(queryClaimId?.id || "")
    }
  }

  useEffect(() => {
    verify();
  }, [searchParams]);

  const handleComplete = useCallback(() => {
    console.log(authenDisabled);
    console.log(celsiusPassword);
    console.log(signature);
    console.log(place);
    submitAggreement();
  }, [authenDisabled, celsiusPassword, place, signature, submitAggreement]);

  const handlePreview = useCallback(() => {
    setStep(1);
  }, []);

  const get_claims = async (claim_id: string) => {
    try {
      let ret = await clientAPI("post", "/getClaim", {
        claim_id,
      });
      if (ret.status === "FAILED" || !ret.status) {
        console.log("Something wrong with your request");
      } else {
        setClaim(ret.message);
        const surveyObj = JSON.parse(ret.message.survey);
        setSurvey(surveyObj);
        if (surveyObj?.claim_link) {
          const result = await clientAPI(
            "post",
            "/celciusSchedule/" + surveyObj?.claim_link
          );
          if (result.status !== "FAILED") {
            setCelciusScheduleData(result.message);
          }
        }
      }
    } catch (error) {}
  };

  return (
    <div className={classNames("container", styles.main)}>
      {messageContextHolder}
      {step >= 4 ? (
        <StepResult onPreview={handlePreview} />
      ) : (
        <>
          <>
            {[1, 2].includes(step) && (
              <div className={styles.title}>
                <img src={LogoCelsius} width={50} height={36} alt="celsius" />{" "}
                Celsius Transfer
              </div>
            )}
            {step === 1 && (
              <Step1
                messageApi={messageApi}
                onNext={() => setStep(2)}
                claim={claim}
                survey={survey}
                //  @ts-ignore
                claim_id={location?.id || ""}
                setSignature={setSignature}
                place={place}
                setPlace={setPlace}
                celciusScheduleData={celciusScheduleData}
              />
            )}
            {step === 2 && (
              <Step2
                messageApi={messageApi}
                onNext={() => {
                  handleComplete()
                  setStep(4)}}
                onBack={() => setStep(1)}
                authenDisabled={authenDisabled}
                celsiusPassword={celsiusPassword}
                setAuthenDisabled={setAuthenDisabled}
                setCelsiusPassword={setCelsiusPassword}
                setCelsiusPinCode={setCelsiusPinCode}
                celsiusPinCode={celsiusPinCode}
                claim={claim}
                survey={survey}
              />
            )}
          </>
          {/* {step === 3 && (
            <Step3
              onNext={() => setStep(4)}
              onBack={() => setStep(2)}
              onComplete={handleComplete}
              setAuthenDisabled={setAuthenDisabled}
              setCelsiusPassword={setCelsiusPassword}
              claim={claim}
              survey={survey}
            />
          )} */}
        </>
      )}
    </div>
  );
}

TransferAgreementPage.propTypes = {};

export default TransferAgreementPage;
