import React, { FC } from "react";
import styles from "./index.module.scss";
import {Checkbox, Input } from "antd";

type Step2Type = {
  onNext: () => any;
  onBack: () => any;
  claim: any;
  survey: any;
  setCelsiusPassword: any;
  setAuthenDisabled: any;
  authenDisabled: any,
  celsiusPassword: any,
  messageApi: any
  setCelsiusPinCode: any
  celsiusPinCode: any
};

const Step2: FC<Step2Type> = ({
  onNext,
  onBack,
  setAuthenDisabled,
  setCelsiusPassword,
  authenDisabled,
  celsiusPassword,
  messageApi,
  survey,
  setCelsiusPinCode,
  celsiusPinCode
}) => {

  const next = () => {
    if(!(authenDisabled && celsiusPassword && celsiusPinCode)) {
        messageApi.warning('Please fill all data!')
        return
    }
    onNext()
  }

  return (
    <>
      <div className={styles.content}>
        <div className={styles.contentTitle}>Celsius Account</div>

        <div className={styles.contentSubTitle}>
          <span style={{ color: "#666666" }}>
            Thanks for signing the Agreement.We’re almost there.<br/>
            Please sign into your Celsius account and disable 2-factor authentication.
          </span>
          <div className={styles.formItem}>
            <div className={styles.formLabel}>
              Your Celsius Email:{" "}
              <span className={styles.formLabelRequired}>*</span>
            </div>
            <div className={styles.formValue}>
              <Input
                className={styles.formInput}
                name="input"
                value={survey?.celsius_email}
                disabled
              />
            </div>
          </div>
        </div>

        <div className={styles.contentSubTitle}>
          {/* <span>Share your password below.</span> */}
          <div className={styles.formItem}>
            <div className={styles.formLabel}>
              Please enter your Celsius Password:
              <span className={styles.formLabelRequired}>*</span>
            </div>
            <div className={styles.formSubTitle}>
              Please do not change your password after this
            </div>
            <div className={styles.formValue}>
              <Input
                className={styles.formInput}
                name="input"
                // placeholder="Your password"
                onChange={(e) => {
                  setCelsiusPassword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.contentSubTitle} style={{ marginTop: "35px" }}>
          <div className={styles.formItem} style={{ marginTop: "10px",display:'flex',flexDirection:'row',gap:'4rem' }}>
            <div>
              <div className={styles.formLabel}>
                Disable any 2FA in your Celsius Account
                <span className={styles.formLabelRequired}>*</span>
              </div>
              <div className={styles.formSubTitle}>
                Please do not enable 2FA again
              </div>
            </div>

          </div>
          <div className={styles.formValue} style={{ marginTop: "2px" }}>
            <Checkbox onChange={(e) => setAuthenDisabled(e.target.checked)}>
              Yes
            </Checkbox>
          </div>
        </div>
         {authenDisabled && <div className={styles.contentSubTitle}>
          {/* <span>Share your password below.</span> */}
          <div className={styles.formItem}>
            <div className={styles.formLabel}>
              Celsius PIN/Code
              <span className={styles.formLabelRequired}>*</span>
            </div>
            <div className={styles.formValue}>
              <Input
                className={styles.formInput}
                name="input"
                value={celsiusPinCode}
                // placeholder="Your password"
                onChange={(e) => {
                  setCelsiusPinCode(e.target.value);
                }}
              />
            </div>
          </div>
        </div>}
      </div>
      <div className={styles.buttons}>
        <button onClick={onBack} className={'sd-btn'}>
          Back
        </button>
        <button onClick={next} className={'sd-btn'}>
          Next
        </button>
      </div>
    </>
  );
};

export default Step2;
