import React, { FC } from 'react';
import styles from './index.module.scss';
import ClapImg from '../../statics/images/clap.svg';
import { Button } from 'antd';

type StepResultType = {
  onPreview: () => any;
}

const StepResult: FC<StepResultType> = ({ onPreview }) => {
  return (
    <div className={styles.result}>
      <img src={ClapImg} alt="Clap" />
      <div className={styles.resultText}>
      Account transfer has been initiated!<br />
      Stay updated on the status of your submission.
      </div>
      <a
              target="_blank"
              className={"sd-btn"}
              rel="noreferrer"
              href={
                process.env.REACT_APP_OPNX_DASHBOARD_URL ||
                "https://opnx.com/claims/dashboard"
              }
            >
              Go to OPNX Dashboard
            </a>
      {/* <div className={styles.buttons}>
        <Button onClick={onPreview} className={styles.submit}>Preview survey again</Button>
      </div> */}
    </div>
  )
}

export default StepResult
