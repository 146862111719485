import styles from "./index.module.scss";
import { TbCopy } from "react-icons/tb";

import ClaimInfoModal from "./ClaimInfo";
import { useState, useCallback, useEffect } from "react";
import ClaimAmountModal from "./ClaimAmount";
import { Typography } from "antd";
import { survey_obj, truncDigits } from "../../../common/utils";
import { get_status } from "../../PublicDataPage/Table/TableRow";
import { useProvider } from "wagmi";
import { BcTokenFactory } from "../../../typechain/BcTokenFactory";
import { Link } from "react-router-dom";
import { PopupModal } from "react-calendly";
import { isNumber } from "util";
import ReactDOM from "react-dom";
interface IProps {
  claim: any;
  prices: any;
}

function TableClaimMobile({ claim, prices }: IProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showClaimAmount, setShowClaimAmount] = useState(false);
  const [isClaimIdExist, setIsClaimIdExist] = useState(false);
  const [isClaendlyOpen, setISClaendlyOpen] = useState(false);
  const provider = useProvider();
  const checkClaimExist = useCallback(async () => {
    if (!claim || !claim.claim_id || claim.claim_id === "") {
      return;
    }
    const result = await BcTokenFactory.connect(
      process.env.REACT_APP_FTX_COMMON_ADDRESS!,
      provider
    ).get_id_by_claim_id(claim.claim_id);

    console.log(
      claim.claim_id,
      result,
      result.toNumber(),
      claim.admin_status,
      claim.submit_status
    );
    // console.log(claim.claim_id, result, result.toNumber());
    if (result.toNumber() === 0 || !result) {
      setIsClaimIdExist(false);
      return;
    }
    setIsClaimIdExist(true);
  }, [claim, provider]);

  useEffect(() => {
    setIsClaimIdExist(false);
    checkClaimExist();
  }, [checkClaimExist]);
  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleShowClaimAmount = () => {
    setShowClaimAmount(!showClaimAmount);
  };
  const surveyData = survey_obj(claim?.survey);

  function preference(survey: any) {
    return (
      <>
        {parseFloat(survey.preferenceRisk) <= 0 ? (
          <>
            <span>Not Flagged</span>
          </>
        ) : parseFloat(survey.preferenceRisk) > 0 ? (
          <>
            <div className={styles.subTableCell}>
              <div className={styles.info}>
                <span style={{ alignSelf: "center" }}>
                  Estimated: {truncDigits(parseFloat(survey.preferenceRisk), 2)}{" "}
                  USD
                </span>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      <ClaimInfoModal
        claim={claim}
        prices={prices}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
      />
      <ClaimAmountModal
        handleCancel={handleShowClaimAmount}
        isModalOpen={showClaimAmount}
        claim={claim}
        prices={prices}
      />
      <div className={styles.main}>
        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-medium text-sm">Bankruptcy</span>
          <span className="font-semibold text-sm">FTX Ch.11...of Delaware</span>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-medium text-sm">Claim ID</span>
          <div className="flex gap-1">
            <span className="font-normal text-sm">{claim.claim_id}</span>
            <Typography.Text
              copyable={{
                text: claim.claim_id,
                icon: <TbCopy className="self-center text-lg text-[#03ABAB]" />,
              }}
            ></Typography.Text>
          </div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <span className="text-[#666666] font-normal text-sm">
              Claim Amount
            </span>
            <span
              onClick={() => setShowClaimAmount(true)}
              className="text-[#03ABAB] font-normal text-sm border-b"
            >
              More Info
            </span>
          </div>

          <span className="font-medium text-[#111111] text-sm">
            {claim.survey
              ? isNumber(surveyData?.ftx_account_balance)
                ? "$" + truncDigits(surveyData?.ftx_account_balance, 2)
                : 0
              : "N/A"}
          </span>
        </div>
        <div className="border border-[#EDEDED] my-3" />
        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Customer Code
          </span>
          <span className="">
            {survey_obj(claim?.survey)?.claim_f_line || "N/A"}
          </span>
        </div>

        <div className="border border-[#EDEDED] my-3" />
        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Schedule F Line
          </span>
          <div className="flex gap-1">
            {survey_obj(claim?.survey)?.claim_link || "N/A"}
          </div>
        </div>
        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">Claim No</span>
          <span className="">
            {survey_obj(claim?.survey)?.poc_file_number || "N/A"}
          </span>
        </div>

        <div className="border border-[#EDEDED] my-3" />

        <div className="flex flex-col gap-2">
          <span className="text-[#666666] font-normal text-sm">
            Claim Status
          </span>
          <span className="">
            {get_status(
              claim,
              claim.status,
              claim.submit_status,
              claim?.token_minted
            )}
          </span>
        </div>
        <div className="mt-3">
          {claim.admin_status === "Approved" &&
          claim.submit_status === "Not Submitted" &&
          !!isClaimIdExist ? (
            <div className="flex gap-3 flex-col">
              <span className="text-[#666666] font-normal text-sm">
                Required Action
              </span>
              <Link
                to={"/transfer-ftx-agreement/" + claim.claim_id}
                state={{ id: claim.claim_id }}
              >
                <span
                  className={styles.action_text}
                  style={{
                    color: "#037c7c",
                  }}
                >
                  Initiate Transfer {">"}
                </span>
              </Link>
            </div>
          ) : null}
          {surveyData?.level3_verification_call_check !== "Approved" && claim?.status !== "Rejected" ? (
              <div>
                <span
                  className={styles.action_text}
                  onClick={() => setISClaendlyOpen(true)}
                >
                  Schedule Call
                </span>
              </div>
          ) : (
            <></>
          )}
        </div>

        <div
          onClick={() => setIsModalOpen(true)}
          className="border border-[#E2E2E2] text-center py-3 rounded-md cursor-pointer mt-5"
        >
          <span className="font-semibold text-[#111111] text-sm">
            Claim Information
          </span>
        </div>
      </div>

      {/* <PopupModal
        url={CALENDY_LINK_URL}
        styles={{
          minWidth: "1000px",
          height: "700px",
        }}
        prefill={{
          email: surveyData?.email,
          name: surveyData?.legal_name,
          customAnswers: {
            a1: surveyData?.ftx_email,
            a2: surveyData?.claim_f_line,
            a3: claim?.claim_id,
          },
        }}
        onModalClose={() => setISClaendlyOpen(false)}
        open={isClaendlyOpen}
        
        rootElement={ReactDOM.createRoot(document.getElementById('root')!)}
      /> */}
    </>
  );
}
TableClaimMobile.propTypes = {};

export default TableClaimMobile;
