import styles from "./index.module.scss";
import ClaimTable from "./ClaimTable";
import { Input } from "antd";
import { BiSearch } from "react-icons/bi";
import { useState, useEffect } from "react";
import ClaimDetails from "./ClaimDetails";
import { clientAPI } from "../../api/client";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";
import { useNavigate } from "react-router";
import TableClaimMobile from "./MobileView";
import Disclaimer from "../Aggrement/Disclaimer";
import { useSwitchNetwork } from "wagmi";
import { CLAIM_TYPE_FTX } from "../../common/constants";
import { useCalendlyEventListener } from "react-calendly";

const CLAIM_TYPE = CLAIM_TYPE_FTX

function MyFTXClaims() {
  const [claims, setClaims] = useState([]);
  const [prices, setPrices] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState<number>(5);
  const [claimsCount, setClaimCount] = useState(0); 
  const navigate = useNavigate();
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const cancelDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const { switchNetwork, isSuccess } = useSwitchNetwork()

  useEffect(() => {
    switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID)|| 80001)
  }, [switchNetwork])

  useEffect(() => {
    if(isSuccess) {
      window.location.reload()
    }
  
  }, [isSuccess])

  const getPrices = async () => {
    try {
      const result = await fetch("https://claims.heimdallrwa.com/ftx-coin-prices.json").then(
        (response) => response.json()
      );
      //  @ts-ignore
      if (Array.isArray(result)) setPrices(result);
    } catch (error) {
      //
    }
  };

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const get_claims = async (page: number) => {
    const accountId = localStorage.getItem("accountId");
    let ret = await clientAPI("post", "/claimsFtx", {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      contain_rejected: 1,
      filter: JSON.stringify({  
        claim_type: CLAIM_TYPE,
        "opnx_data.accountId": accountId,
      }),
    });
    console.log(ret);
    if (ret.status === "FAILED" || !ret.status) {
      console.log("Something wrong with your request");
    } else {
      if(!ret.message || ret.message.length === 0) {
        setShowDisclaimer(true)
      }
      setClaims(ret.message);
      setClaimCount(ret.total);
    }
  };

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      await sleep(1000)
      get_claims(currentPage)
    },
  });

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    //console.log('Page: ', pageNumber);
    get_claims(pageNumber);
    setCurrentPage(pageNumber);
  };

  const search = async (keywords: string) => {
    console.log(keywords);
    let ret = await clientAPI("post", "/ftxClaims", {
      limit: 10,
      offset: 0,
      contain_rejected: 0,
      keywords,
    });
    console.log(ret);
    if (!ret.message) {
      console.log("Something wrong with your request");
    } else {
      setClaims(ret.message);
    }
  };

  useEffect(() => {
    get_claims(1);
  }, []);

  useEffect(() => {
    // get_claims(1);
    // get_claims_count();
    getPrices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

 
  return (
    <div className={styles.main}>
      <Disclaimer
        isModalOpen={showDisclaimer}
        cancelDisclaimer={cancelDisclaimer}
      />
      <div className={styles.header}>My Account</div>
      {/* <Input
        suffix={<BiSearch />}
        placeholder="Search for claim via ID here..."
        size="large"
        className={styles.search}
      /> */}

      <div className={styles.mobile_view_table}>
        <div className="flex flex-col gap-5 mobile-view-table">
          {claims.map((claim, index) => (
            <TableClaimMobile claim={claim} prices={prices} />
          ))}
        </div>
      </div>
      <div className={styles.table}>
        <ClaimTable
          setSelectedRow={(claim: any) =>
            navigate(`/my-ftx-claim-detail/${claim?.claim_id}`)
          }
          claims={claims}
          prices={prices}
        />

        <div className={styles.pagination}>
          <label>
            Show {claimsCount > 0 ? (currentPage - 1) * pageSize + 1 : 0} to{" "}
            {(currentPage - 1) * pageSize + claims.length} of {claimsCount}{" "}
            entries
          </label>
          <Pagination
            defaultCurrent={currentPage}
            total={claimsCount}
            defaultPageSize={pageSize}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

MyFTXClaims.propTypes = {};

export default MyFTXClaims;
