import { Button, Collapse } from "antd";
import AccountBalance from "./AccountBalance";
import ClaimaintInformation from "./ClaimaintInformation";
import ClaimInformation from "./ClaimInformation";
import styles from "../index.module.scss";
import { useCallback, useContext } from "react";
import { ClaimContext } from "../../ClaimContextProvider";
import ProofOfClaim from "./ProofOfClaim";

const Step5 = () => {
  const { handleBack, handleSubmit, formData } = useContext(ClaimContext);

  const handleBackStep = useCallback(() => {
    handleBack();
  }, [handleBack]);

  const panelList = [
    {
      key: "claimant-information",
      label: "Claimant Personal Information",
      content: ClaimaintInformation,
    },
    {
      key: "claim-information",
      label: "Claim General Information",
      content: ClaimInformation,
    },
    {
      key: "account-balance",
      label: "Accounts, Balances and Transactions",
      content: AccountBalance,
    },
    ...(formData?.is_soa_available === "No" ||
    (formData?.is_soa_available === "Yes" &&
      formData?.is_soa_amount_agreed === "No")
      ? [
          {
            key: "proof-of-claim",
            label: "Proof of Claim",
            content: ProofOfClaim,
          },
        ]
      : []),
  ];

  const openPanel = panelList.map((panel) => panel.key);

  return (
    <>
      <Collapse
        className={styles.panel_collapse}
        defaultActiveKey={openPanel}
        expandIcon={() => null}
      >
        {panelList.map((panel) => (
          <Collapse.Panel header={panel.label} key={panel.key}>
            <div className="p-5">{panel.content({})}</div>
          </Collapse.Panel>
        ))}
      </Collapse>
      <div className={styles.navigation}>
        <Button onClick={handleBackStep}>Back</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </>
  );
};
export default Step5;
