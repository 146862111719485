import React from 'react'
import { Typography } from 'antd';

import IconTwitter from '../../../statics/images/icon-twitter.svg';
import { classNames } from "../../../common/utils";

import styles from './index.module.scss';

function Footer() {
  return (
    // <div className={classNames('container', styles.footer)}>
    //   <a className={styles.row} href="https://">
    //     <img src={IconTwitter} width={23} height={23} alt="twitter" />
    //     <Typography.Text strong>Connect on Twitter</Typography.Text>
    //   </a>
    //   <p>
    //   </p>
    // </div>
    <div className={classNames('container', styles.footer)}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
        <a className={styles.row} href="https://twitter.com/heimdallrwa" style={{ marginRight: 5 }}>
          <img src={IconTwitter} width={23} height={23} alt="twitter" />
        </a>
        <Typography.Text type="secondary">Copyright © 2023 by Heimdall Operations Limited</Typography.Text>
      </div>
    </div >
  )
}

Footer.propTypes = {}

export default Footer
