import React from "react";
import { Tooltip } from "antd";
import { BsInfoCircle } from "react-icons/bs";
import styles from "./TableHead.module.scss";

function TableHead() {
  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.cell7}></div> */}

      <div className={styles.cell1}>Bankruptcy Case</div>
      <div className={styles.cell2}>Claim ID</div>
      <div className={styles.cell3}>Claim Amount</div>
      {/* <div className={styles.cell4}>Claim Status</div> */}
      <div className={styles.cell4}>
        <div className="flex gap-1">
          <div>Preference Risk</div>
          <Tooltip
                placement="topLeft"
                title={
                  "Preference Risk is calculated at Account Level considering New Value Defense using Withdrawal & Deposit transactions within the 90 day window between April 14, 2022 & July 12, 2022, inclusive of both dates, counting from the first Withdrawal transaction"
                }
              >
                <div className="self-center">
                  <BsInfoCircle style={{ cursor: "pointer" }} />
                </div>
              </Tooltip>
        </div>
      </div>
      <div className={styles.cell5}>Schedule F Line</div>
      <div className={styles.cell6}>Claim No.</div>
     
    </div>
  );
}

TableHead.propTypes = {};

export default TableHead;
