import { Button, Form, Input, Select, Typography } from "antd";
import { useCallback, useContext, useEffect } from "react";
import USER_ICON from "../../../statics/images/user.svg";
import FILE_ICON from "../../../statics/images/fi_file-text.svg";
import PHONE_ICON from "../../../statics/images/fi_phone.svg";
import { ClaimContext } from "../ClaimContextProvider";
import styles from "./index.module.scss";
import clsx from "clsx";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { step_1 } from "./schema";

const Step1 = () => {
  const {
    userData,
    countries,
    handleNext,
    handleBack,
    useYupValidationResolver,
    formData,
    handleUpdateForm,
  } = useContext(ClaimContext);

  type FormData = yup.InferType<typeof step_1>;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: useYupValidationResolver(step_1),
    defaultValues: {
      claim_holder: formData.claim_holder,
    },
  });

  const values = useWatch({ control });

  const handleNextStep = useCallback(() => {
    handleUpdateForm(values);
    handleNext();
  }, [handleNext, handleUpdateForm, values]);

  return (
    <Form onFinish={handleSubmit(handleNextStep)} layout="vertical">
      <div className={clsx(styles.panel_container)}>
        <Typography.Text className="font-semibold text-black text-2xl">
          Personal Details
        </Typography.Text>
        <div className={styles["detail-information"]}>
          {userData && (
            <div className={styles["personal_info"]}>
              <div className="flex items-center gap-x-1">
                <p className="m-0">
                  Hi <span>{userData.legalName}</span>, welcome.
                </p>
              </div>
            </div>
          )}
        </div>

        <Form.Item label="Are you the original claim holder or did you buy this claim from someone?" required>
          <Controller
            name="claim_holder"
            control={control}
            render={({ field }) => (
              <Select
                status={errors.claim_holder && "error"}
                options={[
                  { label: "Original Claim Owner", value: "Original Claim Owner" },
                  { label: "Bought This Claim", value: "Bought This Claim" },
                ]}
                placeholder="Select"
                {...field}
              />
            )}
          />
          <Typography.Text type="danger">
            {errors.claim_holder?.message?.toString()}
          </Typography.Text>
        </Form.Item>
      </div>
      <div className={styles.navigation}>
        <Button htmlType="submit">Next</Button>
      </div>
    </Form>
  );
};
export default Step1;
