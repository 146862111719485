import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Link } from 'react-router-dom';

import styles from './index.module.scss';
import LogoOPNX from '../../statics/images/logo-opnx.svg';

function LoginPage() {
  return (
    <div className={styles.main}>
      <Link to="/claim">
        <Button size='large' className={styles.btnLogin}>
          Sign in with
          <img src={LogoOPNX} alt="sign in with OPNX" width={50} height={18} />
        </Button>
      </Link>
    </div>
  )
}

LoginPage.propTypes = {}

export default LoginPage
