import { Button, Form, Space, Steps, Typography } from "antd";
import clsx from "clsx";
import { useContext } from "react";
import ClaimContextProvider, { FTXClaimContext } from "./ClaimContextProvider";
import styles from "./index.module.scss";
import Clap from "../../statics/images/clap.svg";
import FTXLogo from "../../statics/images/ftx_logo.svg";
import { classNames } from "../../common/utils";
import Submited from "./components/Submited";

const ClaimPageLayout = () => {
  const {
    submitted,
    currentStep,
    progress,
    messageContextHolder,
    claimId,
    resetForm,
    form,
  } = useContext(FTXClaimContext);

  if (submitted && !!claimId) {
    return (
      <Submited/>
    );
  }

  const validateMessages = {
    required: "Please input the data!",
  };

  return (
    <div className={styles.content}>
      {messageContextHolder}
      <Space
        direction="vertical"
        align="center"
        size={12}
        className={styles.pageHeader}
      >
        <img src={FTXLogo} height={35} alt="celsius" className="self-center" />

        <Typography.Title
          level={2}
          className={styles.title}
          style={{ marginTop: "5px" }}
        >
          <div className="flex gap-2">
            <span className={styles.title_text}>FTX Claims Questionnaire</span>
          </div>
        </Typography.Title>
        <Typography.Text type="secondary" className={styles.description}>
          Please complete the questionnaire by supplying the correct information{" "}
          <br />
          about your FTX Bankruptcy Claim.
        </Typography.Text>
      </Space>

      <div className={clsx(styles.steps)}>
        <Steps
          current={currentStep}
          labelPlacement="vertical"
          items={progress}
          size={"small"}
          responsive={false}
        />
      </div>
      <Form form={form} layout="vertical" validateMessages={validateMessages}>
        <div className={styles.card}>{progress[currentStep]?.content}</div>
      </Form>
    </div>
  );
};

const FTXClaimPage = () => {
  return (
    <ClaimContextProvider>
      <ClaimPageLayout />
    </ClaimContextProvider>
  );
};

export default FTXClaimPage;
