import React, { useState, useEffect } from 'react';
import { Space, Button, Typography, Tag, Tooltip } from 'antd';

import { classNames, survey_obj, truncDigits } from '../../../common/utils';
import TagOutline from '../../../components/Tag/TagOutline';
import { AiFillCloseCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { HiCheckCircle } from 'react-icons/hi';
import IconChevronDown from '../../../statics/images/icon-chevron-down.svg';
import { Link } from 'react-router-dom';
import styles from './TableRow.module.scss';
import { BsInfoCircle } from 'react-icons/bs';
import clsx from 'clsx';
import { TbCopy } from 'react-icons/tb';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { IoIosArrowDown } from 'react-icons/io';
import { RxCross2, RxDotFilled } from 'react-icons/rx';
import CryptoIcon from '../../../components/CryptoIcon';

export const get_status = (
  props: any,
  status: string,
  submit_status: string,
  token_minted = 0
) => {
  // console.log(status);
  // console.log(submit_status);
  if (token_minted) {
    return (
      <Tag color="#E3FAE5" className="rounded-[36px]">
        <span style={{ color: '#31B43E' }}>Complete</span>
      </Tag>
    );
  }
  if (status === 'Rejected') {
    return (
      <Tag color="#F04438" className="rounded-[36px]">
        <span style={{ color: '#fff' }}>Rejected</span>
      </Tag>
    );
  }
  if (submit_status === 'Rejected' || submit_status === '' || !submit_status) {
    return (
      <Tag color="#EAE9FF" className="rounded-[36px]">
        <span style={{ color: '#6558FF' }}>Under Review</span>
      </Tag>
    );
  } else if (submit_status === 'Submitted') {
    return (
      <Tag color="#E3FAE5" className="rounded-[36px]">
        <span style={{ color: '#31B43E' }}>Transfer in Progress</span>
      </Tag>
    );
  }
  return (
    <Tag color="#EAE9FF" className="rounded-[36px]">
      <span style={{ color: '#6558FF' }}>Under Review</span>
    </Tag>
  );
};

interface ClaimLayout {
  claim: any;
  index: number;
  prices: any[];
  currentPage: number;
}

function TableRow(props: ClaimLayout) {
  // useEffect(() => {
  //   console.log(props.claim);
  // }, [props]);
  const { prices } = props;
  console.log("here =====");

  const getIcon = (value: any) => {
    const matchedCoin =
      prices &&
      (prices
        .find((item: any) => `${item.price}` === `${value}`)
        ?.coin?.toLowerCase() ||
        'btc');
    return matchedCoin;
  };

  const [isOpenClaimInfo, setIsOpenClaimInfo] = useState(false);
  const [isOpenClaimAmount, setIsOpenClaimAmount] = useState(false);

  useEffect(() => {
    setIsOpenClaimAmount(false);
    setIsOpenClaimInfo(false);
  }, [props.currentPage]);

  function claimClass(admin_status: any, survey: any) {
    return (
      <>
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="20" height="20" rx="10" fill="#31B43E" />
              <path
                d="M14 7L8.5 12.5L6 10"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            General Unsecured
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="20" height="20" rx="10" fill="#31B43E" />
              <path
                d="M14 7L8.5 12.5L6 10"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Non-Priority
          </div>
          {/* <br />
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F04438" />
                <path
                  d="M13 7L7 13M7 7L13 13"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <strike>Priority</strike>
            </div>
            <br /> */}
          {/* <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F04438" />
                <path
                  d="M13 7L7 13M7 7L13 13"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <strike>Offset</strike>
            </div>
            <br /> */}
        </>

        {survey.claim_schedule_status === 'YES' ? (
          <>
            {/* <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#31B43E" />
                <path
                  d="M14 7L8.5 12.5L6 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Disputed
            </div>
            <br /> */}
            {/* <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#31B43E" />
                <path
                  d="M14 7L8.5 12.5L6 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Contingent
            </div> */}
            {/* <br />
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#31B43E" />
                <path
                  d="M14 7L8.5 12.5L6 10"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Unliquidated
            </div>
            <br /> */}
          </>
        ) : (
          <>
            {/* <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F04438" />
                <path
                  d="M13 7L7 13M7 7L13 13"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <strike>Disputed</strike>
            </div>
            <br />
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F04438" />
                <path
                  d="M13 7L7 13M7 7L13 13"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <strike>Contingent</strike>
            </div>
            <br />
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F04438" />
                <path
                  d="M13 7L7 13M7 7L13 13"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <strike>Unliquidated</strike>
            </div> */}
            <br />
          </>
        )}
      </>
    );
  }
  function preference(survey: any) {
    return (
      <>
        {parseFloat(survey.preferenceRisk) <= 0 ? (
          <>
            <span>Not Flagged</span>
          </>
        ) : parseFloat(survey.preferenceRisk) > 0 ? (
          <>
            <div className={styles.subTableCell}>
              <div className={styles.info}>
                <span style={{ alignSelf: 'center' }}>
                  Estimated: {truncDigits(parseFloat(survey.preferenceRisk), 2)}{' '}
                  USD
                </span>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
  return (
    <div className={styles.wrapper} key={props.index}>
      <div
        className={clsx(
          styles.row,
          (isOpenClaimInfo || isOpenClaimAmount) && styles.section_selected
        )}
      >
        {/* <div className={styles.cell7}>
          <Button size="small">Claim Information</Button>
        </div> */}

        <div className={styles.cell1}>
          <label>Bankruptcy Case</label>
          <span>
            {props.claim.claim_type === 'celsius'
              ? 'Celsius Ch.11...of NY'
              : 'Celsius Ch.11...of NY'}
          </span>
        </div>
        <div className={styles.cell2}>
          <label>Claim ID</label>
          {props.claim.claim_id}
          <Typography.Text
            copyable={{
              text: props.claim.claim_id,
              icon: (
                <TbCopy
                  className={clsx('text-[#13A2A2] text-lg self-center', {
                    '!text-white': isOpenClaimInfo || isOpenClaimAmount,
                  })}
                />
              ),
            }}
          ></Typography.Text>
        </div>
        <div className={styles.cell3}>
          <label>
            Claim amount
            <a href="home">More info</a>
          </label>
          <Space>
            <span>
              {props.claim.survey
                ? '$' +
                  truncDigits(survey_obj(props.claim.survey).totalClaim, 2)
                : 'N/A'}
            </span>
            <IoIosArrowDown
              onClick={() => setIsOpenClaimAmount(!isOpenClaimAmount)}
              className={clsx('text-xl text-[#03ABAB] cursor-pointer', {
                'text-white': isOpenClaimAmount || isOpenClaimInfo,
              })}
              style={{
                transform: `rotate(${isOpenClaimAmount ? '180' : '0'}deg)`,
              }}
            />
          </Space>
        </div>

        <div className={styles.cell4}>
          <label>Preference Risk</label>
          {preference(survey_obj(props.claim.survey))}
        </div>
        <div className={styles.cell5}>
          <label>Schedule F Line</label>
          {survey_obj(props?.claim?.survey)?.claim_link || 'N/A'}
        </div>
        <div className={styles.cell6}>
          <label>Claim No</label>
          {survey_obj(props?.claim?.survey)?.poc_file_number || 'N/A'}
        </div>
        {/* <div className={styles.cell4}>
          <label>Claim Status</label>
          {get_status(props.claim, props.claim.status, props.claim.submit_status)}
        </div> */}

        {/* <div className={styles.cell6}>
          <label>Agreement</label>
          {props.claim.admin_status === "Approved" &&
          props.claim.submit_status === "Not Submitted" ? (
            <Link
              to={"/transfer-agreement/" + props.claim.claim_id}
              state={{ id: props.claim.claim_id }}
            >
              <Button size="small">Submit</Button>
            </Link>
          ) : null}
        </div> */}

        <div>
          <IoIosArrowDropdownCircle
            onClick={() => setIsOpenClaimInfo(!isOpenClaimInfo)}
            className={clsx('text-2xl mr-2 text-[#03ABAB] cursor-pointer', {
              'text-white': isOpenClaimInfo || isOpenClaimAmount,
            })}
            style={{
              transform: `rotate(${isOpenClaimInfo ? '180' : '0'}deg)`,
            }}
          />
        </div>
        {/* <div className={styles.cell5}>
          <label>Tokenization TX</label>
          <img
            src={IconChevronDown}
            width={24}
            style={{
              transform: `rotate(${isOpenClaimInfo ? "180" : "0"}deg)`,
              cursor: "pointer",
              marginRight: "1rem",
            }}
            onClick={() => setIsOpenClaimInfo(!isOpenClaimInfo)}
            alt="icon_image"
          />
        </div> */}
      </div>

      <div
        className={styles.claimInfo}
        style={{
          ...(isOpenClaimAmount ? { display: 'block' } : { display: 'none' }),
        }}
      >
        <RxCross2
          onClick={() => setIsOpenClaimAmount(false)}
          className="absolute top-4 right-2 text-xl cursor-pointer"
        />
        <div className="flex !w-full">
          <label className={styles.heading} style={{ width: '20%' }}>
            Claim Amount
          </label>

          <div
            className={clsx(styles.subTable, styles.amountTable)}
            style={{ width: '80%' }}
          >
            <div className={clsx(styles.subTableHeader, '!border-none')}>
              Token
            </div>
            <div className={clsx(styles.subTableHeader, '!border-none')}>
              Quantity
            </div>
            <div className={clsx(styles.subTableHeader, '!border-none')}>
              Token Price as of July 13, 2022
            </div>
            <div className={clsx(styles.subTableHeader, '!border-none')}>
              Claim Amount
            </div>
            <div></div>
            {survey_obj(props.claim.survey).celsius_balances &&
              survey_obj(props.claim.survey).celsius_balances.map(
                (claimAmount: any, index: any) => {
                  const coinName = getIcon(claimAmount.price);
                  console.log(
                    '🚀 ~ file: TableRow.tsx:480 ~ TableRow ~ coinName:',
                    coinName
                  );
                  return (
                    <>
                      <div
                        className={clsx(
                          styles.subTableCell,
                          '!border-b border-[#ededed]'
                        )}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <CryptoIcon name={coinName} size={20} />
                          {coinName.toUpperCase()}
                        </div>
                      </div>
                      <div
                        className={clsx(
                          styles.subTableCell,
                          '!border-b border-[#ededed]'
                        )}
                      >
                        {claimAmount.Quantity}
                      </div>
                      <div
                        className={clsx(
                          styles.subTableCell,
                          '!border-b border-[#ededed]'
                        )}
                      >
                        ${claimAmount.Coin} per {coinName.toUpperCase()}
                      </div>
                      <div
                        className={clsx(
                          styles.subTableCell,
                          '!border-b border-[#ededed]'
                        )}
                      >
                        $
                        {truncDigits(
                          claimAmount.Coin * claimAmount.Quantity,
                          2
                        )}
                      </div>
                      <div></div>
                    </>
                  );
                }
              )}
          </div>
        </div>
      </div>

      <div
        className={styles.claimInfo}
        style={{
          ...(isOpenClaimInfo ? { display: 'block' } : { display: 'none' }),
        }}
      >
        <>
          <RxCross2
            onClick={() => setIsOpenClaimInfo(false)}
            className="absolute top-5 right-2 text-xl cursor-pointer"
          />
          <div className="flex !w-full">
            <label className={styles.heading} style={{ width: '20%' }}>
              Claim Information
            </label>
            <div className={styles.subTable} style={{ width: '80%' }}>
              {/* <div className={styles.subTableHeader}>
              Claim Details matches with Admin Schedule
            </div> */}
              <div className={styles.subTableHeader}>Claim Classification</div>
              <div className={styles.subTableHeader}>Claim Type</div>

              <div className={clsx(styles.subTableHeader, '!flex gap-1')}>
                <div>CUD status</div>
                <Tooltip
                  placement="topLeft"
                  title={
                    'This is to denote if the claim is Contingent, Unliquidated and/or Disputed'
                  }
                >
                  <div>
                    <BsInfoCircle className="cursor-pointer !text-[#13A1A1] mt-1 self-center" />
                  </div>
                </Tooltip>
              </div>

              <div className={styles.subTableHeader}>Offset status</div>
              {/* <div className={styles.subTableHeader}>Preference Risk</div> */}
              {/* <div className={styles.subTableHeader}>
                Relationship with Celsius
              </div> */}
              <div className={styles.subTableHeader}>Location</div>
              {/* <div className={styles.subTableHeader}>KYC Status</div> */}
              <div className={clsx(styles.subTableHeader, '!flex gap-1')}>
                <div>Proof of Ownership</div>
                <Tooltip
                  placement="topLeft"
                  title={
                    'Use this Claim Number to look up the publicly shared Notice of Transfer establishing SPV ownership of given claim'
                  }
                >
                  <div>
                    <BsInfoCircle className="cursor-pointer !text-[#13A1A1] mt-1 self-center" />
                  </div>
                </Tooltip>
              </div>
              <div className={styles.subTableHeader}>Tokens Issued</div>

              {/* <div className={styles.subTableCell}>
              {props.claim.claim_details_matched === "Yes" ? (
                <TagOutline color="#31B43E">● YES</TagOutline>
              ) : props.claim.claim_details_matched === "No" ? (
                <TagOutline color="#CF352A">● NO</TagOutline>
              ) : (
                "N/A"
              )}
            </div> */}

              <div className={styles.subTableCell}>
                {claimClass(
                  props.claim.admin_status,
                  survey_obj(props.claim.survey)
                )}
              </div>
              {/* claim type */}
              <div className={styles.subTableCell}>Earn</div>

              <div className={styles.subTableCell}>
                {/* <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="10" fill="#F04438" />
                    <path
                      d="M13 7L7 13M7 7L13 13"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>CUD</span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      "This denotes if a claim is Contingent, Unliquidated and/or Disputed"
                    }
                  >
                    <div>
                      <BsInfoCircle style={{ cursor: "pointer" }} />
                    </div>
                  </Tooltip>
                </div>
                <br />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="10" fill="#F04438" />
                    <path
                      d="M13 7L7 13M7 7L13 13"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>Offset</span>
                </div> */}
                <div className="flex gap-1 px-2 py-1 text-[#CF352A] border-[#E2E2E2] border rounded-[36px] w-fit">
                  <RxDotFilled className="self-center" />
                  <div className="text-xs font-semibold self-center">No</div>
                </div>
              </div>

              <div className={styles.subTableCell}>
                <div className="flex gap-1 px-2 py-1 text-[#CF352A] border-[#E2E2E2] border rounded-[36px] w-fit">
                  <RxDotFilled className="self-center" />
                  <div className="text-xs font-semibold self-center">No</div>
                </div>
              </div>

              {/* <div className={styles.subTableCell}>
                {preference(survey_obj(props.claim.survey))}
                
              </div> */}
              {/* <div className={styles.subTableCell}>
                {survey_obj(props.claim.survey).celsius_relationship
                  ? survey_obj(props.claim.survey).celsius_relationship.map(
                      (relationship: any) => (
                        <>
                          {relationship === "Depositor" ? (
                            <TagOutline color="#D63696">Depositor</TagOutline>
                          ) : relationship === "Vendor" ? (
                            <TagOutline color="#31B43E">Vendor</TagOutline>
                          ) : relationship === "Other" ? (
                            <TagOutline color="#1FB5D6">Other</TagOutline>
                          ) : null}
                          <br />
                        </>
                      )
                    )
                  : "N/A"}
              </div> */}
              <div className={styles.subTableCell}>
                {survey_obj(props.claim.survey).country}
                <div className="flex flex-col border-t border-[#EDEDED] mt-5 pt-5 gap-2">
                  <div className="text-[#13A2A2] text-xs font-medium">
                    Common Tokens
                  </div>

                  <div className="text-[#666666] text-xs font-medium">{
                    props.claim?.token_minted ?
                    Number(+props.claim?.token_minted / 10**18).toFixed(2):
                      0
                  }</div>
                </div>
              </div>
              <div className={styles.subTableCell}>
                {props.claim?.agreement_number || "N/A"}
                {/* <div className="flex flex-col border-t border-[#EDEDED] mt-5 pt-5 gap-2">
                  <div className="text-[#13A2A2] text-xs font-medium">
                    Preference Tokens
                  </div>

                  <div className="text-[#666666] text-xs font-medium">N/A</div>
                </div> */}
                <div className="flex flex-col border-t border-[#EDEDED] mt-5 pt-5 gap-2">
                  <div className="text-[#13A2A2] text-xs font-medium">
                    Tx ID
                  </div>

                  <div className="text-[#666666] text-xs font-medium">
                    {props.claim?.hash ?<a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_EXPLORER_URL}/${props.claim.hash}`} >
                    {props.claim.hash?.slice(0, 4)}...{props.claim.hash?.slice(
                      -6
                    )}
                  </a> : 'N/A'}
                  </div>
                </div>
              </div>
              <div className={styles.subTableCell}>
                <div>{
                    props.claim?.token_minted ?
                      Number(+props.claim?.token_minted / 10** 18).toFixed(2):
                      0
                  }</div>
                
              </div>

              {/* <div className={styles.subTableCell}>
              {props.claim.balance_confirmed ? (
                props.claim.balance_confirmed === "No" ? (
                  <TagOutline color="#CF352A">● NO</TagOutline>
                ) : props.claim.balance_confirmed === "Yes" ? (
                  <TagOutline color="#31B43E">● YES</TagOutline>
                ) : (
                  "N/A"
                )
              ) : (
                "N/A"
              )}
            </div> */}
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

TableRow.propTypes = {};

export default TableRow;
