import React, { useCallback, useEffect } from 'react'
import { clientAPI } from "../../api/client";

import { useNavigate, useSearchParams } from 'react-router-dom';
import { CLAIM_TYPE, CLAIM_TYPE_FTX } from '../../common/constants';
import { message } from 'antd';


function Redirect() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const data = Object.fromEntries(
    Array.from(searchParams)
  );
  const navigate = useNavigate();
  const [messageApi, messageContextHolder] = message.useMessage();
  const verify = useCallback(async () => {
    console.log(data, 'datadata');
    
    if (!data || !data.accountId || !data.host || !data.nonce || !data.path || !data.sign || !data.timestamp) {
      return;
    }
    const result = await clientAPI("post", "/verify", {
      accountId: data.accountId,
      host: data.host,
      nonce: data.nonce,
      path: data.path,
      sign: data.sign,
      timestamp: data.timestamp,
      applicationId: data.applicationId,
      claimType: data.claimType,
    });
    if (result.status === "FAILED" || !result.status) {
      console.log("Something wrong with your request");
      messageApi.error("Login failed", 10)
    } else {
      localStorage.setItem("token", result?.message?.token);
      localStorage.setItem("accountId", result?.message?.accountId);
      localStorage.setItem("applicationId", data?.applicationId);
      localStorage.setItem("claimType", data?.claimType);
      if(data.claimType === CLAIM_TYPE) {
        window.open("/#/my-account", "_self");
      } else {
        window.open("/#/my-ftx-claims", "_self");
      }
    }
  }, [data]);

  useEffect(() => {
    verify();
  }, [verify]);

  console.log(data);

  return (
    <div>{messageContextHolder}</div>
  )
}

Redirect.propTypes = {}

export default Redirect
