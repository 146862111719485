import { useState, useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import useSVP from './useSVP';
import { Button, Typography } from 'antd';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  data,
}: any) => {
  // org
  // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      className="text-xs"
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {data?.name}
    </text>
  );
};

export default function SVPData() {
  /* spvTotalPrefRisk */
  const { claimAmount, prefRisk, assetCompositions } = useSVP();
  const [displayMore, setDisplayMore] = useState<boolean>(false);
  const preferenceRatio = useMemo(() => {
    if (
      !claimAmount ||
      claimAmount?.notionalTotal === 0 ||
      prefRisk === undefined
    )
      return 'Insufficient Data';

    return (
      prefRisk /
      (claimAmount.notionalTotal)
    ).toFixed(2);
  }, [claimAmount, prefRisk]);

  const claimTypeData = useMemo(() => {
    if (
      !claimAmount
    )
      return undefined;

    const earnPercentage =
      claimAmount?.earnTotal /
      (claimAmount?.earnTotal + claimAmount?.custodyTotal);

    return [
      {
        id: 1,
        name: 'Earn',
        percentage: earnPercentage * 100,
        color: '#03ABAB',
      },
      {
        id: 2,
        name: 'Custody',
        percentage: 100 - earnPercentage * 100,
        color: '#FACC15',
      },
    ];
  }, [claimAmount]);

  const assetCompositionData = useMemo(() => {
    if (!assetCompositions || assetCompositions.length <= 0) return undefined;

    const colors = ['#FACC15', '#6366F1', '#3B82F6', '#EC4899', '#000'];
    const displayData = (
      assetCompositions?.length > 4
        ? [
            ...assetCompositions.slice(0, 4),
            assetCompositions.slice(0, 4).reduce(
              (acc, assetCompositions) => {
                return ['Others', acc[1] - assetCompositions[1]];
              },
              ['Others', 100]
            ),
          ]
        : assetCompositions
    ).map((i, id) => ({
      id,
      name: i[0],
      percentage: i[1],
      color: id <= 4 ? colors[id] : undefined,
    }));

    return displayData;
  }, [assetCompositions]);

  return (
    <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-5 z-50">
      <div className="col-span-4 md:col-span-5">
        <div className="font-semibold text-3xl">Celsius SPV Data</div>
      </div>
      <div className="col-span-5"></div>

      <div className="col-span-4 lg:col-span-3 bg-white p-8 rounded-lg shadow">
        <div className="text-base font-semibold">
          Total Notional Claim Amount
        </div>
        <div className="text-2xl text-primary-400 font-semibold mt-3">
          $
          {claimAmount
            ? Number(claimAmount.notionalTotal)?.toFixed(
                2
              )
            : 0}
        </div>
      </div>

      <div className="col-span-4 lg:col-span-3 bg-white p-8 rounded-lg shadow">
        <div className="text-base font-semibold">Total Preference Risk</div>
        <div className="text-2xl text-primary-400 font-semibold mt-3">
          ${Number(prefRisk)?.toFixed(2)}
        </div>
      </div>

      <div className="col-span-4 lg:col-span-3 bg-white p-8 rounded-lg shadow z-50">
        <div className="text-base font-semibold">Preference Ratio</div>
        <div className="text-2xl text-primary-400 font-semibold mt-3">
          {preferenceRatio}
        </div>
      </div>

      <div className="col-span-4 lg:col-span-3 bg-white p-8 rounded-lg shadow z-50">
        <div className="text-base font-semibold">Common Tokens Outstanding</div>
        <div className="text-2xl text-primary-400 font-semibold mt-3">
          {claimAmount
            ? Number(claimAmount.custodyTotal + claimAmount.earnTotal)?.toFixed(
                2
              )
            : 0}
        </div>
      </div>

      <div className="col-span-4 lg:col-span-6 bg-white p-8 rounded-lg shadow z-50">
        <div className="text-2xl font-semibold mb-8">Claim Type</div>
        {claimTypeData ? (
          <div className="w-full grid grid-cols-12 gap-6">
            <div className="col-span-6  max-[450px]:col-span-12 h-[200px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={300} height={300}>
                  <Pie
                    data={claimTypeData}
                    cx="50%"
                    cy="50%"
                    // labelLine={false}
                    // label={({
                    //   cx,
                    //   cy,
                    //   midAngle,
                    //   innerRadius,
                    //   outerRadius,
                    //   percent,
                    //   index,
                    // }) =>
                    //   renderCustomizedLabel({
                    //     cx,
                    //     cy,
                    //     midAngle,
                    //     innerRadius,
                    //     outerRadius,
                    //     percent,
                    //     index,
                    //     data: claimTypeData?.[index],
                    //   })
                    // }
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="percentage"
                  >
                    {claimTypeData.map((el, index) => (
                      <Cell key={`cell-${index}`} fill={el.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="col-span-6 max-[450px]:col-span-12 flex items-center">
              <table className="w-full">
                <tr className="border-b border-grey-200">
                  <th className="text-sm text-grey-400 text-left">
                    Claim Type
                  </th>
                  <th className="text-sm text-grey-400 text-right">%</th>
                </tr>

                {claimTypeData.map((el) => (
                  <tr key={el.id}>
                    <td className="py-2 flex items-center text-left space-x-2">
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{ backgroundColor: el.color }}
                      ></div>
                      <div className="text-sm text-grey-400">{el.name}</div>
                    </td>
                    <td className="py-2 text-grey-400 text-right text-sm font-semibold">
                      {el.percentage.toFixed(4)}%
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <Typography.Text>Insufficient Data</Typography.Text>
        )}
      </div>

      <div className="col-span-4 lg:col-span-6 bg-white p-8 rounded-lg shadow z-50">
        <div className="text-2xl font-semibold mb-8">Asset Composition</div>
        {assetCompositionData ? (
          <div className="w-full grid grid-cols-12 gap-6">
            <div className="col-span-6 max-[450px]:col-span-12 h-[200px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={300} height={300}>
                  <Pie
                    data={assetCompositionData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    // label={({
                    //   cx,
                    //   cy,
                    //   midAngle,
                    //   innerRadius,
                    //   outerRadius,
                    //   percent,
                    //   index,
                    // }) =>
                    //   renderCustomizedLabel({
                    //     cx,
                    //     cy,
                    //     midAngle,
                    //     innerRadius,
                    //     outerRadius,
                    //     percent,
                    //     index,
                    //     data: assetCompositionData?.[index],
                    //   })
                    // }
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="percentage"
                  >
                    {assetCompositionData.map((el, index) => (
                      <Cell key={`cell-${index}`} fill={el.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="col-span-6 max-[450px]:col-span-12 flex items-center">
              <table className="w-full">
                <tr className="border-b border-grey-200">
                  <th className="text-sm text-grey-400 text-left">
                    Claim Type
                  </th>
                  <th className="text-sm text-grey-400 text-right">%</th>
                </tr>

                {assetCompositionData.slice(0, 5).map((el) => (
                  <tr key={el.id}>
                    <td className="py-2 flex items-center text-left space-x-2">
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{ backgroundColor: el.color }}
                      ></div>
                      <div className="text-sm text-grey-400">{el.name}</div>
                    </td>
                    <td className="py-2 text-grey-400 text-right text-sm font-semibold">
                      {el.percentage.toFixed(2)}%
                    </td>
                  </tr>
                ))}
                {displayMore && (
                  <>
                    {assetCompositionData
                      .slice(-(assetCompositionData.length - 5))
                      .map((el) => (
                        <tr key={el.id}>
                          <td className="py-2 flex items-center text-left space-x-2">
                            <div
                              className="w-2 h-2 rounded-full"
                              style={{ backgroundColor: el.color }}
                            ></div>
                            <div className="text-sm text-grey-400">
                              {el.name}
                            </div>
                          </td>
                          <td className="py-2 text-grey-400 text-right text-sm font-semibold">
                            {el.percentage.toFixed(2)}%
                          </td>
                        </tr>
                      ))}
                  </>
                )}
                {/* {assetCompositionData?.length >= 4 && (
                  <tr>
                    <td className="py-2 flex items-center text-left space-x-2"></td>
                    <td className="py-2 text-grey-400 text-right text-sm font-semibold">
                      <Button
                        type="ghost"
                        className={'p-0 z-50'}
                        onClick={() => setDisplayMore(!displayMore)}
                      >
                        {displayMore ? 'See less' : 'See more'}
                      </Button>
                    </td>
                  </tr>
                )} */}
              </table>
            </div>
          </div>
        ) : (
          <Typography.Text>Insufficient Data</Typography.Text>
        )}
      </div>
    </div>
  );
}
