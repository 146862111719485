import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd';

import styles from './index.module.scss';

function PageTitle({ children }: { children: string }) {
  return (
    <Typography.Title level={2} className={styles.pageTitle}>{children}</Typography.Title>
  )
}

PageTitle.propTypes = {
  children: PropTypes.string
}

export default PageTitle
