import { Button, Col, Modal, Row, Tabs, TabsProps, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useCallback, useMemo, useState } from "react";
import CryptoIcon from "../../../../components/CryptoIcon";
import styles from "./index.module.scss";
import { AiFillEye } from "react-icons/ai";
import { baseURL } from "../../../../api/client";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const mobileBalanceItems = [
  {
    label: `Earn Account`,
    value: "earn",
  },
  {
    label: `Custody Account`,
    value: "custody",
  },
  {
    label: `Withheld Account`,
    value: "witheld",
  },
  {
    label: `Borrow Account`,
    value: "borrow",
  },
];

const txHistoryItems = [
  {
    label: `Earn Account`,
    value: "earn",
  },
  {
    label: `Custody Account`,
    value: "custody",
  },
];
export default function AccountBalances({ surveyData, setPreview }: any) {
  const [balanceView, setBalanceView] = useState<boolean | null>(null);
  const [selectedBalance, setSelectedBalance] = useState<any | null>(null);

  const [txHistoryView, setTxHistoryView] = useState<boolean | null>(null);
  const [selectedTx, setSelectedTx] = useState<any | null>(null);
  const [fileSuffix, setFileSuffix] = useState<any | null>(null);

  const renderTxTab = useCallback(
    (data: { tx: any[]; pdf: any[]; excl: any[]; name: string }) => {
      const columns: ColumnsType<Record<string, any>> = [
        {
          title: "Date",
          dataIndex: "TxsDate",
          key: "TxsDate",
          render: (_, { TxsDate }, index) => (
            <Row key={index} className={""}>
              <Row align="middle" gutter={8}>
                <Col>
                  <Typography.Text>{TxsDate}</Typography.Text>
                </Col>
              </Row>
            </Row>
          ),
        },
        {
          title: "Type",
          dataIndex: "Type",
          key: "Type",
        },
        {
          title: "Token",
          dataIndex: "token",
          key: "token",
          render: (_, { token }) => (
            <Row gutter={4}>
              <Col>
                <CryptoIcon name={token?.toLowerCase()} />{" "}
              </Col>
              <Col>
                <Typography.Text>{token?.toUpperCase()}</Typography.Text>
              </Col>
            </Row>
          ),
        },
        {
          title: "Value",
          key: "Value",
          dataIndex: "Value",
        },
      ];

      return (
        <div className={styles["tx-table"]}>
          <Table pagination={false} columns={columns} dataSource={data.tx} />

          {data?.excl?.[0]?.content && (
            <Row className={styles["table-row"]}>
              <Col span={12}>
                <Typography.Text strong>
                  {data.name} Tx Excel File
                </Typography.Text>
              </Col>
              <Row>
                <Col span={12}>
                  <div className={""}>
                    <Button
                      className={"file-action text-[#03ABAB]"}
                      type="link"
                      onClick={() => setPreview(data?.excl?.[0]?.content)}
                    >
                      View
                    </Button>
                  </div>
                </Col>
              </Row>
            </Row>
          )}

          {data?.pdf?.[0]?.content && (
            <Row className={styles["table-row"]}>
              <Col span={12}>
                <Typography.Text strong>
                  {data.name} Tx PDF File
                </Typography.Text>
              </Col>
              <Row>
                <Col span={12}>
                  <div className={""}>
                    <Button
                      className={"file-action text-[#03ABAB]"}
                      type="link"
                      onClick={() => setPreview(data?.pdf?.[0]?.content)}
                    >
                      View
                    </Button>
                  </div>
                </Col>
              </Row>
            </Row>
          )}
        </div>
      );
    },
    [setPreview]
  );

  const renderBalanceTab = useCallback((data: any[]) => {
    const columns: ColumnsType<Record<string, any>> = [
      {
        title: "Token",
        dataIndex: "token",
        key: "token",
        render: (_, { price, token }) => (
          <Row gutter={4}>
            <Col>
              <CryptoIcon name={token?.toLowerCase()} />{" "}
            </Col>
            <Col>
              <Typography.Text>{token?.toUpperCase()}</Typography.Text>
            </Col>
          </Row>
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (_, { price }) => <Typography.Text>${price}</Typography.Text>,
      },
      {
        title: "Quantity",
        dataIndex: "value",
        key: "value",
        render: (_, { Quantity }) => (
          <Typography.Text>{Quantity}</Typography.Text>
        ),
      },
      {
        title: "Value",
        key: "item_value",
        dataIndex: "item_value",
        render: (_, item) => (
          <Typography.Text>
            ${Number(item?.["Claim Value"])?.toFixed(3)}
          </Typography.Text>
        ),
      },
    ];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  }, []);

  const balanceItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderBalanceTab(surveyData?.celsius_earn_balances),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderBalanceTab(surveyData?.celsius_custody_balances),
      },
      {
        key: "3",
        label: `Withheld Account`,
        children: renderBalanceTab(surveyData?.celsius_witheld_balances),
      },
      {
        key: "4",
        label: `Borrow Account`,
        children: renderBalanceTab(surveyData?.celsius_borrow_balances),
      },
    ],
    [
      renderBalanceTab,
      surveyData?.celsius_borrow_balances,
      surveyData?.celsius_custody_balances,
      surveyData?.celsius_earn_balances,
      surveyData?.celsius_witheld_balances,
    ]
  );

  const txItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderTxTab({
          tx: surveyData?.celsius_tx_earn,
          pdf: surveyData?.celsius_pdf_earn,
          excl: surveyData?.celsius_excl_earn,
          name: "Earn",
        }),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderTxTab({
          tx: surveyData?.celsius_tx_custody,
          pdf: surveyData?.celsius_pdf_custody,
          excl: surveyData?.celsius_excl_custody,
          name: "Custody",
        }),
      },
    ],
    [
      renderTxTab,
      surveyData?.celsius_excl_custody,
      surveyData?.celsius_excl_earn,
      surveyData?.celsius_pdf_custody,
      surveyData?.celsius_pdf_earn,
      surveyData?.celsius_tx_custody,
      surveyData?.celsius_tx_earn,
    ]
  );

  const handleBalanceTabForMobile = (data: any) => {
    setBalanceView(true);
    setSelectedBalance(surveyData?.[`celsius_${data}_balances`]);
  };

  const handleTxHistoryForMobile = (data: any) => {
    setSelectedTx(surveyData?.[`celsius_tx_${data}`]);
    setFileSuffix(data);
    setTxHistoryView(true);
  };

  return (
    <div
      className={
        "divide-y divide-grey-100 sm:divide-y-0 " + styles.table_balance
      }
    >
      {surveyData?.ftx_claim_data?.length ? (
        surveyData?.ftx_claim_data?.map((item: any, index: number) => {
          return (
            <div key={index} className="py-4 sm:py-0">
              <div className="flex items-center">
                {!!item.tokenName && (
                  <div className="mr-2">
                    <CryptoIcon name={item.tokenName?.toLowerCase()} />
                  </div>
                )}
                <div className="text-sm font-semibold">
                  {item.tokenName}&nbsp;&nbsp;
                </div>
              </div>
              <div className="mt-2.5 sm:mt-2 text-xs">
                {(+item.Quantity).toFixed(12)}
              </div>
            </div>
          );
        })
      ) : (
        <p>N/A</p>
      )}
    </div>
  );
}
