import { useState } from "react";
import { Button, Modal } from "antd";
import styles from "./index.module.scss";
import { Checkbox } from "antd";
import clsx from "clsx";

function AgreementModal({ isModalOpen, cancelDisclaimer }) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <Modal
      open={isModalOpen}
      footer={null}
      width={770}
      onCancel={cancelDisclaimer}
      style={{ top: 20 }}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>Master Agreement</div>
        <div className={styles.modalContent}>
          <div className={styles.assignmentOfClaimAgreementParent}>
            <div className={styles.assignmentOfClaimAgreement}>
              <div className={styles.frameParent}>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameContainer}>
                    <div className={styles.frameGroup}>
                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.frameParent}>
                            <div className={styles.masterAgreement}>
                              MASTER AGREEMENT
                            </div>
                            <div className={styles.frameWrapper1}>
                              <div className={styles.frameContainer}>
                                <div className={styles.draftNoContainer}>
                                  <p className={styles.draftNo}>
                                    <span>{`Draft No: `}</span>
                                    <span className={styles.span}>[ ]</span>
                                  </p>
                                  <p className={styles.draftNo}>
                                    <span>{`Draft Date: `}</span>
                                    <span className={styles.span}>[Date]</span>
                                  </p>
                                  <p className={styles.blankLine}>&nbsp;</p>
                                  <p className={styles.draftNo}>
                                    <span>{`Dated: `}</span>
                                    <span className={styles.span}>[Date]</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.frameChild} />
                        </div>
                        <div className={styles.frameWrapper2}>
                          <div className={styles.signatoriesParent}>
                            <div className={styles.party1Wrapper}>
                              <div className={styles.celsius0Spv}>
                                [party 1]
                              </div>
                            </div>
                            <div className={styles.party1Wrapper}>
                              <div className={styles.celsius0Spv}>
                                Celsius0 SPV
                              </div>
                            </div>
                            <div className={styles.party1Wrapper}>
                              <div className={styles.celsius0Spv}>
                                Heimdall Operations Limited
                              </div>
                            </div>
                            <div className={styles.party1Wrapper}>
                              <div className={styles.celsius0Spv}>OPNX</div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.lineParent}>
                          <div className={styles.frameChild} />
                          <div className={styles.frameGroup}>
                            <div className={styles.masterAgreementRelating}>
                              MASTER AGREEMENT RELATING TO ELIGIBILITY OF CLAIM
                            </div>
                            <div
                              className={styles.applebySeychellesOfficeParent}
                            >
                              <div
                                className={
                                  styles.applebySeychellesOfficeContainer
                                }
                              >
                                <p className={styles.draftNo}>
                                  APPLEBY SEYCHELLES
                                </p>
                                <p className={styles.draftNo}>OFFICE:</p>
                              </div>
                              <div className={styles.suite202SecondContainer}>
                                <p className={styles.draftNo}>
                                  Suite 202, Second Floor
                                </p>
                                <p className={styles.draftNo}>
                                  Eden Plaza, Eden Island
                                </p>
                                <p className={styles.draftNo}>
                                  Mahe, Seychelles
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.contentsParent}>
                        <div className={styles.contents}>CONTENTS</div>
                        <div className={styles.clauseParent}>
                          <div className={styles.masterAgreementRelating}>
                            Clause
                          </div>
                          <div className={styles.masterAgreementRelating}>
                            Page
                          </div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>1.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Definitions and Interpretation
                              .....................................................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>1</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>2.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Agreement
                              ....................................................................................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>5</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>3.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Acceptance of Agreement and Contribution
                              ............................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>6</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>4.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Representations, Warranties and Undertakings of
                              the Participant
                              ........................................
                            </div>
                          </div>
                          <div className={styles.div1}>7</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>5.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Disclaimers, Limitation of Liability,
                              Indemnification
                              .................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>11</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>6.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Fees
                              ..............................................................................................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>14</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>7.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Miscellaneous
                              ................................................................................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>14</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>9.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Notices
                              ..........................................................................................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>15</div>
                        </div>
                        <div className={styles.aaParent}>
                          <div className={styles.parent}>
                            <div className={styles.div}>10.</div>
                            <div
                              className={styles.definitionsAndInterpretation}
                            >
                              Dispute Resolution and Governing Law
                              ......................................................................................
                            </div>
                          </div>
                          <div className={styles.div1}>16</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper3}>
                  <div className={styles.definitionsAndInterpretationGroup}>
                    <div className={styles.parent}>
                      <div className={styles.thisAgreementIsContainer}>
                        <span className={styles.thisAgreement}>
                          THIS AGREEMENT
                        </span>
                        <span className={styles.isDated}> is dated</span>
                      </div>
                      <div className={styles.div18}>20</div>
                    </div>
                    <div className={styles.parties}>parties</div>
                    <div className={styles.nameOfRecipientContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          [Name of recipient], a resident/citizen of [ ] having
                          residential/ registered office address at [ ]
                          (“Participant”);
                        </li>
                        <li className={styles.nameOfRecipient}>
                          Celsius0 SPV, a company limited by shares registered
                          under the laws of the Seychelles and having its
                          registered office at [ ], Seychelles (“Issuer”);
                        </li>
                        <li className={styles.nameOfRecipient}>
                          Heimdall Operations Limited, a company limited by
                          shares registered under the laws of the Seychelles and
                          having its registered office at [ ], Seychelles
                          (“Heimdall”); and
                        </li>
                        <li>
                          OPNX, a company registered in [ ] and having its
                          registered office at [ ] (“OPNX”, with the
                          Participant, the Issuer and Heimdall, the “Parties”).
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.preambleParent}>
                  <div className={styles.preamble}>PREAMBLE</div>
                  <div className={styles.nameOfRecipientContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (A). This Master Agreement (“Agreement”) is an agreement
                        by and among the Participant, the Issuer, Heimdall, and
                        OPNX to set forth the process for determining the
                        Eligibility of the Participant’s Claim.
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (B). The Participant represents to hold certain Claim(s)
                        against the Debtor and has agreed to voluntarily provide
                        the information and documents relating to such Claim(s)
                        to Heimdall, as requested, in order for Heimdall to
                        determine the Eligibility of such Claim(s).
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (C). OPNX has agreed to review the details provided by
                        the Participant and collect all necessary documents and
                        information required for Know Your Customer compliance,
                        if not already in the possession of OPNX. Should the
                        Participant’s Claim be deemed eligible to participate by
                        OPNX, OPNX shall forward the Claim to Heimdall to
                        determine Eligibility.
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (D). Upon receipt of a Claim from OPNX, Heimdall shall
                        determine the Eligibility of such Claim to the best of
                        its ability and notify OPNX accordingly.
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li>
                        (E). OPNX has agreed to notify the Participant of its
                        Claim’s Eligibility to enter into an Assignment of Claim
                        Agreement pursuant to which the Claim would be assigned
                        to the Issuer in return for OPNX Credit.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={styles.definitionsAndInterpretationParent}>
                  <div className={styles.preamble}>
                    <ul className={styles.definitionsAndInterpretation1}>
                      Definitions and Interpretation
                    </ul>
                  </div>
                  <div className={styles.thePartiesAgreeContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (A). This Master Agreement (“Agreement”) is an agreement
                        by and among the Participant, the Issuer, Heimdall, and
                        OPNX to set forth the process for determining the
                        Eligibility of the Participant’s Claim.
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (B). The Participant represents to hold certain Claim(s)
                        against the Debtor and has agreed to voluntarily provide
                        the information and documents relating to such Claim(s)
                        to Heimdall, as requested, in order for Heimdall to
                        determine the Eligibility of such Claim(s).
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (C). OPNX has agreed to review the details provided by
                        the Participant and collect all necessary documents and
                        information required for Know Your Customer compliance,
                        if not already in the possession of OPNX. Should the
                        Participant’s Claim be deemed eligible to participate by
                        OPNX, OPNX shall forward the Claim to Heimdall to
                        determine Eligibility.
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        (D). Upon receipt of a Claim from OPNX, Heimdall shall
                        determine the Eligibility of such Claim to the best of
                        its ability and notify OPNX accordingly.
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li>
                        (E). OPNX has agreed to notify the Participant of its
                        Claim’s Eligibility to enter into an Assignment of Claim
                        Agreement pursuant to which the Claim would be assigned
                        to the Issuer in return for OPNX Credit.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.preamble}>AGREED TERMS</div>
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.definitionsAndInterpretationGroup}>
                    <div className={styles.acceptanceOfAgreementContainer}>
                      <ul className={styles.definitionsAndInterpretation2}>
                        Definitions and Interpretation
                      </ul>
                    </div>
                    <div className={styles.inThisAgreementContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            1.1 In this Agreement the following capitalized
                            terms have the meanings given below, unless the
                            context requires otherwise:
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Assignment of Claim Agreement:
                          </span>
                          <span>
                            {" "}
                            an agreement between the Participant and the Issuer
                            by virtue of which the Participant shall assign its
                            Claim to the Issuer in exchange for receipt of OPNX
                            Credit.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Business:
                          </span>
                          <span>
                            {" "}
                            individually and collectively as the case may be,
                            the business of the Issuer, its management and
                            administration.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Claim:
                          </span>
                          <span>
                            {" "}
                            means, with respect to each Participant, all of such
                            Participant’s right, title and interest in and to
                            each of the Participant’s claims against the Debtor,
                            and all rights and benefits of such Participant
                            relating to such claim or claims, including without
                            limitation:
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (a). all accounts, agreements, instruments,
                            invoices, purchase orders and other documents
                            evidencing, or relating to such claim or claims or
                            referred to therein;
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (b). such Participant’s right to receive principal,
                            interest, penalties and fees, if any, which may be
                            paid with respect to such claim or claims;
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (c). any actions, claims, rights or lawsuits of any
                            nature whatsoever, whether against the Debtor, its
                            affiliates, any guarantor or any other person or
                            entity, arising out of or in connection with such
                            claim or claims;
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (d). all voting and other rights or benefits arising
                            from, under or relating to such claim or claims; and
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (e). all cash, securities, instruments and other
                            property which may be paid or issued by the Debtor
                            in satisfaction of such claim or claims under any
                            insolvency laws or otherwise.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Damages:
                          </span>
                          <span>
                            {" "}
                            collectively any losses, liabilities, costs or
                            expenses of any kind, whether direct or indirect,
                            consequential, compensatory, incidental, actual,
                            exemplary, punitive, special exemplary or economic
                            (and whether intentionally caused or resulting from
                            negligence) which arises in tort, contract or
                            otherwise including, without limitation, any loss of
                            property, business, revenue, profit, data, use,
                            goodwill or other intangible loss.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Debtor:
                          </span>
                          <span>
                            {" "}
                            the person or entity identified as the debtor of the
                            Participant in the Assignment of Claim Agreement.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Eligibility:
                          </span>
                          <span>
                            {" "}
                            the determination made by Heimdall as to whether a
                            Participant qualifies to assign its Claim to the
                            Issuer following a proprietary review of the Claim
                            held by a Participant, with the term “Eligible”
                            being construed accordingly.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Issuer Affiliates:
                          </span>
                          <span>
                            {" "}
                            each of the Issuer’s past, present and future
                            employees, officers, directors, contractors,
                            consultants, equity holders, suppliers, volunteers,
                            certifiers, vendors, service providers, affiliates,
                            agents, representatives, partners, advisors, and the
                            predecessors, successors and assigns of each of the
                            foregoing (and Issuer Affiliate is any one of them).
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Know Your Customer:
                          </span>
                          <span>
                            {" "}
                            guidelines and regulations in financial services
                            relating to the verification of the identity,
                            suitability, and risks involved with maintaining a
                            business relationship with a customer.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            OPNX Credit:
                          </span>
                          <span>
                            {" "}
                            the credit to be issued by OPNX to the Participant,
                            for exclusive use on the OPNX platform, in exchange
                            for the assignment of the Claim to the Issuer.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.restrictedJurisdictionAt}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.thisAgreement}>
                            Restricted Jurisdiction:
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (a).the United States of America and any of its
                            territories, possessions and other areas subject to
                            its jurisdiction;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (b). Canada;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (c). any Financial Action Task Force (“
                          </span>
                          <span className={styles.assignmentOfClaim}>FATF</span>
                          <span className={styles.atheUnitedStates}>
                            ”) high-risk jurisdiction of a FATF jurisdiction
                            with strategic deficiencies;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (d). Cuba;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (e). Democratic People’s Republic of Korea (“
                          </span>
                          <span className={styles.assignmentOfClaim}>
                            North Korea
                          </span>
                          <span className={styles.atheUnitedStates}>”);</span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (f). Iran;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (g). Pakistan;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (h). Syria;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (i). the Crimea region of Ukraine;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (j). United Arab Emirates;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (k). the Seychelles;
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (l). Bahrain; and
                          </span>
                        </li>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.atheUnitedStates}>
                            (m). any jurisdiction in which the sale of Tokens,
                            or any solicitation in respect of the Tokens, would
                            require registration or licensing not obtained by
                            the Issuer or otherwise would be unlawful (where the
                            Issuer or any relevant entity does not comply with
                            such restrictions).
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Restricted Person:
                          </span>
                          <span>
                            {" "}
                            any person that is, or any person acting on behalf
                            of:
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (a). a citizen, resident (tax or otherwise) of, or
                            person located or domiciled in any Restricted
                            Jurisdiction or any entity, including without
                            limitation, any corporation, partnership, trust or
                            unincorporated entity, created or organized in or
                            under the laws of any Restricted Jurisdiction;
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (b). any person who is the subject of Sanctions or
                            is organized or resident in a country or territory
                            that is the subject of country-wide or
                            territory-wide Sanctions; or
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>(c). a person under the age of 18 years.</span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span className={styles.assignmentOfClaim}>
                            Sanctions:
                          </span>
                          <span>
                            {" "}
                            economic or financial sanctions or trade embargoes
                            administered or enforced from time to time by:
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (a). the U.S. government, including those
                            administered by OFAC or the U.S. Department of
                            State;
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (b). the United Nations Security Council, the
                            European Union or His Majesty’s Treasury of the
                            United Kingdom; or
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          <span>
                            (c). any other relevant sanctions authority.
                          </span>
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li>
                          <span className={styles.assignmentOfClaim}>
                            Tokens:
                          </span>
                          <span>
                            {" "}
                            aggregately, the token or series of tokens, whether
                            fungible or non-fungible in nature, issued and/or
                            distributed by the Issuer, at the sole and absolute
                            discretion of the Issuer and without requiring
                            consent from any other party.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          1.2 References in this Agreement to:
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          (a). a person includes any individual, company,
                          corporation, body corporate, partnership, trust or
                          unincorporated association (whether or not having
                          separate legal personality);
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          (b). a reference to a document includes all
                          modifications, amendments or supplements to such
                          document, or replacements or novations of it in
                          accordance with their terms;
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          (c). a reference to a Clauses or Schedules is to,
                          respectively, clauses of and schedules to this
                          Agreement and references to this Agreement include its
                          Schedules;
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          (d). Clause and Schedule headings are for convenience
                          only and are to be ignored in construing this
                          Agreement;
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          (e). including means including without limitation and
                          includes and included shall be construed accordingly;
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          (f). a defined term includes both the singular and the
                          plural;
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          (g). a month or a year is a calendar month and a
                          calendar year, respectively; and
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li>
                          (h) a reference to a statute or any provision thereof
                          is a reference that statute or such provision thereof,
                          as it may be amended or re-enacted from time to time.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.definitionsAndInterpretationGroup}>
                  <div className={styles.acceptanceOfAgreementContainer}>
                    <ul className={styles.definitionsAndInterpretation2}>
                      Agreement
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      2.1 The Participant is the sole owner of the Claim and
                      wishes to voluntarily provide the details of such Claim to
                      Heimdall as is necessary for Heimdall to determine the
                      Eligibility of such Claim. For the avoidance of doubt, the
                      execution of this Agreement does not constitute an
                      assignment of any Claim, nor does it imply any
                      entitlement, guarantee, or assurance of any sort that such
                      Claim may be Eligible for assignment.
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      2.2 The Participant, already otherwise eligible to trade
                      on OPNX, has indicated to OPNX its desire to assign its
                      Claim. OPNX shall review the Know Your Customer
                      information of the Participant to ensure its validity and
                      request any additional information not already in its
                      possession as may be required. Should the Participant be
                      deemed to be compliant by OPNX, OPNX shall inform the
                      Participant of the same and send the requisite information
                      to Heimdall to determine the Eligibility of the
                      Participant’s Claim.
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      2.3 Upon receiving the requisite information from OPNX,
                      Heimdall shall use reasonable efforts to obtain
                      information related to the Claim determine the Eligibility
                      of such Claim. Heimdall shall then notify OPNX of the
                      Claim’s Eligibility.
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      2.4 It is expressly agreed by all Parties to this
                      Agreement that Heimdall shall use reasonable efforts to
                      take all actions and to do all things necessary, proper or
                      advisable to determine Eligibility under Clause 2.3 and
                      the Parties indemnify and hold harmless Heimdall against
                      any claim which may be brought in relation to Heimdall’s
                      review and ultimate determination of the Claim’s
                      Eligibility. Heimdall’s determination of the Eligibility
                      of the Claim shall be final, and there shall be no duty on
                      the part of Heimdall to provide any basis for its
                      determination as to the Eligibility, or lack thereof, to
                      the Issuer or to any other party. For the avoidance of
                      doubt, Heimdall shall bear no liability with regard to its
                      determination of the Claim’s Eligibility.
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      2.5 The Participant hereby agrees that its Claim could be
                      valid but not Eligible for assignment as part of this
                      Agreement. The Participant agrees to voluntarily submit
                      its Claim for consideration by Heimdall irrespective of
                      the outcome of the determination, and the Participant
                      further agrees that it shall have no right of appeal
                      against Heimdall or any other party regarding such
                      determination.
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      2.6 Upon receipt of said notification from Heimdall, OPNX
                      shall notify the Participant of its Claim’s Eligibility
                      (or non-Eligibility, as the case may be) to enter into an
                      Assignment of Claim Agreement.
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      2.7 Following the lawful assignment of the Claim to the
                      Issuer, the Participant shall be eligible to receive OPNX
                      Credit as consideration for assignment of its Claim to the
                      Issuer subject to such terms and conditions as may be
                      agreed between OPNX and the Participant.
                    </ul>
                  </div>
                </div>
                <div className={styles.definitionsAndInterpretationGroup}>
                  <div className={styles.acceptanceOfAgreementContainer}>
                    <ul className={styles.definitionsAndInterpretation2}>
                      Acceptance of Agreement and Contribution
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      3.1 The Participant agrees that the determination of the
                      OPNX Credit to be credited to it shall be final and
                      binding upon it.
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        3.2 The Participant hereby confirms that it is aware of
                        the risks associated with this Agreement and the
                        transaction set forth herein, and unconditionally and
                        irrevocably agrees to enter into this Agreement and to
                        take such risks voluntarily, including without
                        limitation, the following risks which could negatively
                        impact the OPNX Credit:
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        The rapidly evolving, economical, commercial,
                        technological, legal, and regulatory environment linked
                        to blockchains and cryptocurrencies;
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        The volatility and unpredictability of the price of
                        cryptocurrencies generally;
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        The novelty and the uncertainty linked to the evolution
                        of the legal and regulatory environment of
                        cryptocurrencies and blockchain technologies;
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        The rapid technological changes characterising the
                        blockchain industry, including changing uses, changes in
                        customer demand, frequent product and service
                        introductions and the emergence of new industry
                        standards and practices;
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li className={styles.nameOfRecipient}>
                        The risks associated with OPNX including, but not
                        limited to:
                      </li>
                      <li className={styles.nameOfRecipient}>
                        Being unable to access OPNX;
                      </li>
                      <li className={styles.nameOfRecipient}>
                        The potential loss in value of the OPNX Credit and
                        specifically the risk that the OPNX Credit could have a
                        value of zero; and
                      </li>
                    </ul>
                    <p className={styles.draftNo}>&nbsp;</p>
                    <ul className={styles.nameOfRecipientAResident}>
                      <li>
                        Any other risks that could arise, or could be reasonably
                        expected to arise, out of interaction with
                        cryptocurrencies and blockchains, related to their use,
                        storage, transfer, legal treatment, economic value, and
                        any and all other risks that may impact the desired or
                        intended actions or outcomes of the Participant.
                      </li>
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      3.3 The Participant shall rely on its own careful review
                      of this Agreement and on its own careful research and
                      examination of all relevant documents, including any
                      previous or subsequent agreement it is required to enter
                      into with OPNX for the grant of OPNX Credit to it and any
                      risks listed therein, and shall independently weigh all
                      risks involved with the OPNX Credit. This Agreement does
                      not and is not intended to provide legal, tax or
                      accounting advice to the Participant and the Participant
                      shall consult with its own advisors with respect to the
                      legal, tax, regulatory, financial and accounting
                      consequences of holding OPNX Credit. The Participant shall
                      read, comprehend and consider carefully the non-exhaustive
                      risks described above before deciding whether to proceed
                      with this Agreement, any related agreement and the
                      underlying transaction. Such decision shall be made with a
                      full awareness of the risks involved, which include the
                      risk of a total loss of contributions and a total loss of
                      the utility or value of the OPNX Credit.
                    </ul>
                  </div>
                </div>
                <div className={styles.definitionsAndInterpretationGroup}>
                  <div className={styles.acceptanceOfAgreementContainer}>
                    <ul className={styles.definitionsAndInterpretation2}>
                      Representations, Warranties and Undertakings of the
                      Participant
                    </ul>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      3.1 The Participant agrees that the determination of the
                      OPNX Credit to be credited to it shall be final and
                      binding upon it.
                    </ul>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(a)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      <p className={styles.draftNo}>
                        it is the sole legal and beneficial owner of, and has
                        good title to, the Claim, free and clear of
                      </p>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <p className={styles.draftNo}>
                        (i). any legal, regulatory or contractual restriction on
                        transfer; or
                      </p>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <p className={styles.draftNo}>
                        (ii). any pledge, lien, claim, security interest,
                        participation, factoring agreement or other encumbrance
                        of any type or kind (each a Lien and collectively Liens)
                        and shall assign to issuer such good title free and
                        clear of any and all Liens;
                      </p>
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(b)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      <p className={styles.draftNo}>the Participant</p>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <p className={styles.draftNo}>
                        (i). is the original owner of the Claim; and
                      </p>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <p className={styles.draftNo}>
                        (ii). has not previously sold, transferred, assigned,
                        participated or otherwise conveyed the Claim, nor has
                        Participant agreed to do any of the foregoing;
                      </p>
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(c)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      no payment or distribution has been received by or on
                      behalf of the Participant, in full or partial satisfaction
                      of the Claim;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(d)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant has not engaged (and shall not engage) in
                      any act, conduct or omission, and has not had (and shall
                      not have) any relationship with the Debtor or any of its
                      affiliates that shall reduce or impair or otherwise
                      adversely affect the Claim or result in Issuer receiving
                      proportionately less in payments or distributions under,
                      or less favorable treatment (including the timing of
                      payments or distributions) for the Claim than is received
                      by creditors holding claims of the same class or type as
                      the Claim;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(e)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      there are no preference actions against the Participant or
                      the Claim, and no legal or equitable defences,
                      counterclaims or offsets, reductions, recoupments,
                      impairments, avoidances, disallowances or subordinations
                      have been (or may be) asserted by or on behalf of Debtor
                      to reduce the amount of the Claim or affect its validity
                      or enforceability;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(f)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant has not received any payment from the
                      Debtor or its affiliates within the ninety (90) days
                      preceding the date of this Agreement, nor has the
                      Participant withdrawn any funds from any account
                      Participant held with the Debtor or any of its affiliates
                      during the same period of time;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(g)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Claim is a valid, undisputed, liquidated, enforceable,
                      unsubordinated, non-contingent general unsecured claim
                      against the Debtor;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(h)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the basis for the Claim is amounts due and owing by the
                      Debtor arising from the Participant’s deposit of crypto
                      assets on the Debtor’s cryptocurrency-based finance
                      platform, which crypto assets were received by the Debtor;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(i)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Claim is specifically against the Debtor designated in
                      the Assignment of Claim Agreement, and not against any
                      subsidiary, parent or affiliate of the Debtor;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(j)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      no objection to the Claim has been made or threatened and
                      there are no offsets, setoffs or legal or equitable
                      defences that have been (or may be) asserted by or on
                      behalf of Debtor or any other party to reduce the amount
                      of the Claim below the Claim Amount or to reduce the value
                      of the Claim as compared to other claims against the
                      Debtor of the same class or type as the Claim;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(k)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      no Lien, objection, dispute, counterclaim, defence or
                      claim (including any preference or fraudulent conveyance
                      claim) or right of set-off, reduction, offset, delay,
                      recoupment, disgorgement, impairment, avoidance,
                      disallowance or subordination by the Debtor or any other
                      entity (collectively, an “Impairment”), has been or shall
                      be asserted in respect of the Claim or any part of the
                      Claim;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.a}>
                      <ul className={styles.nameOfRecipientAResident}>(l)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant is not and has never been an “insider” of
                      the Debtor or its affiliates within the meaning of Section
                      101(31) of the US Bankruptcy Code, is not an “affiliate”
                      of the Debtor, as such term is defined in either Section
                      101(2) of the US Bankruptcy Code or Rule 144 of the US
                      Securities Act of 1933, as amended;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(m)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant does not hold any funds or property of, or
                      owe any amounts or property to, the Debtor or any of its
                      affiliates and has not effected or received, and shall not
                      effect or receive, the benefit of any setoff against the
                      Debtor or any of its affiliates;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(n)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      true, correct and complete copies of all Claim
                      documentation have been delivered by the Participant, and
                      other than these documents, there are no other contracts,
                      documents, agreements, stipulations or orders that could
                      materially or adversely affect the Claim or Issuer’s
                      rights hereunder;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(o)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      true and complete copies of all agreements, instruments,
                      invoices, purchase orders, proofs of delivery and other
                      documents evidencing or relating to the Claim will be, at
                      Issuer’s option, delivered to Issuer prior to payment of
                      the consideration or maintained in good condition by
                      Participant until further notice;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(p)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant will not be subject to tax withholding on
                      any distributions on the Claim by or on behalf of a
                      government entity;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(q)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant has not received any notice, and has no
                      knowledge, that the Claim or any portion thereof are void,
                      voidable, unenforceable or subject to any impairment;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(r)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      no broker, finder, agent, or other entity acting on behalf
                      of the Participant is entitled to any commission or fee
                      for which the Issuer could be responsible;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(s)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant is not “insolvent” within the meaning of
                      Section 101(32) of the US Bankruptcy Code (and will not be
                      rendered insolvent as a result of its sale of the Claim)
                      and is able to pay its debts as they become due, has
                      adequate capital to conduct its business and has not filed
                      a petition seeking protection under the US Bankruptcy Code
                      or similar statute;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(t)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant has correctly and completely represented
                      to Heimdall (i) whether it filed a proof of claim in the
                      Debtor’s bankruptcy case or relies on the Debtor’s
                      scheduled claim; and (ii) all information related thereto;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(u)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      there are no encumbrances or other debt owed by the
                      Participant on the Claim and the Participant owns the
                      Claim outright and the Claim is not the subject of any
                      security or third-party interest;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(v)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      it is not a Restricted Person and is outside the
                      Restricted Jurisdictions at the time of executing this
                      Agreement, and shall be outside of the Restricted
                      Jurisdictions at any time that it performs its obligations
                      under this Agreement or otherwise holds OPNX Credit;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(w)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      if the Participant is a natural person, it represents that
                      it has full power and authority to execute, deliver and
                      perform under this Agreement and all ancillary documents,
                      and such agreements constitute the valid, legal and
                      binding agreement of Participant, enforceable against
                      Participant in accordance with their terms;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(x)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      if the Participant is not a natural person, it represents
                      that it is duly organized and validly existing under the
                      laws of its jurisdiction of organization, in good standing
                      under such laws, and is authorized and empowered to
                      execute, deliver and perform under this Agreement and all
                      ancillary documents, and such agreements constitute the
                      valid, legal and binding agreement of Participant,
                      enforceable against Participant in accordance with their
                      terms;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(y)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      it has carefully considered, acknowledges, understands and
                      assumes the risks associated with the assignment of its
                      Claim;
                    </div>
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.m}>
                      <ul className={styles.nameOfRecipientAResident}>(z)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      it has read and understands this Agreement and its
                      contents;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(aa)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      it has obtained sufficient information in order to make an
                      informed decision to proceed with the assignment of its
                      Claim(s) for the OPNX Credit and has had the opportunity
                      to seek legal, accounting, tax and other professional
                      advice regarding this Agreement and any related document;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(bb)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      neither the execution, delivery or performance of this
                      Agreement, nor consummation of the transactions
                      contemplated hereby, shall violate or contravene any law,
                      rule, regulation, order, agreement or instrument affecting
                      the Participant or the Claim and there are no proceedings
                      pending, or threatened against the Participant, that would
                      adversely affect the Claim or any action taken or to be
                      taken by the Participant hereunder;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(cc)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      all information submitted by the Participant to OPNX and
                      Heimdall is true, complete, valid and not misleading; and
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(dd)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      neither the Participant, nor any person having a direct or
                      indirect beneficial interest in the Participant, is the
                      subject of Sanctions or is organized or resident in a
                      country or territory that is a Restricted Jurisdiction or
                      the subject of country-wide or territory-wide Sanctions.
                    </div>
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>{`4.1 `}</span>
                      <span className={styles.assignmentOfClaim}>
                        Undertakings.
                      </span>
                      <span>
                        {" "}
                        The Participant undertakes that it shall notify all
                        parties to this Agreement in writing, immediately, upon
                        becoming aware that any of the representations and
                        warranties made by Participant pursuant to Clause 4.1
                        was at the time it was made or deemed to be made, or is
                        becoming with the passage of time, false or misleading.
                      </span>
                    </ul>
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>{`4.2 `}</span>
                      <span className={styles.assignmentOfClaim}>
                        Acknowledgements.
                      </span>
                      <span>
                        {" "}
                        The Participant expressly acknowledges, understands and
                        agrees that:
                      </span>
                    </ul>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(a)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the assignment of its Claim(s) for the OPNX Credit is
                      non-refundable and cannot be cancelled;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(b)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      <p className={styles.draftNo}>
                        the assignment of its Claim(s) for the OPNX Credit
                        hereunder:
                      </p>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <p className={styles.draftNo}>
                        (i).does not provide the Participant with any ownership
                        of any physical asset or ownership or other interest or
                        rights of any form with respect to the Issuer, any
                        Issuer Affiliate, Heimdall or OPNX or their revenues or
                        assets, including, but not limited to, any voting,
                        distribution, redemption, liquidation, proprietary
                        (including all forms of intellectual property), or other
                        financial or legal rights; and
                      </p>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <p className={styles.draftNo}>
                        (ii). does not represent a loan to the Issuer or any
                        Issuer Affiliate;
                      </p>
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(c)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the assignment of its Claim(s) for the OPNX Credit and
                      receipt of the OPNX Credit may have tax consequences for
                      the Participant; the Participant is solely responsible for
                      the Participant’s compliance with the Participant’s tax
                      obligations and neither the Issuer nor Heimdall nor OPNX,
                      by virtue of this Agreement, bears any liability or
                      responsibility with respect to any tax consequences to the
                      Participant or for any withholding, collecting, reporting,
                      paying, settling and/or remitting any sales, use, value
                      added, duty, impost, levy, tariff or surcharge or any
                      other tax arising from the Participant’s assignment of its
                      Claim for the OPNX Credit;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(d)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      nothing in this Agreement, or otherwise provided to the
                      Participant by the Issuer, Heimdall or OPNX should be
                      construed as tax, accounting, financial or legal advice
                      and the Participant bears sole responsibility to determine
                      the tax implications of the assignment of its Claim for
                      the OPNX Credit;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(e)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      this Agreement is being entered into for commercial, and
                      not for investment, speculation, hedging, or arbitrage
                      strategy, for immediate resale or other financial
                      purposes. Neither this Agreement nor any other document
                      constitutes a prospectus or security offering document,
                      nor are they an offer to sell, or the solicitation of an
                      offer to buy an investment, security, commodity, or swap
                      on an investment, security or commodity.
                    </div>
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>4.4</span>
                      <span className={styles.assignmentOfClaim}>
                        {" "}
                        Intellectual Property.
                      </span>
                      <span>
                        {" "}
                        Heimdall, the Issuer and each Issuer Affiliate retains
                        all right, title and interest in their respective
                        intellectual property, including, without limitation,
                        inventions, ideas, concepts, code, discoveries,
                        processes, marks, methods, software, compositions,
                        formulae, techniques, information and data, whether or
                        not patentable, copyrightable or protectable in
                        trademark (and where patentable, copyrightable or
                        protectable, whether or not so protected), and any
                        trademarks, copyright or patents based thereon. Nothing
                        in this Agreement shall confer or be deemed to confer on
                        the Participant or OPNX any rights or licenses in such
                        intellectual property and neither the Participant nor
                        OPNX may use Heimdall’s, the Issuer’s or Issuer
                        Affiliate’s intellectual property for any reason without
                        their prior written consent. Should there be any breach
                        of this clause, Heimdall and the Issuer reserve the
                        right to seek Damages including but not limited to
                        attorneys’ fees.
                      </span>
                    </ul>
                  </div>
                </div>
                <div className={styles.definitionsAndInterpretationGroup}>
                  <div className={styles.acceptanceOfAgreementContainer}>
                    <ul className={styles.definitionsAndInterpretation2}>
                      Disclaimers, Limitation of Liability, Indemnification
                    </ul>
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>{`5.1 `}</span>
                      <span className={styles.assignmentOfClaim}>
                        Disclaimer of Liability.
                      </span>
                      <span>
                        {" "}
                        The Issuer, each Issuer Affiliate and Heimdall expressly
                        disclaim responsibility for, and in no case shall
                        Heimdall, the Issuer or any Issuer Affiliate, by virtue
                        of this Agreement, be liable for, and the Participant
                        hereby releases each other party to this Agreement from,
                        any Damages to the fullest extent allowed by applicable
                        law, to any person or entity in connection with:
                      </span>
                    </ul>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(a)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any person’s receipt of Tokens in violation of this
                      Agreement;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(b)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      in violation of any anti-money laundering,
                      counter-terrorism financing, Sanctions or other regulatory
                      requirements that are imposed in any jurisdiction;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(c)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the exercise of any right by the Issuer or any Issuer
                      Affiliate under this Agreement;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(d)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the exercise of any right by Heimdall under this Agreement
                      or any related agreement;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(e)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the assignment of the Claim by the Participant and
                      issuance of the OPNX Credit to the Participant;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(f)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any loss in value suffered by any person as a result of
                      any conversion rate between fiat currencies, between fiat
                      and cryptocurrencies or between cryptocurrencies of any
                      kind used in relation to this Agreement;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(g)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any error, bug, flaw or other kind of defect of the source
                      code of the Tokens, the ecosystem or in related code or
                      any malfunction, breakdown, collapse, or delay caused by
                      any blockchain or by software conducting any sale or other
                      transfer of Tokens;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(h)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any loss or destruction or loss of access to Tokens;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(i)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any application, program, service or good created by the
                      Issuer or any default, breach, infringement, breakdown,
                      collapse, service suspension or interruption, fraud,
                      mishandling, misconduct, malpractice, negligence,
                      bankruptcy, insolvency, dissolution or winding-up of any
                      third-party funding portal, wallet or exchange service
                      handling tokens;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(j)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      listing or delisting of Tokens on or from any
                      cryptocurrency exchange or market or the trading of Tokens
                      on the same;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(k)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Tokens being classified or treated by any government,
                      quasi-government, authority or public body as a kind of
                      currency, security, commercial paper, negotiable
                      instrument, investment or other classification where such
                      classification or treatment results in the Tokens, or the
                      acquisition, purchase or distribution thereof, being
                      banned, regulated or subject to legal restrictions; and/or
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(l)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any indirect, special, incidental, consequential,
                      exemplary or punitive damages of any kind or for loss of
                      revenue, income or profits, loss of use or data, loss or
                      diminution in value of assets, or damages for business
                      interruption arising out of or in any way related to this
                      Agreement,
                    </div>
                  </div>
                  <div className={styles.inEachCase}>
                    in each case regardless of the form of action and even if
                    the party has been advised of the possibility of such
                    Damages and regardless of whether such Damages were
                    foreseeable.
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>{`5.2 `}</span>
                      <span className={styles.assignmentOfClaim}>
                        Limitations on Liability.
                      </span>
                      <span>
                        {" "}
                        To the fullest extent permitted by applicable law, in no
                        event shall the maximum aggregate liability of the
                        Issuer, any Issuer Affiliate or Heimdall arising out of
                        or in any way related to this Agreement or any related
                        agreement, or the access to and use of the Tokens or any
                        application, products or services accessed using the
                        Tokens, exceed US$100.
                      </span>
                    </ul>
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>{`5.3 `}</span>
                      <span className={styles.assignmentOfClaim}>
                        Indemnity.
                      </span>
                      <span>
                        {" "}
                        To the fullest extent permitted by applicable law, the
                        Participant shall indemnify, defend, hold harmless and
                        reimburse the other parties to this Agreement from and
                        against any and all actions, proceedings, claims,
                        Damages, demands and actions (including without
                        limitation fees and expenses of counsel), incurred by
                        the Issuer, Heimdall or any Issuer Affiliate arising
                        from or relating to:
                      </span>
                    </ul>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(a)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant’s failure to perform its responsibilities
                      or obligations under this Agreement;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(b)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant’s breach of or violation of this
                      Agreement;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(c)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any inaccuracy in any representation or warranty of the
                      Participant;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(d)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      the Participant’s violation of any rights of any other
                      person or entity; and/or
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(e)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any act or omission of the Participant that is negligent,
                      unlawful or constitutes wilful misconduct,
                    </div>
                  </div>
                  <div className={styles.inEachCase}>
                    such indemnity being in addition to, and not in lieu of, any
                    other indemnities set forth in a written agreement between
                    the Participant and any or all of OPNX, Heimdall, the Issuer
                    and any Issuer Affiliate.
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>{`5.4 `}</span>
                      <span className={styles.assignmentOfClaim}>
                        Indemnification of Heimdall.
                      </span>
                      <span>
                        {" "}
                        To the fullest extent permitted by applicable law, the
                        Participant, OPNX and the Issuer hereby indemnify,
                        defend and hold harmless Heimdall from and against any
                        and all actions, proceedings, claims, Damages, demands
                        and actions (including without limitation fees and
                        expenses of counsel), arising from or relating to any
                        transaction under this Agreement and any related
                        agreement.
                      </span>
                    </ul>
                  </div>
                  <div className={styles.inThisAgreementContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      <span className={styles.atheUnitedStates}>{`5.5 `}</span>
                      <span className={styles.assignmentOfClaim}>
                        Plaintiff’s Indemnification of Heimdall and the Issuer.
                      </span>
                      <span>
                        {" "}
                        The Participant shall indemnify and hold harmless
                        Heimdall and the Issuer in respect of any and all costs,
                        claims, losses, Damages and expenses which may be
                        incurred or suffered by Heimdall or the Issuer as a
                        result of or arising out of prosecuting, defending,
                        settling or investigating:
                      </span>
                    </ul>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(a)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any threatened, pending, or completed claim, demand,
                      inquiry, investigation, action, suit or proceeding,
                      whether formal or informal or brought by or in the right
                      of the Participant or otherwise and whether of a civil,
                      criminal, administrative or investigative nature, in which
                      Heimdall or the Issuer may be or may have been involved as
                      a party or otherwise, arising out of this Agreement;
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(b)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any attempt (regardless of its success) by any person to
                      charge or cause Heimdall or the Issuer to be charged with
                      wrongdoing or with financial responsibility for Damages
                      arising out of or incurred in connection with the matters
                      indemnified against in this Agreement; or
                    </div>
                  </div>
                  <div className={styles.aaParent}>
                    <div className={styles.aa}>
                      <ul className={styles.nameOfRecipientAResident}>(c)</ul>
                    </div>
                    <div className={styles.itIsTheContainer}>
                      any expense, interest, assessment, fine, tax, judgment or
                      settlement payment arising out of or incident to any of
                      the matters indemnified against in this Agreement
                      including reasonable fees and disbursements of legal
                      counsel, experts, accountants, consultants and
                      investigators (before and at trial and in appellate
                      proceedings).
                    </div>
                  </div>
                  <div className={styles.referencesInThisContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      5.6 The Issuer or Heimdall reserves the right to exercise
                      sole control over the defence, at the Participant’s
                      expense, of any claim subject to indemnification under
                      this Agreement.
                    </ul>
                  </div>
                </div>
                <div className={styles.definitionsAndInterpretationParent}>
                  <div className={styles.preamble}>
                    <ul className={styles.definitionsAndInterpretation2}>
                      Fees
                    </ul>
                  </div>
                  <div className={styles.thePartiesAgreeContainer}>
                    <ul className={styles.nameOfRecipientAResident}>
                      The Parties agree that the fees to be paid to Heimdall,
                      OPNX and the Issuer for the provision of their services in
                      relation to the assignment of the Claim shall be the
                      subject of separate agreements which shall be entered into
                      following a positive determination of Eligibility.
                    </ul>
                  </div>
                </div>
                <div className={styles.frameWrapper5}>
                  <div className={styles.definitionsAndInterpretationGroup}>
                    <div className={styles.miscellaneous1}>
                      <ul className={styles.definitionsAndInterpretation2}>
                        Miscellaneous
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.1 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Assignment.
                        </span>
                        <span>
                          {" "}
                          The Participant shall not assign this Agreement
                          without the prior written consent of the other parties
                          to this Agreement. Any assignment or transfer in
                          violation of this Clause 7.1 shall be void. The Issuer
                          may assign this Agreement without the consent of or
                          notice to the Participant. Subject to the foregoing,
                          this Agreement, and the rights and obligations of the
                          Parties hereunder, shall be binding upon and inure to
                          the benefit of their respective successors, assigns,
                          heirs, executors, administrators and legal
                          representatives.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.2 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Severability.
                        </span>
                        <span>
                          {" "}
                          If at any time any provision or part-provision of this
                          Agreement is or becomes invalid, illegal or
                          unenforceable in any respect, it shall be deemed
                          modified to the minimum extent necessary to make it
                          valid, legal and enforceable. If such modification is
                          not possible, the relevant provision or part-provision
                          shall be deemed deleted. Any modification to or
                          deletion of a provision or part-provision under this
                          Clause 7.2 shall not affect the validity and
                          enforceability of the rest of this Agreement.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.3 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Waivers.
                        </span>
                        <span>
                          {" "}
                          The failure by Heimdall, the Issuer or any Issuer
                          Affiliate to exercise or enforce any right or
                          provision of this Agreement shall not constitute a
                          present or future waiver of such right or provision
                          nor limit the right of Heimdall, such Issuer or Issuer
                          Affiliate’s to enforce such right or provision at a
                          later time. All waivers by Heimdall, the Issuer or any
                          Issuer Affiliate must be unequivocal and in writing to
                          be effective.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.4 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Amendments.
                        </span>
                        <span>
                          {" "}
                          No amendment or variation to the terms of this
                          Agreement shall be effective unless it is made or
                          confirmed in a written document signed by each of the
                          Parties. The rights of the Parties to rescind or vary
                          this Agreement are not subject to the consent of any
                          other person (including any Issuer Affiliate to the
                          extent they are not a Party hereto).
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.5 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Further Assurance.
                        </span>
                        <span>
                          {" "}
                          The Participant agrees to execute all such documents
                          and do all such acts and things as may be reasonably
                          requested by OPNX or Heimdall in connection with this
                          Agreement or the OPNX Credit.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.6 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          No Partnership or Agency Relationship.
                        </span>
                        <span>
                          {" "}
                          Nothing in this Agreement and no action taken by the
                          Parties, unless expressly stated therein, shall
                          constitute, or be deemed to constitute, a partnership,
                          association, joint venture or other co-operative
                          relationship between any of Heimdall, the Issuer and
                          any Issuer Affiliate on the one hand and either or
                          both of the Participant and OPNX on the other hand,
                          nor shall any action taken by the Parties pursuant to
                          this Agreement constitute, or be deemed to constitute,
                          any agency relationship between Heimdall, the Issuer
                          and any Issuer Affiliate on the one hand and either or
                          both of the Participant and OPNX on the other hand.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.7 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          No Third-Party Rights.
                        </span>
                        <span>
                          {" "}
                          This Agreement does not create any third-party
                          beneficiary rights in any person, save that any Issuer
                          Affiliate may rely on this Agreement in any Dispute
                          brought against it by the Participant or to exercise
                          any rights expressly provided to them hereunder.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.8 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          No Petition.
                        </span>
                        <span>
                          {" "}
                          The Participant hereby covenants that it shall not
                          take any action to present a petition or commence any
                          case, proceeding, proposal or other action under any
                          existing or future law of any jurisdiction, domestic
                          or foreign, relating to this Agreement.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.9 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Translations.
                        </span>
                        <span>
                          {" "}
                          This Agreement may be translated. Any translation is
                          for reference purposes only. The English language
                          versions of this Agreement shall prevail to the extent
                          of any inconsistency between translations.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.10 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Counterparts.
                        </span>
                        <span>
                          {" "}
                          This Agreement may be executed in counterparts, each
                          of which when executed and delivered shall constitute
                          an original, but all such counterparts together shall
                          constitute one and the same instrument.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.11 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Electronic Signatures.
                        </span>
                        <span>
                          {" "}
                          This Agreement may be executed by electronically
                          transmitted signatures, and such signatures shall be
                          deemed to be as valid as an original signature whether
                          or not confirmed by delivering the original signatures
                          in person, by courier or by mail. Each Party shall be
                          authorized to rely on such electronic signatures, and
                          shall not be required to undertake any investigation
                          into the validity of any signatures.
                        </span>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <span
                          className={styles.atheUnitedStates}
                        >{`7.12 `}</span>
                        <span className={styles.assignmentOfClaim}>
                          Force Majeure.
                        </span>
                        <span>
                          {" "}
                          The Issuer, Heimdall or OPNX shall not be in breach of
                          this Agreement for a total or partial failure of
                          performance by it of its duties and obligations under
                          this Agreement occasioned by any pandemic; fires;
                          strikes; floods; power outages or failures; acts of
                          God or state enemies; acts of any government or
                          government official; any and all market movements,
                          shifts, or volatility; computer, server, or internet
                          malfunctions; security breaches or cyberattacks
                          (including hacks, exploits, and/or compromise of any
                          custody of funds or cryptocurrencies) ; criminal acts;
                          delays or defaults caused by common carriers; acts or
                          omissions of third parties; or, any other delays,
                          defaults, failures or interruptions that cannot
                          reasonably be foreseen or provided against by the
                          Issuer.
                        </span>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.definitionsAndInterpretationGroup}>
                    <div className={styles.acceptanceOfAgreementContainer}>
                      <ul className={styles.definitionsAndInterpretation2}>
                        Notices
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li className={styles.nameOfRecipient}>
                          8.1 The Participant agrees and consents to receive
                          electronically all communications, agreements,
                          documents, receipts, notices and disclosures that the
                          Issuer or its affiliates, Heimdall or OPNX may provide
                          in connection with this Agreement and agrees that such
                          notice may be delivered to the email address provided
                          by the Participant.
                        </li>
                      </ul>
                      <p className={styles.draftNo}>&nbsp;</p>
                      <ul className={styles.nameOfRecipientAResident}>
                        <li>
                          Such notice shall be deemed effective and received by
                          the Participant on the date on which the email is sent
                          to the Participant or when it is executed
                          electronically on the platform of OPNX or Heimdall.
                        </li>
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        8.2 Any notice to be served on the Issuer, Heimdall or
                        OPNX by the Participant may be served by sending it to
                        their respective email address. Such notice shall be
                        deemed effective and received by the party on the date
                        on which the email is sent, provided if such notice is
                        sent on a day which is not a business day in the
                        Seychelles or after 17:00 (5:00 p.m.) Seychelles time on
                        any business day in the Seychelles, it shall instead be
                        deemed to have been given at 09:00 (9:00 a.m.)
                        Seychelles time on the next business day. All notices
                        must be in English.
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.definitionsAndInterpretationGroup}>
                    <div className={styles.acceptanceOfAgreementContainer}>
                      <ul className={styles.definitionsAndInterpretation2}>
                        Dispute Resolution and Governing Law
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        9.1 Subject to Clause 9.2, in relation to any dispute,
                        controversy or claim arising out of, relating to or in
                        connection with this Agreement, including with respect
                        to the formation, applicability, breach, failure to pay
                        on demand, termination, validity or enforceability
                        thereof (“Dispute”), each Party irrevocably submits to
                        the exclusive jurisdiction of the courts of the
                        Seychelles and waives any objection to such Dispute
                        being heard in such courts on the grounds of venue or on
                        the grounds that the Dispute has been brought in an
                        inconvenient forum.
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        9.2 Notwithstanding any other provision of this
                        Agreement, the Participant agrees that the Issuer and
                        any Issuer Affiliate, Heimdall or OPNX have the right to
                        apply for injunctive remedies (or an equivalent type of
                        urgent legal relief) in any jurisdiction.
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        9.3 This Agreement (and any Dispute) shall be governed
                        by and construed in accordance with, in all respects
                        including as to its validity, interpretation and effect,
                        the laws of the Seychelles, without giving effect to its
                        principles or rules of conflict of laws to the extent
                        such principles or rules are not mandatorily applicable
                        by statute and would permit or require the application
                        of the laws of another jurisdiction.
                      </ul>
                    </div>
                    <div className={styles.referencesInThisContainer}>
                      <ul className={styles.nameOfRecipientAResident}>
                        9.4 Any Dispute is personal to the Participant and the
                        Issuer (or any Issuer Affiliate), Heimdall or OPNX and
                        the Participant agrees that no Dispute shall be brought
                        as a class action, class arbitration or any other type
                        of representative proceeding.
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameContainer}>
                    <div className={styles.frameContainer}>
                      <div className={styles.frameGroup}>
                        <div className={styles.signatoriesParent}>
                          <div className={styles.signatories}>SIGNATORIES</div>
                          <div className={styles.thisAgreementHas}>
                            This Agreement has been entered into on the date
                            stated at the beginning of this Agreement
                          </div>
                        </div>
                        <div className={styles.frameParent14}>
                          <div className={styles.frameContainer}>
                            <div className={styles.frameDiv}>
                              <div className={styles.signedForAndContainer}>
                                <span className={styles.thisAgreement}>
                                  SIGNED
                                </span>
                                <span>{` for and on behalf of `}</span>
                                <span className={styles.thisAgreement}>
                                  Issuer
                                </span>
                              </div>
                              <div
                                className={
                                  styles.definitionsAndInterpretationGroup
                                }
                              >
                                <div className={styles.draftNoContainer}>
                                  By:
                                </div>
                                <div className={styles.frameInner} />
                                <div className={styles.draftNoContainer}>
                                  <p className={styles.draftNo}>Name:</p>
                                  <p className={styles.draftNo}>Position:</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.frameContainer}>
                            <div className={styles.frameDiv}>
                              <div className={styles.signedForAndContainer}>
                                <span className={styles.thisAgreement}>
                                  SIGNED
                                </span>
                                <span> for and on behalf of</span>
                                <span className={styles.thisAgreement}>
                                  {" "}
                                  Participant
                                </span>
                              </div>
                              <div
                                className={
                                  styles.definitionsAndInterpretationGroup
                                }
                              >
                                <div className={styles.draftNoContainer}>
                                  By:
                                </div>
                                <div className={styles.frameInner} />
                                <div className={styles.draftNoContainer}>
                                  <p className={styles.draftNo}>Name:</p>
                                  <p className={styles.draftNo}>Position:</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.frameContainer}>
                            <div className={styles.frameDiv}>
                              <div className={styles.signedForAndContainer}>
                                <span
                                  className={styles.thisAgreement}
                                >{`SIGNED `}</span>
                                <span>for and on behalf of</span>
                                <span className={styles.thisAgreement}>
                                  {" "}
                                  Heimdall
                                </span>
                              </div>
                              <div
                                className={
                                  styles.definitionsAndInterpretationGroup
                                }
                              >
                                <div className={styles.draftNoContainer}>
                                  By:
                                </div>
                                <div className={styles.frameInner} />
                                <div className={styles.draftNoContainer}>
                                  <p className={styles.draftNo}>Name:</p>
                                  <p className={styles.draftNo}>Position:</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.frameContainer}>
                            <div className={styles.frameDiv}>
                              <div className={styles.signedForAndContainer}>
                                <span
                                  className={styles.thisAgreement}
                                >{`SIGNED `}</span>
                                <span>for and on behalf of</span>
                                <span className={styles.thisAgreement}>
                                  {" "}
                                  OPNX
                                </span>
                              </div>
                              <div
                                className={
                                  styles.definitionsAndInterpretationGroup
                                }
                              >
                                <div className={styles.draftNoContainer}>
                                  By:
                                </div>
                                <div className={styles.frameInner} />
                                <div className={styles.draftNoContainer}>
                                  <p className={styles.draftNo}>Name:</p>
                                  <p className={styles.draftNo}>Position:</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.agreeBtnWarn}>
          <Checkbox
          
          onChange={el=>setIsChecked(el.target.checked)}>
            {" "}
            I have read the complete Master Agreement and wish to proceed
          </Checkbox>
        </div>
        <button
          disabled={!isChecked}
          onClick={()=>cancelDisclaimer()}
          className={clsx(styles.button, {
            [styles.disabledBtn]: !isChecked,
          })}
        >
          Next
        </button>
      </div>
    </Modal>
  );
}

AgreementModal.propTypes = {};

export default AgreementModal;
