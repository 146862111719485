import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils";
import Logo from "../../statics/images/logo.svg";
import styles from "./index.module.scss";
import { Dropdown, Menu, Space } from "antd";
import { useState } from "react";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import Disclaimer from "../../containers/Aggrement/Disclaimer";
import { CLAIM_TYPE, CLAIM_TYPE_FTX } from "../../common/constants";

function Header() {
  const isLoggedIn = !!localStorage.getItem("token");
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [type, setType] = useState("");

  const cancelDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const items: any = [
    {
      label: "Menu",
      key: "1",
      children: [
        {
          label: (
            <Link
              to="/public-data"
              className={
                window.location.toString().includes("public-data")
                  ? styles.active
                  : ""
              }
            >
              Public Data
            </Link>
          ),
        },
        {
          label: (
            <Link
              to="/spv-data"
              className={
                window.location.toString().includes("spv-data")
                  ? styles.active
                  : ""
              }
            >
              SPV Data
            </Link>
          ),
        },
        {
          isAuth: true,
          label: (
            <Link
              to="/my-account"
              className={
                window.location.toString().includes("my-account")
                  ? styles.active
                  : ""
              }
            >
              My Account
            </Link>
          ),
        },
        {
          isAuth: true,
          label: (
            <Link
              to="/celsius"
              className={
                window.location.toString().includes("claim")
                  ? styles.active
                  : ""
              }
            >
              Tokenize Claims
            </Link>
          ),
        },

        // {
        //   label: <Link to="/">Check your Claim</Link>,
        // },
      ],
    },
  ];

  const [current, setCurrent] = useState("menu");
  const onClick = (e: any) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const logout = () => {
    // fix type location in typescript
    window.location = "/" as any;
    localStorage.setItem("token", "");
    localStorage.setItem("accountId", "");
    localStorage.setItem("applicationId", "");
    localStorage.setItem("claimType", "");
  };

  return (
    <div className={styles.header}>
      <Disclaimer
        isModalOpen={showDisclaimer}
        type={type}
        cancelDisclaimer={cancelDisclaimer}
      />
      <div className={classNames("container z-50", styles.headerWrappper)}>
        <Link to="/">
          <img src={Logo} alt="logo" width={135} height={36} />
        </Link>

        <div className={styles.hamburger}>
          <Menu
            mode="horizontal"
            items={[
              {
                ...items[0],
                children: items[0]?.children
                  ?.map((el: any) =>
                    el.isAuth ? (isLoggedIn && el) || "" : el
                  )
                  .filter((ele: any) => !!ele),
              },
            ]}
          />
        </div>

        <div className={styles.menu} style={{ marginRight: "-3rem" }}>
          <ul>
            <li>
              <Dropdown
                placement="bottomLeft"
                arrow
                menu={{
                  items: [
                    // {
                    //   key: "1",
                    //   label: <Link to="/public-ftx-data">FTX Claims Data</Link>,
                    // },
                    // {
                    //   key: "2",
                    //   label: <Link to="/spv-ftx-data">FTX SPV Data</Link>,
                    // },
                    {
                      key: "3",
                      label: <Link to="/public-data">Celsius Claims Data</Link>,
                    },
                    {
                      key: "4",
                      label: <Link to="/spv-data">Celsius SPV Data</Link>,
                    },
                  ],
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Link
                    to={""}
                    className={
                      [
                        "/public-ftx-data",
                        "/spv-ftx-data",
                        "/public-data",
                        "/spv-data",
                      ].some((el) => window.location.toString().includes(el))
                        ? styles.active
                        : ""
                    }
                  >
                    <Space style={{ gap: "4px" }}>
                      Resouces
                      <DownOutlined
                        style={{ paddingTop: "3px" }}
                        width={"10px"}
                        height={"5.7px"}
                      />
                    </Space>
                  </Link>
                </a>
              </Dropdown>
            </li>
            {isLoggedIn && (
              <>
                <li>
                  <Dropdown
                    placement="bottomLeft"
                    arrow
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <Link to="/my-account">My CELSIUS Claims</Link>
                          ),
                        },
                        {
                          key: "2",
                          label: <Link to="/my-ftx-claims">My FTX Claims</Link>,
                        },
                      ],
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Link
                        to={""}
                        className={
                          ["/my-account", "/my-ftx-claims"].some((el) =>
                            window.location.toString().includes(el)
                          )
                            ? styles.active
                            : ""
                        }
                      >
                        <Space style={{ gap: "4px" }}>
                          My Claims
                          <DownOutlined
                            style={{ paddingTop: "3px" }}
                            width={"10px"}
                            height={"5.7px"}
                          />
                        </Space>
                      </Link>
                    </a>
                  </Dropdown>
                </li>
                <li>
                  <Dropdown
                    placement="bottomLeft"
                    arrow
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <li
                              onClick={() => {
                                setShowDisclaimer(true);
                                setType(CLAIM_TYPE);
                              }}
                            >
                              Tokenize Celsius Claims
                            </li>
                          ),
                        },
                        {
                          key: "2",
                          label: (
                            <li
                              onClick={() => {
                                setShowDisclaimer(true);
                                setType(CLAIM_TYPE_FTX);
                              }}
                            >
                              Tokenize FTX Claims
                            </li>
                          ),
                        },
                      ],
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Link
                        to={""}
                        className={
                          ["/celsius", "/ftx"].some((el) =>
                            window.location.toString().includes(el)
                          )
                            ? styles.active
                            : ""
                        }
                      >
                        <Space style={{ gap: "4px" }}>
                          Tokenize Claims
                          <DownOutlined
                            style={{ paddingTop: "3px" }}
                            width={"10px"}
                            height={"5.7px"}
                          />
                        </Space>
                      </Link>
                    </a>
                  </Dropdown>
                </li>
                <li onClick={logout}>
                  <Link to="">Logout</Link>
                </li>
              </>
            )}
            {/* <li>
              <Link to="/">Check your Claim</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {};

export default Header;
