import React from "react";
import PageTitle from '../../components/PageTitle';
import Filter from './Filter';
import TableClaimData from './Table';

import styles from './index.module.scss';

function PublicDataPage() {

  return (
    <>
      <div className={styles.main}>
        <PageTitle>Public Claims Data</PageTitle>

        <TableClaimData />
        {/* <Table
          columns={columns}
          // rowSelection={{}}
          expandable={{
            expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.status}</p>,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpCircleTwoTone style={{ fontSize: '24px' }} twoToneColor="#03ABAB" onClick={(e: any) => onExpand(record, e)} />
              ) : (
                <DownCircleTwoTone style={{ fontSize: '24px' }} twoToneColor="#03ABAB" onClick={(e: any) => onExpand(record, e)} />
              )
          }}
          dataSource={data}
        /> */}
      </div>


    </>
  )
}

PublicDataPage.propTypes = {}

export default PublicDataPage
