import { useContext, useMemo } from "react";
import { ClaimContext } from "../ClaimContextProvider";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { Button, Modal, Typography } from "antd";
import { useNavigate } from "react-router";
import styles from "./index.module.scss";

export type CompoundedComponent = {
  STEP_1: typeof Step1;
  STEP_2: typeof Step2;
  STEP_3: typeof Step3;
  STEP_4: typeof Step4;
  STEP_5: typeof Step5;
};

const Steps: CompoundedComponent = {
  STEP_1: Step1,
  STEP_2: Step2,
  STEP_3: Step3,
  STEP_4: Step4,
  STEP_5: Step5,
};

const StepsPanel = () => {
  const { currentStep, formData, isExceedLimit } = useContext(ClaimContext);
  const navigate = useNavigate();
  const curKey: keyof typeof Steps = useMemo(() => {
    if (formData.claim_holder !== "Bought This Claim") {
      if (currentStep >= 3) {
        return `STEP_5` as keyof typeof Steps;
      }
    }
    return `STEP_${currentStep + 1}` as keyof typeof Steps;
  }, [currentStep, formData.claim_holder]);
  const Component = useMemo(() => Steps[curKey], [curKey]);
  return (
    <>
      {Component && <Component />}
      <Modal
        open={isExceedLimit}
        className={styles.modal_limit}
        width={"350px"}
        title={<div className="title-txt">Exceeded Account Limit</div>}
        footer={null}
        closable={false}
        centered
      >
        <div className="txt-desc">
          You have reached the claim limit for this account.
        </div>
        <div className="txt-desc">Please increase the limit and try again.</div>

        <div className="flex justify-center">
          <button onClick={() => navigate("/")} className={"button"}>
            Back
          </button>
        </div>
      </Modal>
    </>
  );
};
export default StepsPanel;
