import { Modal } from "antd";
import { survey_obj, truncDigits } from "../../../common/utils";
import CryptoIcon from "../../../components/CryptoIcon";

interface IProps {
  handleCancel: any;
  isModalOpen: any;
  claim: any;
  prices: any;
}

function ClaimAmountModal({
  handleCancel,
  isModalOpen,
  claim,
  prices,
}: IProps) {
  

  return (
    <Modal
      footer={null}
      title="Claim Amount"
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <div className="flex flex-col gap-5">
        {survey_obj(claim.survey).ftx_claim_data && survey_obj(claim.survey).ftx_claim_data.map(
          (claimAmount: any, index: any) => {
            
            return (
              <div className="flex border border-[#EDEDED] rounded-md flex-col gap-2 py-2 px-3">
                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                  Coin
                  </span>
                  <div className="flex gap-2">
                    <span className="font-normal text-[#666666] text-sm">
                    {claimAmount?.tokenName.toUpperCase()}
                    </span>
                    <CryptoIcon
                            name={claimAmount?.tokenName?.toLowerCase()}
                            size={20}
                          />
                  </div>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                   USD Value as of Nov 11, 2022
                  </span>
                  <span className="font-normal text-[#666666] text-sm">
                  ${claimAmount.Coin} per{" "}
                        {claimAmount?.tokenName.toUpperCase()}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                    Quantity
                  </span>
                  <span className="font-normal text-[#666666] text-sm">
                    {claimAmount.Quantity}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold text-[#111111] text-sm">
                  Claim Amount
                  </span>
                  <span className="font-normal text-[#666666] text-sm">
                  $
                        {truncDigits(
                          claimAmount.Coin * claimAmount.Quantity,
                          2
                        )}
                  </span>
                </div>
              </div>
            );
          }
        )}
      </div>
    </Modal>
  );
}
ClaimAmountModal.propTypes = {};

export default ClaimAmountModal;
