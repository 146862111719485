import { Button, Modal, Row, Typography } from "antd";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { ClaimContext } from "../../ClaimContextProvider";
import { baseURL } from "../../../../api/client";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const ProofOfClaim = () => {
  const { formData } = useContext(ClaimContext);

  const [preview, setPreview] = useState<string | null>(null);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex max-[450px]:flex-col max-[450px]:gap-2">
        <div className="grow gap-2 w-1/3 flex flex-col justify-start">
          <Typography.Text className="text-[#666666] text-sm font-medium">
            Proof of Claim Filed
          </Typography.Text>
          <Typography.Text className="text-[#111111] font-semibold text-sm">
            {formData["is_poc_filed"] === "Yes" ? (
              <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#31B43E] flex justify-center items-center font-semibold">
                • YES
              </div>
            ) : (
              <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#CF352A] flex justify-center items-center font-semibold">
                • NO
              </div>
            )}
          </Typography.Text>
        </div>

        {formData["is_poc_filed"] === "Yes" && (
          <div className="grow gap-2 w-1/3 flex flex-col justify-start">
            <Typography.Text className="text-[#666666] text-sm font-medium">
              Date Filed
            </Typography.Text>
            <Typography.Text className="text-[#111111] font-semibold text-sm">
              {formData?.is_poc_general_bar
                ? moment(new Date()).format("DD/MM-YYYY - HH:mm")
                : "N/A"}
            </Typography.Text>
          </div>
        )}

        {formData["is_poc_filed"] === "Yes" && (
          <div className="grow gap-2 w-1/3 flex flex-col justify-start">
            <Typography.Text className="text-[#666666] text-sm font-medium">
              Claim Number
            </Typography.Text>

            <Typography.Text className="text-[#111111] font-semibold text-sm">
              {formData?.poc_file_number ?? "N/A"}
            </Typography.Text>
          </div>
        )}
      </div>

      {formData["is_poc_filed"] === "Yes" && (
        <div className="flex max-[450px]:flex-col max-[450px]:gap-2">
          <div className="grow gap-2 w-1/3 flex flex-col justify-start">
            <Typography.Text className="text-[#666666] text-sm font-medium">
              Name as per Proof of Claim
            </Typography.Text>

            <Typography.Text className="text-[#111111] font-semibold text-sm">
              {formData?.name_appear_poc ?? "N/A"}
            </Typography.Text>
          </div>
          <div className="grow gap-2 w-1/3 flex flex-col justify-start">
            <Typography.Text className="text-[#666666] text-sm font-medium">
              Objections Against the Claim
            </Typography.Text>
            <Typography.Text className="text-[#111111] font-semibold text-sm">
              {formData["is_poc_registered"] === "Yes" ? (
                <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#31B43E] flex justify-center items-center font-semibold">
                  • YES
                </div>
              ) : (
                <div className="h-[24px] w-[52px] rounded-[36px] border border-[#E2E2E2] text-[#CF352A] flex justify-center items-center font-semibold">
                  • NO
                </div>
              )}
            </Typography.Text>
          </div>
          {formData?.["poc_link"]?.fileList?.[0]?.name && (
            <div className="grow gap-2 w-1/3 flex flex-col justify-start">
              <Typography.Text className="text-[#666666] text-sm font-medium">
                Proof of Claim PDF
              </Typography.Text>
              <Typography.Text className="text-[#111111] font-semibold text-sm">
                <Button
                  className={"file-action text-[#03ABAB] p-0"}
                  type="link"
                  onClick={() =>
                    setPreview(
                      baseURL +
                        "/files?name=" +
                        encodeURIComponent(formData?.["poc_link"]?.fileList?.[0]?.name)+ "&token=" + localStorage.getItem('token')
                    )
                  }
                >
                  View
                </Button>
              </Typography.Text>
            </div>
          )}
        </div>
      )}

      <Modal
        open={!!preview}
        onCancel={() => setPreview(null)}
        centered
        footer={false}
        width={"80vw"}
        bodyStyle={{ height: "80vh" }}
      >
        <Row style={{ height: "100%" }}>
          {preview && (
            <DocViewer
              documents={[{ uri: preview }]}
              pluginRenderers={DocViewerRenderers}
            />
          )}
        </Row>
      </Modal>
    </div>
  );
};
export default ProofOfClaim;
