import { useEffect, useState } from "react";
import { clientAPI } from "../../api/client";

const useSVP = () => {
  const [claimAmount, setClaimAmount] = useState<{
    notionalTotal: number;
    custodyTotal: number;
    earnTotal: number;
  }>();
  const [prefRisk, setPrefRisk] = useState();
  const [assetCompositions, setAssetCompositions] = useState<Array<any>>();

  const getSPVPrefRisk = async () => {
    try {
      const result = await clientAPI("get", "/spvTotalPrefRisk");

      if (result.status !== "FAILED") {
        setPrefRisk(result.message);
      }
    } catch (error) {}
  };

  const getAssetCompositions = async () => {
    try {
      const result = await clientAPI("get", "/getAssetCompositions");

      if (result.status !== "FAILED") {
        if (result.message) {
          const data = result.message as Record<string, number>;
          const total = Object.values(data).reduce(
            (arr: number, cur: number) => arr + cur,
            0
          );
          const assetPercentage = Object.keys(data)
            .reduce((arr: Array<any>, cur: string) => {
              return [...arr, [cur, (data[cur] / total) * 100]];
            }, [])
            .sort((a: string, b: string) => +b[1] - +a[1]);

          setAssetCompositions(assetPercentage);
        }
      }
    } catch (error) {}
  };

  const getSPVClaim = async () => {
    try {
      const result = await clientAPI("get", "/spvTotalClaimAmounts");

      if (result.status !== "FAILED") {
        setClaimAmount(result.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSPVClaim();
    getSPVPrefRisk();
    getAssetCompositions();
  }, []);

  return {
    claimAmount,
    prefRisk,
    assetCompositions,
  };
};

export default useSVP;
