import { RxDotFilled } from "react-icons/rx";

export const CheckingTxt = ({ text }: any) => {
  const color = text === "Yes" ? "#13a1a1" : "#CF352A";
  return (
    <div
      className={`flex items-center text-[${color}] w-fit rounded-[36px] border border-[#E2E2E2] px-2 py-1`}
    >
      <RxDotFilled color={color} />
      {text || "No"}
    </div>
  );
};
