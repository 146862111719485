import axios from "axios";
import { setWithExpiry } from "../common/utils";
// export const baseURL = 'https://api.heimdallrwa.com';
// export const baseURL = "http://localhost:3410";

export const baseURL =
  process.env.REACT_APP_PUBLIC_API || "https://api.heimdallrwa.com";
export const clientAPI = async (method, url, options) => {
  if (!options) options = {};
  let urlencodedOptions = new URLSearchParams(
    Object.entries(options)
  ).toString();

  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token;

    return config;
  });
  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      if (response?.data.message === "Invalid Token") {
        localStorage.setItem("invalidToken", true)
        setWithExpiry("invalidToken", true, 10000)
        localStorage.setItem("token", "");
        localStorage.setItem("accountId", "");
        localStorage.setItem("applicationId", "");
        localStorage.setItem("claimType", "");
        window.location = '/';
      }
      return response;
    },
    function (error) {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  const { data } = await axios({
    baseURL,
    url,
    method,
    data: urlencodedOptions,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  return data;
};

export const uploadFileAPI = async (options) => {
  if (!options) options = {};

  const { data } = await axios({
    baseURL,
    url:"/upload",
    method:'POST',
    data: options,
    headers: {
      Accept: "*/*",
      "content-type": "multipart/form-data",
    },
  });

  return data;
};
