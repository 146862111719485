import { Tooltip } from "antd";
import styles from "./ClaimTable.module.css";
import ClaimTableRow from "./ClaimTableRow";
import clsx from "clsx";
import { BsInfoCircle } from "react-icons/bs";
function ClaimTable({ setSelectedRow, claims, prices }) {
  return (
    <div className={styles.main}>
      <table className={clsx(styles.table, "table-auto")}>
        <thead className={clsx(styles.thead)}>
          <tr className={styles.tr1}>
            <th className={clsx(styles.tr_th, "!min-w-[200px] !px-5")}>
              Bankruptcy
            </th>
            <th className={clsx(styles.tr_th, "!w-[200px]")}>Claim ID</th>
            <th className={clsx(styles.tr_th, "!w-[200px]")}>Claim Amount</th>
            {/* <th className={clsx(styles.tr_th, "!w-[180px]")}>
              <div className={styles.cell4}>
                <div className="flex gap-1">
                  <div>Preference Risk</div>
                  <Tooltip
                    placement="topLeft"
                    title={
                      "Preference Risk is calculated at Account Level considering New Value Defense using Withdrawal & Deposit transactions within the 90 day window between April 14, 2022 & July 12, 2022, inclusive of both dates, counting from the first Withdrawal transaction"
                    }
                  >
                    <div className="self-center">
                      <BsInfoCircle style={{ cursor: "pointer" }} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </th> */}
            <th className={clsx(styles.tr_th, "!w-[200px]")}>
            Customer Code
            </th>
            {/* <th className={clsx(styles.tr_th, "!w-[130px]")}>Claim No.</th> */}
            <th className={clsx(styles.tr_th, "!w-[230px]")}>Claim Status</th>
            <th className={clsx(styles.tr_th, "!min-w-[200px]")}>Action</th>
            <th className={clsx(styles.tr_th)} />
          </tr>
        </thead>

        <tbody className={clsx(styles.tbody)}>
          {claims?.map((claim, index) => (
            <ClaimTableRow
              key={index}
              claim={claim}
              prices={prices}
              onViewDetails={() => setSelectedRow(claim)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

ClaimTable.propTypes = {};

export default ClaimTable;
