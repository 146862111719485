import styles from "./index.module.css";
import { Tooltip } from "antd";
import { RxCross2, RxDotFilled } from "react-icons/rx";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiCheckCircle } from "react-icons/hi";
import TagOutline from "../../../components/Tag/TagOutline";
import ClaimAmount from "../ClaimAmount";
import { survey_obj, truncDigits } from "../../../common/utils";
import { BsInfoCircle } from "react-icons/bs";
import clsx from "clsx";
import { CheckingTxt } from "../../MyFTXClaimInfo/Components/CheckingTxt";

interface IProps {
  showInfo?: boolean;
  showAmount?: boolean;
  claim?: any;
  prices?: Array<[]>;
  setShowAmount: any;
  setShowInfo: any;
}

function ClaimInfoTable({
  showInfo,
  showAmount,
  claim,
  prices,
  setShowInfo,
  setShowAmount,
}: IProps) {
  function claimClass(admin_status: any, survey: any) {
    return (
      <div className={styles.col2}>
        <div className={styles.each_class}>
          <HiCheckCircle className={styles.sign} />
          <span>General Unsecured</span>
        </div>
        <div className={styles.each_class}>
          <HiCheckCircle className={styles.sign} />
          <span>Priority</span>
        </div>
      </div>
    );
  }
  function cicd() {
    return (
      <CheckingTxt text={['Unliquidated', 'Disputed'].some(el => survey_obj(claim.survey)?.schedule_data?.indicator?.includes(el)) ? 'Yes': 'No'} />
      // <div className={styles.col2}>
      //   <div className={styles.each_class}>
      //     <AiFillCloseCircle className={styles.cross} />
      //     <span>UD</span>
      //     <Tooltip
      //       placement="topLeft"
      //       title={
      //         "This denotes if a claim is Contingent, Unliquidated and/or Disputed"
      //       }
      //     >
      //       <div style={{ alignSelf: "center" }}>
      //         <BsInfoCircle
      //           style={{ cursor: "pointer", alignSelf: "center" }}
      //         />
      //       </div>
      //     </Tooltip>
      //   </div>
      //   <div className={styles.each_class}>
      //     <AiFillCloseCircle className={styles.cross} />
      //     <span>Offset</span>
      //   </div>
      // </div>
    );
  }

  function preference(survey: any) {
    return (
      <>
        {parseFloat(survey.preferenceRisk) <= 0 ? (
          <div className="flex gap-1 px-2 py-1 text-[#CF352A] border-[#E2E2E2] border rounded-[36px] w-fit">
            <RxDotFilled className="self-center" />
            <div className="text-xs font-semibold self-center">No</div>
          </div>
        ) : parseFloat(survey.preferenceRisk) > 0 ? (
          <>
            <div className={styles.each_class}>
              <HiCheckCircle className={styles.sign} />
              <span>Flagged</span>
            </div>

            <div className={styles.subTableCell}>
              <div className={styles.info} style={{ marginTop: "5px" }}>
                <span style={{ alignSelf: "center", fontSize: "12px" }}>
                  (Net: {truncDigits(parseFloat(survey.preferenceRisk), 2)} USD
                  )
                </span>
                <Tooltip
                  placement="topLeft"
                  title={
                    "Net amount is Total Withdrawal - Total Deposit within the 90 day window between April 14, 2022 & July 13, 2022"
                  }
                >
                  <div className="self-center">
                    {" "}
                    <BsInfoCircle
                      style={{ cursor: "pointer", alignSelf: "center" }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      {showAmount ? (
        <tr className="relative">
          <td
            colSpan={1}
            // className={clsx(
            //   'border-t border-[#EDEDED] relative bg-white py-3 px-5 text-lg font-semibold !rounded-tl-none',
            //   showInfo ? '-top-9' : '-top-4'
            // )}
            className={`max-w-[150px] border-t border-grey-300 relative -top-4 bg-white py-3 px-5 text-lg font-semibold !rounded-tl-none ${
              showInfo ? "!rounded-bl-none" : ""
            }`}
          >
            Claim Amount
          </td>
          <td
            colSpan={8}
            // className={clsx(
            //   'border-t border-[#EDEDED] relative bg-white py-3 px-5 !rounded-tr-none',
            //   showInfo ? '-top-9' : '-top-4'
            // )}
            className={`border-t border-grey-300 relative -top-4 bg-white py-3 px-5 !rounded-tr-none
            ${showInfo ? "!rounded-bl-none" : ""}`}
          >
            <RxCross2
              onClick={() => {
                setShowAmount(false);
              }}
              className="absolute top-2 right-2 text-xl cursor-pointer"
            />
            <ClaimAmount claim={claim} prices={prices} />
          </td>
        </tr>
      ) : null}

      {showInfo ? (
        <tr className="relative">
          <td
            colSpan={1}
            className={`max-w-[150px] relative ${
              showAmount ? "-top-8" : "-top-4"
            } bg-white py-3 px-5 text-lg font-semibold !rounded-tl-none whitespace-pre-wrap`}
          >
            Claim Information
          </td>

          <td
            colSpan={8}
            className={`relative ${
              showAmount ? "-top-8" : "-top-4"
            } bg-white py-3 px-5 !rounded-tr-none`}
          >
            <RxCross2
              onClick={() => {
                setShowInfo(false);
              }}
              className="absolute top-2 right-2 text-xl cursor-pointer"
            />
            <table className={styles.table}>
              <thead>
                <tr>
                  <td className={styles.tr_td}>Claim Classification</td>
                  <td
                    className={clsx(
                      styles.tr_td,
                      "flex items-center space-x-1"
                    )}
                  >
                    <div>UD status</div>
                    <Tooltip
                      placement="topLeft"
                      title={
                        "This is to denote if the claim is Unliquidated and/or Disputed"
                      }
                    >
                      <div>
                        <BsInfoCircle className="cursor-pointer !text-[#13A1A1] self-center" />
                      </div>
                    </Tooltip>
                  </td>
                  <td className={styles.tr_td}>Location</td>
                  <td
                    className={clsx(
                      styles.tr_td,
                      "flex items-center space-x-1"
                    )}
                  >
                    <div>Proof of Ownership</div>
                    <Tooltip
                      placement="topLeft"
                      title={
                        "Use this Claim Number to look up the publicly shared Notice of Transfer establishing SPV ownership of given claim"
                      }
                    >
                      <div>
                        <BsInfoCircle className="cursor-pointer !text-[#13A1A1] mt-1 self-center" />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className={styles.td}>
                    {claimClass(claim.admin_status, survey_obj(claim.survey))}
                  </td>
                  <td className={styles.td}>{cicd()}</td>

                  <td className={styles.td}>
                    <span>
                      {survey_obj(claim.survey)?.opnx_data?.countryOfResidency}
                    </span>
                  </td>

                  <td className={styles.td}>
                    <div>{claim?.agreement_number || "N/A"}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      ) : null}
    </>
  );
}

ClaimInfoTable.propTypes = {};

export default ClaimInfoTable;
