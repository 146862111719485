import styles from "./index.module.scss";
import { TbCopy } from "react-icons/tb";
import { CheckingTxt } from "../CheckingTxt";
import { Divider, Typography } from "antd";

export default function PersonalInfo({ surveyData }: any) {
  return (
    <div className={""}>
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm font-medium text-[#666666]"}>
          Preferred Communication Email
        </Typography.Text>
        <div className={styles.textGroup}>
          <div className={styles.text3}>{surveyData?.["contact_email"]}</div>
          <TbCopy className={"text-[#03abab] text-lg self-center"} />
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm font-medium text-[#666666]"}>
          Individual or a Corporation
        </Typography.Text>
        <div className={"flex items-center font-medium"}>
          {surveyData?.["user_type"]}
        </div>
      </div>
      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm font-medium text-[#666666]"}>
          Attention
        </Typography.Text>
        <div className={"flex font-medium items-center"}>
          {surveyData?.["attention"] || "N/A"}
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm font-medium text-[#666666]"}>
          Legal Name
        </Typography.Text>
        <div className={"flex font-medium items-center"}>
          {surveyData?.["legal_name"] || "N/A"}
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm font-medium text-[#666666]"}>
          US person/US corporation
        </Typography.Text>
        <CheckingTxt text={surveyData?.is_usa} />
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm font-medium text-[#666666]"}>
          Country of Residency
        </Typography.Text>
        <div className={"flex font-medium items-center"}>
          {surveyData?.["country"]}
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm font-medium text-[#666666]"}>
          Registered Celsius Email
        </Typography.Text>
        <div className={styles.textGroup}>
          <div className={styles.text3}>{surveyData?.["celsius_email"]}</div>
          <TbCopy className={"text-[#03abab] text-lg self-center"} />
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm text-[#666666] font-medium"}>
          Physical Address
        </Typography.Text>
        <div className={"flex items-center font-medium"}>
          {surveyData?.["celsius_address"]}
        </div>
      </div>

      <Divider type="horizontal" className="my-4" />
      <div className="flex flex-col sm:flex-row gap-2 justify-between border-b-black">
        <Typography.Text className={"text-sm  text-[#666666] font-medium"}>
          Phone Number
        </Typography.Text>
        <div className={"flex font-medium items-center"}>
          {surveyData?.["phone_number"]}
        </div>
      </div>

      
    </div>
  );
}
