import { Divider } from "antd";
import { CheckingTxt } from "../CheckingTxt";

export default function ClaimInfo({ surveyData , claimData}: any) {
  return (
    <>
      <div className="flex flex-col gap-[25px] bg-white">
        <div className="flex flex-col sm:flex-row gap-[25px]">
          <div className="flex flex-col w-[50%] gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              Claim is part of the latest Schedule of Assets
            </div>
            <div className="text-black text-[14px] font-bold">
              {surveyData?.schedule_data ? 'Yes' : 'No'}
            </div>
          </div>

          <Divider type="horizontal" className="my-0 block sm:hidden " />
          <div className="flex flex-col gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              {" "}
              Schedule F Line Number
            </div>
            <div className="text-black text-[14px] font-bold">
              {surveyData?.claim_f_line || "N/A"}
            </div>
          </div>
        </div>

        <Divider type="horizontal" className="my-0 block sm:hidden " />
        <div className="flex flex-col sm:flex-row gap-[25px]">
          <div className="flex flex-col w-[50%] gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              Is the claim unliquidated, disputed?
            </div>
            <CheckingTxt text={['Unliquidated', 'Disputed'].some(el => surveyData?.schedule_data?.indicator?.includes(el)) ? 'Yes': 'No'} />
          </div>
          <Divider type="horizontal" className="my-0 block sm:hidden " />
          <div className="flex flex-col gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
              {" "}
              Do you agree with the Claim Details?
            </div>
            <div className="text-black text-[14px] font-bold">
            <CheckingTxt text={surveyData?.is_soa_details_agreed}/>
            </div>
          </div>
        </div>

        <Divider type="horizontal" className="my-0 block sm:hidden " />
        <div className="flex flex-col sm:flex-row gap-[25px]">
          <div className="flex flex-col w-[50%] gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
            Earn Indicator
            </div>
            <CheckingTxt text={surveyData?.schedule_data?.earnIndicator} />
          </div>
          <Divider type="horizontal" className="my-0 block sm:hidden " />
          <div className="flex flex-col gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
            Total Claim Amount
            </div>
            <div className="text-black text-[14px] font-bold">
            $
              {Number(surveyData?.ftx_account_balance)?.toFixed(3) ||
                "N/A"}
            </div>
          </div>
        </div>

        {!!claimData?.join_url && <div className="flex flex-col sm:flex-row gap-[25px]">
         
          <Divider type="horizontal" className="my-0 block sm:hidden " />
          <div className="flex flex-col gap-2">
            <div className="text-[#666666] text-[14px] font-medium">
            Verification Call
            </div>
            <div className="text-black text-[14px] font-bold">
            <a target="_blank" rel="noreferrer" href={claimData?.join_url}>
              Link
            </a>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
}
