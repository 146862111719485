import React, { FC, useRef } from "react";
import styles from "./index.module.scss";
import SignatureCanvas from "react-signature-canvas";
// import { Button, Select } from "antd";
import dayjs from "dayjs";
import { message } from "antd";
import { ScheduleFLine } from "../ClaimPage/ClaimContextProvider";
import clsx from "clsx";

type Step1Type = {
  onNext: (sign: string) => any;
  claim: any;
  survey: any;
  claim_id: string;
  setSignature: any;
  setPlace: any;
  place: any;
  messageApi: any;
  celciusScheduleData: ScheduleFLine;
};

export const rulesPage = (
  claim: any,
  survey: any,
  celciusScheduleData: ScheduleFLine
) => (
  <>
    <h2 className={styles.cyan}>
      <b>DEED OF ASSIGNMENT OF CLAIM</b>
    </h2>

    <div className={styles["basic-block"]}>
      <div>
        <p className={styles.cyan}>
          <b>ASSIGNOR</b>
        </p>
        <br />
        <p>
          <span className={styles.gray}>Address:</span>{" "}
          {survey?.opnx_data?.addressDetail}
        </p>
        <p>
          <span className={styles.gray}>Name:</span>{" "}
          {survey?.legal_name}
        </p>
        <p>
          <span className={styles.gray}>Attention:</span> {survey?.attention}
        </p>
        <p>
          <span className={styles.gray}>Telephone:</span> {survey?.phone_number}
        </p>
        <p>
          <span className={styles.gray}>Email:</span> {survey?.email}
        </p>
      </div>
      <br />
      <div>
        <p className={styles.cyan}>
          <b>ASSIGNEE</b>
        </p>
        <br />
        <p>
          <span className={styles.gray}></span>FTX1 SPV Ltd
        </p>
        <p>
          <span className={styles.gray}>Address:</span>{" "}
          {
            "Suite 202, Second Floor, Eden Plaza, Eden Island, Box 1352, Mahe, Seychelles"
          }
        </p>

        <p>
          <span className={styles.gray}>Email:</span> ftx@adminspv.com
        </p>
      </div>
    </div>
    <br />
    <br />
    <hr />

    <br />
    <br />
    <h3 className={styles.cyan}>DATE OF THIS DEED:</h3>
    <br />
    <table>
      <tr>
        <td>DEBTOR:</td>
        <td>
          <i>	FTX Trading Ltd, et al.</i>
        </td>
      </tr>
      <br />
      <tr>
        <td>PROCEEDINGS:</td>
        <td>
          <i>
          FTX Trading Ltd, et al., Case No. 22-11068 (JTD) (Jointly
            Administered) currently pending in the United States Bankruptcy
            Court for the Southern District of New York (the “Bankruptcy Court”)
          </i>
        </td>
      </tr>
      <br />
      <tr>
        <td>
          <span className="whitespace-nowrap">PETITION DATE:</span>
        </td>
        <td>November 11, 2022</td>
      </tr>
      <br />
      <tr>
        <td>CLAIM:</td>
        <td>
          Assignor’s valid and enforceable, undisputed, non-contingent,
          liquidated claim (Claim No./Customer Code:{" "}
          <b>{survey?.claim_f_line}</b> in the
          Consolidated List of Creditors in the Voluntary Petition filed on November 11, 2022) against the Debtor in the amount of{" "}
          <b>As per Schedule</b> (“Claim Amount”) arising
          from Assignor’s deposit of crypto assets on the Debtor’s
          cryptocurrency-based finance platform.
        </td>
      </tr>
      <br />
      <tr>
        <td>VALUE:</td>
        <td>
          <b>As per Schedule</b>
        </td>
      </tr>
    </table>
    <br />
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      1. Assignor does hereby irrevocably convey and assign unto Assignee all of
      Assignor’s rights, title and interest in, to and under, the Claim of
      Assignor against Debtor in the Proceedings, including, without limitation,
      to the extent related thereto (a) any Proof of Claim (defined below) filed
      in respect of the Claim; (b) all agreements, instruments, invoices,
      receivables, purchase orders, account statements and other documents
      evidencing or relating to the Claim (collectively, the “Claim
      Documentation”); (c) all claims (including without limitation “claims” as
      defined in 11 U.S.C. §101(5)) and causes of action together with voting
      and other rights and benefits arising from, under or relating to the
      Claim, all lawsuits and other rights of any nature arising out of or in
      connection with the Claim; and (d) all of Assignor’s right to receive
      principal, interest, fees, expenses, damages, penalties and any other
      amounts, whether accruing prior to, on or after the date of this deed of
      assignment of claim (“Deed”), and all cash, securities, instruments,
      proceeds and/or other property distributed, issued or received in respect
      of, or exchanged in return for, any of the foregoing together with all
      proceeds of the foregoing (collectively, “Distributions”), whether against
      the Debtor, any affiliate of the Debtor, or other third party liable in
      respect thereof (collectively, the “Assigned Rights”). For the avoidance
      of doubt, Assignee is not assuming any obligations or liabilities of any
      kind owing to Debtor or any other party with respect to, under or in
      connection with the Assigned Rights, Claim Documentation or the
      Proceedings. This Deed shall be deemed an absolute and unconditional
      assignment of the Assigned Rights for the purpose of collection and
      satisfaction and shall not be deemed to create a security interest.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      2. Assignor shall execute the Evidence of Assignment of Claim (to be held
      in escrow pending execution of this Deed) attached hereto as Exhibit A.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      3. Assignor represents and warrants that: [select (a) or (b)]
      <br />
      (a) [{!survey?.schedule_data?.indicator?.includes('Contingent')  ? "x" : " "}] a proof of claim has
      NOT been filed against the Debtor in the Proceedings; or
      <br />
      (b) [{survey?.schedule_data?.indicator?.includes('Contingent')  ? "x" : " "}] a proof of claim has
      been duly and timely filed by Assignor in the Proceedings in the amount of{" "}
      <b>N/A</b>
      (the “Proof of Claim Amount”) (Claim No. [<b>{survey?.poc_file_number}</b>
      ] with file date [
      <b>
        {survey?.is_poc_general_bar
          ? new Date(survey?.is_poc_general_bar)?.toString()
          : ""}
      </b>
      ]) (the “Proof of Claim”); a true, correct and complete copy, including
      any addendums and amendments thereto, is attached hereto as Exhibit B; the
      Proof of Claim does not contain any untrue statement of a material fact or
      omit to state a material fact required to be stated therein or necessary
      in order to make the statements made therein, not misleading; and except
      for the Proof of Claim, no other proofs of claim have been filed with
      respect to the Claim and the Proof of Claim has not been revoked,
      withdrawn, amended or modified and no rights thereunder have been waived.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      4. Assignor further represents, warrants and covenants to Assignee and
      Assignee’s successors and assigns that: <br />
      <br />
      <ul>
        <ol>
          (a) If Assignor is a natural person, Assignor represents that it has
          full power and authority to execute, deliver and perform under this
          Deed and all ancillary documents (collectively, with all exhibits, the
          “Assignment Documents”), and the Assignment Documents constitute the
          valid, legal and binding agreement of Assignor, enforceable against
          Assignor in accordance with their terms;
        </ol>
        <ol>
          (b) If Assignor is not a natural person, Assignor is duly organized
          and validly existing under the laws of its jurisdiction of
          organization, is in good standing under such laws, and is authorized
          and empowered to execute, deliver and perform under the Assignment
          Documents, and the Assignment Documents constitute the valid, legal
          and binding agreement of Assignor, enforceable against Assignor in
          accordance with their terms;
        </ol>
        <ol>
          (c) Assignor represents that, to the extent one or more plans was
          filed and the Assignor cast one or more ballots, Assignor voted in
          favor of the plan submitted by [INSERT NAME OF PLAN PROPONENT].
          Assignor further represents that Assignor will not attempt to alter
          its vote at any time absent Assignee’s express written direction. If
          Assignor has not voted, and the solicitation deadline has not passed,
          Assignor acknowledges that its right to vote is assigned to Assignee.
          If for any reason, Assignor (and not Assignee) is entitled to exercise
          the right to vote on or after the date hereof, Assignor shall exercise
          such right in a timely manner and in accordance with Assignee’s
          instructions, except as prohibited under any applicable law, rule or
          order;
        </ol>

        <ol>
          (d) neither the execution, delivery or performance of the Assignment
          Documents, nor consummation of the transactions contemplated hereby,
          will violate or contravene any law, rule, regulation, order, agreement
          or instrument affecting the Assignor or the Assigned Rights and there
          are no proceedings pending, or threatened against Assignor, that would
          adversely affect the Assigned Rights or any action taken or to be
          taken by Assignor hereunder;
        </ol>
        <ol>
          (e)except for filings in the Proceedings that are contemplated by the
          Assignment Documents, no notice, consent or approval of, or any other
          action by, any authority or entity is or will be required for Assignor
          to execute and deliver, and perform its obligations under this Deed;
        </ol>
        <ol>
          (f) Assignor is the sole legal and beneficial owner of, and has good
          title to, the Assigned Rights, free and clear of (i) any legal,
          regulatory or contractual restriction on transfer or (ii) any pledge,
          lien, claim, security interest, participation, factoring agreement or
          other encumbrance of any type or kind (each a “Lien” and collectively
          “Liens”) and will assign to Assignee such good title free and clear of
          any and all Liens;
        </ol>
        <ol>
          (g) Assignor (i) is the original owner of the Assigned Rights and (ii)
          has not previously sold, transferred, assigned, participated or
          otherwise conveyed the Assigned Rights, nor has Assignor agreed to do
          any of the foregoing;
        </ol>

        <ol>
          (h)no payment or distribution has been received by or on behalf of
          Assignor, in full or partial satisfaction of the Assigned Rights;
        </ol>

        <ol>
          (i) Assignor has not engaged (and shall not engage) in any act,
          conduct or omission, and has not had (and shall not have) any
          relationship with the Debtor or any of its affiliates that will reduce
          or impair or otherwise adversely affect the Assigned Rights or result
          in Assignee receiving proportionately less in payments or
          distributions under, or less favorable treatment (including the timing
          of payments or distributions) for the Assigned Rights than is received
          by creditors holding claims of the same class or type as the Claim;
        </ol>
        <ol>
          (j)there are no preference actions pending against Assignor or the
          Assigned Rights, and no legal or equitable defenses, counterclaims or
          offsets, reductions, recoupments, impairments, avoidances,
          disallowances or subordinations have been (or may be) asserted by or
          on behalf of Debtor to reduce the amount or value of the Assigned
          Rights or affect their validity or enforceability;
        </ol>
        <ol>
          (k) the Assignor has not received any transfer of any interest of the
          Debtor, or its affiliates, in property within the ninety (90) days
          preceding the Petition Date, nor has the Assignor withdrawn any funds
          from any account Assignor held with the Debtor or any of its
          affiliates during the same period of time other than as disclosed by
          Assignor to Heimdall Operations Limited (“Heimdall”);
        </ol>
        <ol>
          (l) the Claim is a valid, undisputed, liquidated, enforceable,
          unsubordinated, non-contingent general unsecured claim against the
          Debtor in an amount not less than the Claim Amount;
        </ol>
        <ol>
          (m) the basis for the Claim is amounts due and owing by the Debtor
          arising from Assignor’s deposit of crypto assets on the Debtor’s
          cryptocurrency-based finance platform, which crypto assets were
          received by the Debtor pursuant to the Claim Documentation;
        </ol>
        <ol>
          (n) the Assigned Rights are specifically against the Debtor designated
          in the Claim annexed hereto as Exhibit B, and not against any
          subsidiary, parent or affiliate of the Debtor;
        </ol>
        <ol>
          (o) no objection to the Claim or the Assigned Rights has been made or
          threatened and there are no offsets, setoffs or legal or equitable
          defenses that have been (or may be) asserted by or on behalf of Debtor
          or any other party to reduce the amount of the Assigned Rights below
          the Claim Amount or to reduce the value of the Claim as compared to
          other claims against the Debtor of the same class or type as the
          Claim;
        </ol>
        <ol>
          (p) no Lien, objection, dispute, counterclaim, defense or claim
          (including any preference or fraudulent conveyance claim) or right of
          set-off, reduction, offset, delay, recoupment, disgorgement,
          impairment, avoidance, disallowance or subordination by the Debtor or
          any other entity (collectively, an “Impairment”), has been or shall be
          asserted in respect of the Claim or any part of the Assigned Rights;
        </ol>
        <ol>
          (q) Assignor is not and has never been an “insider” of the Debtor or
          its affiliates within the meaning of Section 101(31) of the Bankruptcy
          Code, is not an “affiliate” of the Debtor, as such term is defined in
          either Section 101(2) of the Bankruptcy Code or Rule 144 of the
          Securities Act of 1933, as amended, and is not a member of any
          official or unofficial committee in the Proceedings;
        </ol>
        <ol>
          (r) Assignor is not and has never been a director, officer, employee,
          independent contractor, professional, equity holder of the Debtor.
        </ol>
        <ol>
          (s) Assignor does not, and did not on the Petition Date, hold any
          funds or property of, or owe any amounts or property to, Debtor or any
          of its affiliates, and has not effected or received, and shall not
          effect or receive, the benefit of any setoff against the Debtor or any
          of its affiliates;
        </ol>
        <ol>
          (t) true, correct and complete copies of all Claim Documentation have
          been delivered by Assignor to Heimdall, and other than the Claim
          Documentation, there are no other contracts, documents, agreements,
          stipulations or orders that could materially or adversely affect the
          Assigned Rights or Assignee’s rights hereunder;
        </ol>
        <ol>
          (u) true and complete copies of all agreements, instruments, invoices,
          purchase orders, proofs of delivery and other documents evidencing or
          relating to the Claim will be, at Assignee’s option, delivered to
          Assignee or maintained in good condition by Assignor until the
          Bankruptcy Court enters a final decree closing the Case and delivered
          to Assignee within five (5) business days of Assignee’s request;
        </ol>
        <ol>
          (v) Assignee does not assume and will not be responsible for any
          obligations or liabilities of Assignor related to or in connection
          with the Claim or the Case;
        </ol>
        <ol>
          (w) neither Assignor nor Assignee will be subject to tax withholding
          on any Distributions on the Claim by or on behalf of a government
          entity;
        </ol>
        <ol>
          (x) all actions required under applicable law, rules and orders of the
          Bankruptcy Court, including, without limitation, filing and/or serving
          required documents, have been properly taken on a timely basis;
        </ol>
        <ol>
          (y) Assignor has not received any notice, and has no knowledge, that
          the Assigned Rights or any portion thereof are void, voidable,
          unenforceable or subject to any impairment;
        </ol>
        <ol>
          (z) no broker, finder, agent, or other entity acting on behalf of
          Assignor is entitled to any commission or fee for which the Assignee
          could be responsible; and
        </ol>
        <ol>
          (aa) Assignor is not “insolvent” within the meaning of Section 101(32)
          of the Bankruptcy Code (and will not be rendered insolvent as a result
          of its sale of the Assigned Rights) and is able to pay its debts as
          they become due, has adequate capital to conduct its business and has
          not filed a petition seeking protection under the Bankruptcy Code or
          similar statute.
        </ol>
      </ul>
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      5. Assignee represents and warrants to Assignor that: <br />
      (a) Assignee is duly organized and validly existing under the laws of its
      jurisdiction of organization, in good standing under such laws, and is
      authorized and empowered to execute, deliver and perform this Agreement,
      and the Evidence of Transfer of Claim and the Assignment Documents
      constitute the valid, legal and binding agreement of Assignee, enforceable
      against Assignee in accordance with its terms; and <br />
      (b) neither the execution, delivery nor performance of the Assignment
      Documents, nor consummation of the transactions contemplated hereby will
      violate or contravene any law, rule or regulation affecting the Assignee.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      6. Each of Assignee and Assignor (each a “Party” and collectively, the
      “Parties”) represents and warrants that it is a sophisticated entity and
      has adequate information concerning the financial condition of Debtor and
      the Proceedings to make an informed decision regarding the assignment of
      the Assigned Rights and that it has independently and without reliance on
      the other, and based on such information as it has deemed appropriate,
      made its own decision to enter into this Deed, except that it has relied
      on the other Party’s express representations, warranties, covenants,
      agreements and indemnities as contained herein. Each Party is aware that
      information which may be pertinent to the decision to assign the Assigned
      Rights is available to it and can be obtained from the files of the
      Proceedings or other publicly available sources.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      7.Assignor acknowledges and agrees that: (a) Assignee currently may have,
      and later may come into possession of, information relating to the Debtor
      that is not known to Assignor and that such information may be material to
      Assignor’s decision to assign the Assigned Rights to Assignee (“Assignor
      Excluded Information”); (b) Assignor has determined to assign the Assigned
      Rights notwithstanding its lack of knowledge of Assignor Excluded
      Information; (c) Assignee will have no liability to Assignor; and (d)
      Assignor waives and releases any claims that it might have against
      Assignee with respect to the non-disclosure of Assignor Excluded
      Information.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      8. In the event that (a) all or any portion of the Claim is disallowed,
      avoided, offset, subordinated, reduced, disputed, objected to, enjoined,
      subject to setoff, preference action or fraudulent conveyance, or
      otherwise impaired for any reason whatsoever (including but not limited to
      impairment as a result of Assignor’s breach of any representation or
      warranty contained herein), in whole or in part, or (b) any portion of the
      Claim is scheduled (or re-scheduled) by the Debtor as unliquidated,
      contingent or disputed, or is scheduled (or re-scheduled) in an amount
      less than the Claim Amount or (c) an order is entered in the Proceedings
      disapproving the assignment of all or any part of the Assigned Rights or
      if the Bankruptcy Court does not substitute Assignee for Assignor as the
      holder of the Assigned Rights or (d) payments or distributions are made in
      respect of the Assigned Rights that are, per dollar of claim, less in
      amount or different in nature or timing than payments or distributions on
      other claims of the same class or type as the Claim or (e) if any or all
      of the Assigned Rights is allowed against any debtor or entity other than
      the Debtor (any such event or occurrence in (a) through (e) above, a
      “Disallowance” with respect to the portion so affected), Assignor agrees
      to immediately pay to Assignee, on demand of Assignee, an amount equal to
      the Value of the portion of the Assigned Rights subject to Disallowance as
      indicated on Page 1 of this Deed, plus interest thereon at the rate of 10%
      per annum from the date of this Deed to the date of payment. Assignee’s
      demand for such payment under this Section shall not be deemed an election
      of remedies or a limitation on any other rights that Assignee may have
      hereunder or under applicable law. Assignor further agrees to reimburse
      Assignee for all losses, costs and expenses, including reasonable legal
      fees and costs, incurred by Assignee as a result of any such Disallowance
      or the enforcement of this Deed.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      9.Assignor agrees that in the event Assignor shall receive any
      Distributions or notices with respect to the Assigned Rights after the
      date of this Deed, Assignor shall accept the same and shall hold the same
      in trust for the sole benefit of Assignee and shall promptly deliver the
      same to Assignee in the same form received (free of any withholding,
      set-off, claims or deduction of any kind) within four (4) business days,
      with the endorsement of Assignor (without recourse, representation or
      warranty) when necessary or appropriate. In the event Assignor fails to
      deliver any Distribution to Assignee within four (4) business days of
      Assignor’s receipt, Assignor shall be obligated to pay Assignee interest
      on such Distribution at 4% per annum, from the date of Assignor’s receipt
      to the date of Assignee’s receipt. In the event Assignor receives any
      notices or other documents in respect of the Assigned Rights, Assignor
      shall promptly deliver the same to Assignee; provided, however, in the
      event an objection to, or motion for disallowance of, the Claim is
      received by Assignor, Assignor shall immediately notify Assignee in
      writing of such objection or motion. In the event that all or any portion
      of the Distributions on account of the Assigned Rights are not assignable
      by Assignor to Assignee, then Assignor grants to Assignee a 100%
      participation interest in the Assigned Rights or such Distributions, in
      accordance with the provisions of this Deed and applicable law.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      10. Assignor hereby irrevocably appoints Assignee as its true and lawful
      attorney-in-fact with respect to the Assigned Rights and authorizes
      Assignee to act in Assignor’s name, place and stead, to negotiate, demand,
      sue for, compromise and recover all such sums of money which now are, or
      may hereafter become due and payable for or on account of the Assigned
      Rights, to vote the Assigned Rights and to do all things necessary to
      enforce the Assigned Rights and Assignor’s rights thereunder. If for any
      reason, Assignor (and not Assignee) is entitled to exercise any such
      rights after the date hereof (including, without limitation, the right to
      vote), Assignor shall exercise such rights in a timely manner in
      accordance with Assignee’s instructions, except as prohibited under any
      applicable law, rule or order. Assignor agrees that the powers granted by
      this Section are discretionary in nature and exercisable at the sole
      option of Assignee. Assignor and Assignee expressly agree that Assignee
      shall have no obligation to prove, defend, demand or take any action with
      respect to the Assigned Rights or otherwise in the Proceedings.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      11. Assignor agrees to indemnify and hold Assignee and its affiliates and
      its and their officers, directors, partners, employees, counsel, agents
      and controlling persons harmless from and against any and all losses,
      claims, damages, judgments, costs, penalties, expenses and liabilities,
      including, without limitation, reasonable attorneys’ fees and expenses,
      which result from (i) Assignor’s breach of any of Assignor’s
      representations, warranties, covenants or agreements set forth herein, and
      (ii) any obligation of Assignor or Assignee to disgorge, in whole or in
      part, or otherwise reimburse (by setoff or otherwise) the Debtor or any
      other person or entity for any payments, distributions, property, setoffs
      or recoupments received, applied or effected by or for the account of
      Assignor under or in connection with the Assigned Rights or otherwise
      from, against or on account of Debtor or any of its guarantors or
      affiliates or any person or entity obligated in respect thereof.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      12. Assignee may at any time transfer or assign all or any portion of the
      Assigned Rights, together with all rights, title and interests of Assignee
      in and to this Deed, without the consent of, or notice to, Assignor. All
      representations, warranties, covenants, indemnities and agreements
      contained herein shall survive the execution and delivery of this Deed,
      any due diligence performed by Assignee, and the purchase and sale of the
      Assigned Rights. This Deed shall be binding upon, enforceable by, and
      inure to the benefit of the successors and assigns of each Party;
      provided, however, that the now existing and hereafter arising obligations
      of Assignor contained herein shall continue and remain in full force and
      effect and binding on Assignor until fully paid, performed and satisfied
      by Assignor.
    </p>
    <br />
    <p className={`${styles["claim-rules"]}`}>
      <b>
        13. THIS DEED AND ALL MATTERS ARISING OUT OF OR RELATING TO IT,
        INCLUDING BUT NOT LIMITED TO MATTERS WITH RESPECT TO THE FORMATION,
        APPLICABILITY, BREACH, FAILURE TO PAY ON DEMAND, TERMINATION, VALIDITY
        OR ENFORCEABILITY THEREOF, SHALL BE GOVERNED BY THE EXCLUSIVE
        JURISDICTION OF THE COURTS OF THE SEYCHELLES WITHOUT GIVING EFFECT TO
        ITS PRINCIPLES OR RULES OF CONFLICT OF LAWS TO THE EXTENT SUCH
        PRINCIPLES OR RULES ARE NOT MANDATORILY APPLICABLE BY STATUTE AND WOULD
        PERMIT OR REQUIRE THE APPLICATION OF THE LAWS OF ANOTHER JURISDICTION.
        EACH PARTY IRREVOCABLY AND UNCONDITIONALLY WAIVES ITS RIGHT TO TRIAL BY
        JURY AND CONSENTS TO THE EXCLUSIVE JURISDICTION AND VENUE OF THE COURTS
        LOCATED IN THE SEYCHELLES IN ANY ACTION ARISING OUT OF OR UNDER THIS
        DEED. EACH PARTY TO THIS DEED HEREBY IRREVOCABLY WAIVES: (A) TRIAL BY
        JURY; (B) ANY DEFENSE OF IMPROPER VENUE; OR (C) FORUM NON CONVENIENS, TO
        ANY SUCH ACTION BROUGHT IN THE SEYCHELLES COURTS. EACH PARTY CONSENTS TO
        SERVICE OF PROCESS BY ELECTRONIC MAIL AT ITS ADDRESS LISTED ON PAGE 1
        HERETO.
      </b>
    </p>
    <br />
    <p className={`${styles["claim-rules"]}`}>
      <b>
        14. NOTWITHSTANDING ANY OTHER PROVISION OF THIS DEED, THE ASSIGNOR
        AGREES THAT THE ASSIGNEE SHALL HAVE THE RIGHT TO APPLY FOR INJUNCTIVE
        REMEDIES (OR AN EQUIVALENT TYPE OF URGENT LEGAL RELIEF) IN ANY
        JURISDICTION.
      </b>
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      15.Assignor agrees to (i) execute and/or deliver, to cause to be executed
      and/or delivered, all such instruments and documents and to promptly take
      all such actions as Assignee may reasonably request in order to effectuate
      the intent and purpose, and carry out the terms, of this Deed, to cause
      Assignee to become the legal and beneficial owner and holder of the
      Assigned Rights and (ii) cooperate with and assist Assignee in enforcing
      the Assigned Rights. Assignor shall at all times retain and protect (and
      shall direct its professionals and agents to retain and protect), and
      shall deliver to Assignee, promptly upon Assignee’s request, true and
      complete copies of, all Claim Documentation and/or any other documents,
      correspondence, records, information, tape recordings, electronic mail
      messages and the like that relate to, evidence, define or are in support
      of the Assigned Rights, and Assignor shall cooperate with and assist
      Assignee in connection with any of the foregoing, including in connection
      with any proceeding, deposition and/or investigation in respect thereof.
      Assignor covenants and agrees to act reasonably at all times to maximize
      the value of the Assigned Rights and to minimize the amount of time in
      which all components of the Assigned Rights are quantified and paid.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      16. Each of Assignor and Assignee agrees to maintain the confidentiality
      of this Deed, except to the extent (i) required or advisable to enforce
      Assignee’s rights hereunder (ii) required or requested pursuant to
      applicable law, regulation, or by the order of any court or governmental
      authority of competent jurisdiction; provided that either Party may
      disclose this Deed and the transactions contemplated hereby to their legal
      counsel and other professionals and representatives, provided that such
      parties shall be obligated to maintain the confidentiality provisions
      contained herein, and provided further that Assignee may disclose this
      Deedto potential purchasers, transferees, assignees or participants.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      17. This Deed may be executed in multiple counterparts and all of such
      counterparts when taken together shall be deemed to constitute one and the
      same instrument. Delivery of an executed counterpart by facsimile or PDF
      or other electronic transmission shall be as effective as delivery of a
      manually executed counterpart. No amendment of any provision of this Deed
      shall be effective unless it is in writing and signed by the Parties and
      no waiver of any provision of this Deed, nor consent to any departure by
      either party from it, shall be effective unless it is in writing and
      signed by the affected Party, and then such waiver or consent shall be
      effective only in the specific instance and for the specific purpose for
      which given.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      18. The Parties have participated jointly in the negotiation and drafting
      of this Deed and, in the event of ambiguity or question of intent or
      interpretation arises, this Deed shall be construed as jointly drafted by
      the Parties and no presumption or burden of proof shall arise favoring or
      disfavoring either Party by virtue of the authorship of any provision of
      this Deed. The Assignment Documents constitute the complete agreement of
      the Parties with respect to the subject matters referred to herein and
      supersedes all prior or contemporaneous negotiations, agreements or
      representations of every nature whatsoever with respect thereto, all of
      which have become merged and finally integrated herein.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      19. Assignor hereby waives any notice requirement imposed by Bankruptcy
      Rule 3001(e), and consents to the substitution of Assignee for Assignor
      for all purposes in the Proceedings with respect to the Assigned Rights,
      including, without limitation, for voting and distribution purposes.
      Assignor and Assignee agree that Assignee may file copies of the Evidence
      of Assignment of Claim or other appropriate notice with the Bankruptcy
      Court pursuant to Federal Rule of Bankruptcy Procedure 3001(e). Assignor
      grants Assignee the right to make any corrections to the Evidence of
      Assignment of Claim as may be necessary or appropriate to effect
      assignment of the Assigned Rights.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      20. Each payment to be made by Assignor to Assignee shall be made without
      set-off, counterclaim or deduction of any kind. If Assignor fails to make
      a payment or distribution to Assignee within the time period specified in
      this Deed, Assignor shall, upon demand by Assignee, pay such amount due
      together with interest thereon for each day from (and including) the date
      when due to (but excluding) the date when actually paid at a rate per
      annum equal to eight percent (8%).
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      21. All demands, notices, consents, and communications hereunder shall be
      in writing and shall be deemed to have been duly given when e-mailed or
      otherwise transmitted electronically with acknowledgment of receipt by
      recipient.
    </p>
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      22. All rights, powers, and remedies provided under this Deed or otherwise
      available in respect hereof at law or in equity will be cumulative and not
      alternative, and the exercise of any right, power, or remedy thereof by
      any party will not preclude the simultaneous or later exercise of any
      other such right, power or remedy by such party or any other party.
    </p>
    <br />
  </>
);

export const signaturePage = (
  claim: any,
  survey: any,
  celciusScheduleData: ScheduleFLine
) => (
  <>
    <p className={styles.gray}>
      <b className={styles.cyan}>IN WITNESS WHEREOF,</b> the undersigned have
      duly executed this Agreement by their duly authorized representatives as
      of the _____________ day of _____________, 2023.
    </p>
    <br />
    <div className={styles["signature-block"]}>
      <div className={styles.gray}>
        <p>
          <b className={styles.cyan}>[ASSIGNOR]</b>
          <br />
          <br />
        </p>
        <p>___________________________</p>
        <br />
        <p>
          By:{" "}
          <b>
            {survey?.legal_name}
          </b>
        </p>
        <p>Title:</p>
      </div>
      <br />
      <div className={styles.gray}>
        <p>
          <b className={styles.cyan}>[ASSIGNEE]</b>
        </p>
        <p>
          <b className={styles.cyan}>FTX1 SPV LTD</b>
        </p>
        <div className="flex gap-1">
          <p className="self-center">By:</p>

          <img
            className="w-[10rem]"
            src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1685545263/signature_1_1_nerpvp.png"
            alt="..."
          />
        </div>
        <p>Title: <b>Director</b></p>
      </div>
    </div>
  </>
);

export const firstSchedulePage = (
  claim: any,
  survey: any,
  celciusScheduleData: ScheduleFLine
) => (
  <>
    <h3 className={styles.cyan}>
      <b>SCHEDULE I</b>
    </h3>
    <p>
      <b>Purchase Rate and Payment in OPNX Credit</b>
    </p>
    <br />

    <table>
      <tr>
        <td>A.</td>
        <td>Claim Amount</td>
        <td>As per Schedule</td>
      </tr>
      <tr>
        <td>B.</td>
        <td>Purchase Rate:</td>
        <td>
          One OPNX Credit for each $1.00 of the Claim Amount as determined on
          the date of this Agreement
        </td>
      </tr>
      <tr>
        <td>C.</td>
        <td className={styles.nowrap}>OPNX Credit (A x B):</td>
        <td>__________________</td>
      </tr>
    </table>
    <br />
    <p>
      <b>Payment and Delivery Instructions</b>
    </p>
    <br />
    <p className={styles.cyan}>
      <b>To Assignor:</b>
    </p>
    <br />
    <p className={styles.gray}>Notices and Deliveries:</p>
    <br />
    <p className={styles.gray}>Name: {survey?.legal_name}</p>
    <p className={styles.gray}>Address: {survey?.opnx_data?.addressDetail}</p>
    <p className={styles.gray}>Attention: {survey?.attention}</p>
    <p className={styles.gray}>Telephone: {survey?.phone_number}</p>
    <p className={styles.gray}>Email: {survey?.email}</p>
    <p className={styles.gray}> Assignor ACCOUNT DETAILS (OPNX CUSTOMER ID)</p>
    <p className={styles.gray}>[PLEASE PROVIDE]</p>
    <br />
    <p className={styles.cyan}>
      <b>To Assignee:</b>
    </p>
    <br />
    <p className={styles.gray}>Notices and Deliveries:</p>
    <br />
    <p className={styles.gray}>[NAME]</p>
    <p className={styles.gray}>Address:</p>
    <p className={styles.gray}>Attention:</p>
    <p className={styles.gray}>Phone:</p>
    <p className={styles.gray}>E-mail:</p>
    <br />
    <p className={styles.gray}>
      <small>
        For the avoidance of doubt, while OPNX Credits are being provided as
        consideration on a 1:1 basis against the Claim Amount, the value of each
        OPNX Credit is in no way tied to the Claim Amount or the underlying
        value of the Claim, but instead is determined by the value of such OPNX
        Credit on OPNX. None of Assignee, Heimdall nor OPNX make any
        representation regarding the value of the OPNX Credit now or at any
        point in the future.
      </small>
    </p>
  </>
);

export const transferPage = (
  claim: any,
  survey: any,
  celciusScheduleData: any
) => (
  <>
    <p className={clsx(styles.cyan, "text-center")}>
      <b>UNITED STATES BANKRUPTCY COURT</b>
    </p>
    <p className={clsx(styles.cyan, "text-center")}>
      <b>FOR THE DISTRICT OF DELAWARE</b>
    </p>
    <div className={styles["debtors-block"]}>
      <div className={styles.gray}>
        <br />
        <p>In re:</p>
        <br />
        <p>FTX TRADING LTD, et al.,</p>
        <br />
        <p>Debtors.</p>
      </div>
      <div className={styles.gray}>
        <br />
        <br />
        <p>Chapter 11</p>
        <p>Case No. 22-11068 (JTD)</p>
        <br />
        <p>(Jointly Administered)</p>
        <br />
      </div>
    </div>
    <br />
    <p className={clsx(styles.cyan, "text-center")}>
      <b>ASSIGNMENT OF CLAIM OTHER THAN FOR SECURITY</b>
    </p>
    <br />
    <p className={styles.gray}>
      A CLAIM HAS BEEN FILED IN THIS CASE or deemed filed under 11 U.S.C. §
    </p>
    <p className={styles.gray}>
      1111(a). Assignee hereby gives evidence and notice pursuant to Rule
      3001(e)(1) of the Federal Rules of Bankruptcy Procedure of the transfer,
      other than for security, of the claim referenced in this evidence and
      notice.
    </p>
    <div className={styles["security-block"]}>
      <div className="flex flex-col gap-2 ">
        <div className="flex flex-col gap-1">
          <p>Name of Assignor: </p>
          <b>
            {survey?.legal_name}
          </b>
        </div>
        <div className="flex flex-col gap">
          <p>Name and Current Address of Assignor: </p>
          <b className="mt-2">
            {survey?.legal_name}
          </b>{" "}
          and <b>{survey?.opnx_data?.addressDetail}</b>
        </div>
      </div>

      <div className="flex flex-col gap-2 ">
        <div className="flex flex-col gap-1">
          <p>Name of Assignee: </p>
          <b>FTX1 SPV Ltd</b>
        </div>
        <div className="flex flex-col gap">
          <div>
            Name and Address where notices and <br /> payments to Assignee
            should be sent:
          </div>
          <b className="mt-2">
            Adrienne Woods, Esq. <br /> c/o WZMP <br /> 45 Rockefeller Plaza,
            Floor 20 <br /> New York, New York 10111{" "} <br/> Email: aw@heimdallrwa.com
          </b>
        </div>
      </div>
    </div>
    <br />
    <div className={styles["transactions-block"]}>
      <table>
        <thead>
          <th>Customer/Claim No.</th>
          <th>Creditor Name</th>
          <th>Amount</th>
          <th>Debtor</th>
          <th>Case No.</th>
        </thead>
        <tbody>
          <tr>
            <td>{survey?.claim_f_line}</td>
            <td>
              {survey?.legal_name}
            </td>
            <td>{celciusScheduleData?.tokenData?.map((el: any) => (
              <div style={{width: 'max-content'}}>{`${el?.tokenName}: ${el?.value}`}</div>
            ))}</td>
            <td>FTX Trading Ltd</td>
            <td>22-11068</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <p className={styles.gray}>
      <small>
        I declare under penalty of perjury that the information provided in this
        notice is true and correct to the best of my knowledge and belief.
      </small>
    </p>
    <br />
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <p>
          By:{" "}
          <p className={styles.gray} style={{ display: "inline-block" }}>
            <img
            className="w-[8rem]"
            src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1685545263/signature_1_1_nerpvp.png"
            alt="..."
          />
          </p>
        </p>
      </div>
      <div style={{ flex: 1 }}>
        <p>Date:</p>
        <p className={styles.gray}>________________________________</p>
      </div>
    </div>
    <br />
    <p>
      <small>Assignee/Assignee’s Agent</small>  
    </p>
    <br />
    <p>
      <small>
        Penalty for making a false statement: Fine of up to $500,000 or
        imprisonment for up to five (5) years, or both. 18 U.S.C. §§ 152 & 3571.
      </small>
    </p>
  </>
);

export const evidencePage = (
  claim: any,
  survey: any,
  celciusScheduleData: ScheduleFLine
) => (
  <>
    <p className={styles.cyan}>
      <b>EVIDENCE OF TRANSFER OF CLAIM</b>
    </p>
    <br />
    <table>
      <tr>
        <td>
          <p>
            <b>TO:</b>
          </p>
        </td>
        <td>
          <p>
            <b>THE DEBTOR AND THE BANKRUPTCY COURT</b>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <b>AND TO:</b>
          </p>
        </td>
        <td>
          <p>
            <b>FTX Trading Ltd (“Debtor”)</b>
          </p>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <p>
            <b>Case No. 22-11068 (“Case”)</b>
          </p>
        </td>
      </tr>
    </table>
    <br />
    <p>
      <b>Claim No.: {survey?.poc_file_number}</b>
    </p>
    <p>
      <b>Schedule: {survey?.claim_f_line}</b>
    </p>
    <br />
    <p className={styles.gray}>
      {survey?.legal_name}{" "}
      (“Assignor”) does hereby unconditionally and irrevocably assign by deed
      unto:
    </p>
    <b className={clsx(styles.gray, "text-center")}>
      FTX1 SPV Ltd <br />
      Suite 202, 2nd Floor, Eden Plazad <br />
      Eden Island, Mahe, Seychelles
    </b>
    <br />
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      its successors and assigns (“Assignee”), all rights, title and interest in
      and to the claim of Assignor, including all rights: (a) of reclamation and
      all administrative priority claims, and any cure payments made on account
      of Assignor in the Case; (b) to any proof(s) of claim filed; (c) in and to
      any secured claim, collateral or any liens held by Assignor; (d) to vote
      on any question relating to the claim in the Case; (e) to cash, interest,
      principal, securities or other property in connection with the Case; and
      (f) to any amounts listed on Debtor's schedules (collectively, “Claim”),
      which represents 100% of the total claim amount of the Assignor against
      Debtor in the Court, or any other court with jurisdiction over Debtor's
      Case.
    </p>
    <br />
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      Assignor hereby waives: (a) any objection to the assignment of the Claim
      to Assignee on the books and records of Debtor and the Court; and (b) any
      notice or right to a hearing as may be imposed by Federal Rule of
      Bankruptcy Procedure 3001, the Bankruptcy Code, applicable local
      bankruptcy rules or applicable law. Assignor acknowledges, understands,
      agrees, and hereby stipulates that an order of the Court may be entered
      without further notice to Assignor assigning the Claim to Assignee and
      recognizing Assignee as the sole owner and holder of the Claim.
    </p>
    <br />
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      Assignee does not assume and will not be responsible for any obligations
      or liabilities of Assignor related to or in connection with the Claim or
      the Case. You are hereby directed to make all future payments and
      distributions free and clear of all setoffs and deductions, and to give
      all notices and other communications in respect of the Claim to Assignee.
    </p>
    <br />
    <br />
    <p className={`${styles["claim-rules"]} `}>
      <b>
        Assignor further directs the Debtor, the Bankruptcy Court and all other
        interested parties that all further notices relating to the Claim, all
        payments or distributions of money or property in respect of the Claim
        or for any other purpose in this Case, shall be delivered or made to the
        Assignee.
      </b>
    </p>
    <br />
    <br />
    <p className={`${styles["claim-rules"]} ${styles.gray}`}>
      IN WITNESS WHEREOF, each of the undersigned have duly executed this
      Evidence of Transfer of Claim by their duly authorized representative
      dated ________________, 2023.
    </p>
    <br />
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <p>By:</p>
        <p className={styles.gray}>__________________________________</p>
        <p>
          Name:{" "}
          <b>
            {survey?.legal_name}
          </b>
        </p>
        <p>Title:</p>
      </div>
      <div style={{ flex: 1 }}>
        <div className="flex gap-1">
          <p className="self-center">By:</p>

          <img
            className="w-[10rem]"
            src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1685545263/signature_1_1_nerpvp.png"
            alt="..."
          />
        </div>

        <p>Title:Director</p>
      </div>
    </div>
  </>
);

export function agreementContent(
  claim: any,
  survey: any,
  celciusScheduleData: ScheduleFLine
) {
  console.log(survey);
  return (
    <div className={styles["content-container"]}>
      {rulesPage(claim, survey, celciusScheduleData)}
      <br />
      {signaturePage(claim, survey, celciusScheduleData)}
      {/* <br />
      {firstSchedulePage(claim, survey, celciusScheduleData)} */}
      <br />
      {transferPage(claim, survey, celciusScheduleData)}
      <br />
      {evidencePage(claim, survey, celciusScheduleData)}
      <br />
    </div>
  );
}

const Step1: FC<Step1Type> = ({
  onNext,
  messageApi,
  claim,
  survey,
  claim_id,
  setSignature,
  setPlace,
  place,
  celciusScheduleData,
}) => {
  // const [countries, setCountries] = useState([]);

  // const getCountries = async () => {
  //   try {
  //     const result = await fetch(
  //       "https://surveyjs.io/api/CountriesExample"
  //     ).then((response) => response.json());
  //     if (Array.isArray(result)) setCountries(result);
  //   } catch (error) {
  //     //
  //   }
  // };
  // useEffect(() => {
  //   getCountries();
  // }, []);
  const signCanvas = useRef() as React.MutableRefObject<any>;
  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const next = async  () => {
    if (signCanvas.current.isEmpty()) {
      messageApi.warning("Please sign!");
      return;
    }
    signCanvas &&
      setSignature(signCanvas.current.getTrimmedCanvas().toDataURL());
    onNext(signCanvas?.current?.getTrimmedCanvas()?.toDataURL());
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.contentTitle}>Transfer Agreement</div>
        <div className={styles.contentSubTitle}>
          <span>
            Please review the complete Terms and Conditions of the Transfer
            Agreement for your FTX Claim and sign the agreement to initiate
            Account Transfer.
          </span>
          {/* <span>
            You also need to provide ERC-20 Address for the token to be sent to.
          </span> */}
        </div>

        <div className={styles.contentBasic}>
          <div>
            <span>• Agreement Content for Claim ID:</span> {claim?.claim_id}
          </div>
          <div>
            <span>• Name:</span> {survey?.legal_name}
          </div>
        </div>

        <div className={styles.contentInfo}>
          {agreementContent(claim, survey, celciusScheduleData)}
          {/* <ul>
            <li>
              Agreement Content for Claim ID:
              <span>{claim_id}</span>
            </li>
            <li>
              Name: <span>{survey && survey.legal_name}</span>
            </li>
          </ul> */}
        </div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>
            Signature<span className={styles.formLabelRequired}>*</span>
          </div>
          <div className={styles.formValue}>
            <SignatureCanvas
              penColor="green"
              canvasProps={{ className: styles.formSignature }}
              ref={signCanvas}
            />
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>Date and Time</div>
          <div className={styles.formValue}>
            {dayjs(new Date()).format("ddd MMM DD YYYY HH:mm:ss")}
          </div>
        </div>
        {/* <div className={styles.formItem}>
          <div className={styles.formLabel}>
            Location at time of signature(Please mention City, State and
            Country)<span className={styles.formLabelRequired}>*</span>
          </div>
          <div className={styles.formValue}>
            <Select
              className={styles.formSelect}
              options={countries?.map((el: any) => ({
                value: el.name,
                label: el.name,
              }))}
              value={place}
              onChange={(val) => setPlace(val)}
            />
          </div>
        </div> */}
      </div>

      <div className={styles.buttons}>
        <button onClick={next} className={"sd-btn"}>
          Next
        </button>
      </div>
    </>
  );
};

export default Step1;
