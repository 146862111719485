import React from 'react'
import PropTypes from 'prop-types';

import styles from './TagOutline.module.scss';

type TagType = {
  color: string;
  children: string
}

function TagOutLine({ color, children }: TagType) {
  return (
    <div
      style={{ color, borderColor: color }}
      className={styles.tag}
    >
      {children}
    </div>
  )
}

TagOutLine.propTypes = {
  color: PropTypes.string,
  children: PropTypes.string
}

export default TagOutLine
