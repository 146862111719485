import PropTypes from "prop-types";
import styles from "./index.module.scss";

type TagType = {
  color: any;
  children: any;
  bgColor:any
};

function CustomTag({ bgColor,color, children }: TagType) {
  return (
    <div style={{ background: bgColor }} className={styles.tag}>
      <span style={{ color }}>{children}</span>
    </div>
  );
}

CustomTag.propTypes = {
  color: PropTypes.string,
  children: PropTypes.string,
};

export default CustomTag;
